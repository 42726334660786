
import React from 'react';
// import * as Config  from "../../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  Box,
} from '@mui/material';
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */


const UnderConstructionBar = () => {


  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '0.55rem',
        borderRadius: "4px",
        border: '1px solid',
        borderColor: '#808080',
        background: 'linear-gradient(45deg, yellow 25%, black 25%, black 50%, yellow 50%, yellow 75%, black 75%, black 100%)',
        backgroundSize: '56.57px 56.57px', // Diagonal line effect
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
    </Box>
  );
};

export default UnderConstructionBar;

