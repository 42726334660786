
import React from "react";
// import * as Config  from "../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  CircularProgress,
  useTheme,
} from "@mui/material";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */


const ProgressCircleGradient = () => {
  const theme = useTheme();
  const pal = theme.palette


  return (
    <React.Fragment>
      <div style={{ position: "absolute", left: "-10px", }}>
        <svg width={0} height={0}>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor={pal.warning.main} />
              <stop offset="100%" stopColor={pal.primary.main} />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <CircularProgress sx={{ 'svg circle': { stroke: 'url(#my_gradient)' } }} />
    </React.Fragment>
  );
}

export default ProgressCircleGradient;

