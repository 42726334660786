import React from "react";
// import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { ScrollToTopOnLoad as LoadFresh } from "../../../utils";
// import * as Config from "../../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  Box,
  useMediaQuery,
} from "@mui/material";
import { Helmet } from 'react-helmet';
import NavbarPublic from "../../navbarPublic";
import Footer from "../../footer";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import ProjectBotFolkWidget from "../../widgets/projects/ProjectBotFolkWidget";


const ProjectBotFolkPage = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  // const { _id, picturePath } = useSelector((state) => state.user);

  // const navigate = useNavigate();
  // const handleLinkClick = (path) => {
  //   navigate(path);
  //   // if (!isNonMobileScreens) {
  //   //   setIsMobileMenuToggled(false); // Close the mobile menu after clicking a link
  //   // }
  // };


  return (
    <div>
      <Helmet>
        <title>SmartMetal.ai - Bot Folk Project - Next.js MERN Stack - Machine Learning, Artificial Intelligence, Genetic Algorithm</title>
        <meta name="description" content="Bot Folk Project - Next.js MERN Stack - Machine Learning, Artificial Intelligence, Genetic Algorithm" />
      </Helmet>
      <Box>
        <NavbarPublic/>
        <Box
          width="100%"
          padding="2rem 4%"
          display={isNonMobileScreens ? "flex" : "block"}
          gap="1.5rem"
          justifyContent="center"
        >
          <ProjectBotFolkWidget />
          <a href="https://botfolk.ai/" target="_blank" rel="noreferrer">
            botfolk.ai
          </a>
        </Box>
        <Box
          display="block"
          width="100%"
          gap="1.5rem"
          padding="0.0rem"
        >
          <Footer />
        </Box>
      </Box>
      <LoadFresh />
    </div>
  );
};

export default ProjectBotFolkPage;

