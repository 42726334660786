
import { useNavigate } from "react-router-dom";
// import * as Config  from "../../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Divider,
} from "@mui/material";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import FlexBetween from "../../../components/FlexBetween";
import WidgetWrapper from "../../../components/WidgetWrapper";
import ProjectImage from "../../../components/ProjectImage";
import UnderConstruction from "../../../components/UnderConstruction";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */


const DetailBedrockWidget = ({
    smallMode = false,
  }) => {
  const theme = useTheme();
  const pal = theme.palette;
  // const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  const isNonTinyScreens = useMediaQuery("(min-width: 800px)");
  const navigate = useNavigate();

  const imageFileProject = "thm/FLUX-snake-00-thumb.png";
  const projectUrl = "/project/solar";
  const imageAlt = "SunPower Machine-Learning Solar Design App (screenshot)";


  return (
    <WidgetWrapper>
      {/* FIRST ROW */}
      <FlexBetween
        gap="1.5rem"
        pb="1.1rem"
        onClick={() => navigate(`/`)}
      >
        <FlexBetween gap="1rem">
          <Box>

            <Divider color={pal.warning.dark} sx={{ "background-color": pal.warning.dark }} />

            <Box p="1rem 0">
              <FlexBetween mb="0.5rem">
                <Typography
                  // variant="personal"
                  fontSize="1.0rem"
                  color={pal.neutral.dark}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;Python totally dominates the world of large-scale infrastructure,
                  especially web and internet.
                  &nbsp;&nbsp;Python also dominates the world of Machine Learning and AI, which is a red hot area
                  for Full Stack right now.
                  &nbsp;&nbsp;Python has also been my chosen primary language since it was created.
                  &nbsp;&nbsp;The timing is perfect for me to create this brand new, super-advanced Full Stack.
                </Typography>
              </FlexBetween>
            </Box>

            <Divider color={pal.warning.dark} sx={{ "background-color": pal.warning.dark }} />

          </Box>
          { isNonTinyScreens && (
            <ProjectImage
              smallMode={smallMode}
              imageFileProject={imageFileProject}
              imageAlt={imageAlt}
              imageLinkUrl={projectUrl}
            />
          )}
        </FlexBetween>
      </FlexBetween>


      <Box p="0.0rem 0">
        <FlexBetween mb="0.5rem">
          <Typography
            // variant="personal"
            fontSize="1.0rem"
            color={pal.neutral.dark}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;* More details about this project are coming here very soon, including a link to the demo site
            and the GitHub repository.
          </Typography>
        </FlexBetween>
      </Box>

      <Divider color={pal.warning.dark} sx={{ "background-color": pal.warning.dark }} />

      <UnderConstruction />

    </WidgetWrapper>
  );
};

export default DetailBedrockWidget;

