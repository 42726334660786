
import { useNavigate } from "react-router-dom";
import * as Config  from "../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  Box,
  useTheme,
  useMediaQuery,
} from "@mui/material";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

// For !isNonMobileScreen make the size the small one OR make it a small rem or percentage. OR even better,
// might ALSO need to change to block so the bullets and header and stuff flow under.
// NONE OF THIS IS PRIORITY AT THIS POINT


const ProjectImage = ({
    imageFileProject,
    imageAlt = "Project Image",
    smallMode = false,
    imageLinkUrl,
  }) => {
  const theme = useTheme();
  const pal = theme.palette;
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  const navigate = useNavigate();

  const size = smallMode ? "180px" : ( isNonMobileScreens ? "340px" : "180px" );


  return (
    <Box
      width={size}
       sx={ smallMode ? {
         // flexGrow: 1,
         "&:hover": {
           color: pal.primary.less_ink,
           cursor: "pointer",
         },
       } : {
         // flexGrow: 1,
         // None. Not a link in large mode so no hover/pointer.
       }}
    >
      <img
        style={{
          objectFit: "cover",
          borderRadius: "6px",
          border: '1px solid #808080',
      }}
        width={size}
        alt={imageAlt}
        src={`${Config.expressUri}/assets/projects/${imageFileProject}`}
        onClick={  smallMode ? ( () => navigate(imageLinkUrl)  ) : undefined }
      />
      { smallMode && (
        // /* -------- EXPANDER - VERTICAL -------- */
        <Box  // EXPANDER box. Wraps 1x1 transp. Has disp flex and dir col. 1 px img has grow 1.
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <img  // EXPANDER 1x1 px transparent png. Requires containing Box with disp flex and dir col. Has grow 1.
            style={{
              flexGrow: 1,
              // width: "1px",
              height: "40px",  // HACK - Just slightly improves the persistent expansion/sizing problem.
            }}
            alt=""
            src={`${Config.expressUri}/assets/img/transp-1x1.png`}
          />
        </Box>
      )}
    </Box>
  );
};

export default ProjectImage;

