
import { useMemo } from "react";
import {
  BrowserRouter,
  Navigate,
  Routes,
  Route,
} from "react-router-dom";
import { useSelector } from "react-redux";
// import * as Config  from "./config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  CssBaseline,
  ThemeProvider,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "./theme";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import HomePage from "./scenes/homePage";  // Site Root/Home (public)
import GalleryPage from "./scenes/galleryPage";  // Gen AI Gallery (public & linked)
import DevPage from "./scenes/devPage";  //  Developer Resources (public & linked)
import ExhibitPage from "./scenes/exhibitPage";  // Hidden site-dev utility page (un-published gallery images)
import ForgePage from "./scenes/forgePage";  // Forge - AI Generation (depends on PAAS/IAAS GPUs)
import DreamPage from "./scenes/dreamPage";  // Dream-o-matic - AI Generation Game (depends on PAAS/IAAS GPUs)
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import RTX5090AdvertPage from "./scenes/feature/rtx5090AdvertPage";  // Feature Page (NVIDIA RTX 5090)
import H100AdvertPage from "./scenes/feature/h100AdvertPage";  // Feature Page (NVIDIA H100)
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import ProjectBedrockPage from "./scenes/project/projectBedrockPage";  // Project Page (BEDROCK PYTHON STACK)
import ProjectSmartMetalPage from "./scenes/project/projectSmartMetalPage"; // Project Page (this site, MERN STACK)
import ProjectBotFolkPage from "./scenes/project/projectBotFolkPage";  // Project Page (BOTFOLK NEXT.JS MERN STACK)
import ProjectSolarPage from "./scenes/project/projectSolarPage";  // Project Page (SOLAR ML FULL STACK APP)
import ProjectNucleusPage from "./scenes/project/projectNucleusPage";  // Project Page (NUCLEUS OPEN SOURCE FULL STACK)
import ProjectAnsiblePage from "./scenes/project/projectAnsiblePage";  // Project Page (ANSIBLEMYEC2 OPEN SOURCE)
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import LobbyPage from "./scenes/lobbyPage";  // Lobby for Authenticated-Entry or Account-Creation for Matrix
import MatrixPage from "./scenes/matrixPage";  // Matrix Social Network Home (public & linked but with Auth)
import ProfilePage from "./scenes/profilePage";  // Matrix Social Network User Profile Page (public but with Auth)
import HiddenPage from "./scenes/hiddenPage";  // Hidden (public) area
import TestPage from "./scenes/testPage";  // Hidden (public) area - Test for CssBaseline BG
import CitadelPage from "./scenes/citadelPage";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */


function App() {
  const mode = useSelector((state) => state.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const isAuth = Boolean(useSelector((state) => state.token));


  return (
    <div className="app">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Routes>
            {/*-------- PUBLIC AREAS --------*/}
            <Route path="/" element={<HomePage />} />
            <Route path="/gallery" element={<GalleryPage />} />
            <Route path="/dev" element={<DevPage />} />
            <Route path="/exhibit" element={<ExhibitPage />} />
            <Route path="/forge" element={<ForgePage />} />
            <Route path="/dream" element={<DreamPage />} />
            {/*-------- FEATURE PAGES (ADS, LANDING-PAGES, PARTNER PAGES) --------*/}
            <Route path="/feature/nvidia-rtx-5090" element={<RTX5090AdvertPage />} />
            <Route path="/feature/nvidia-h100" element={<H100AdvertPage />} />
            {/*-------- PROJECT/PORTFOLIO PAGES (LANDING-PAGES FOR PROJECT STRIP--------*/}
            <Route path="/project/bedrock" element={<ProjectBedrockPage />} />
            <Route path="/project/smartmetal" element={<ProjectSmartMetalPage />} />
            <Route path="/project/botfolk" element={<ProjectBotFolkPage />} />
            <Route path="/project/solar" element={<ProjectSolarPage />} />
            <Route path="/project/nucleus" element={<ProjectNucleusPage />} />
            <Route path="/project/ansible" element={<ProjectAnsiblePage />} />
            {/*-------- AUTHENTICATED AREAS, LOBBY FOR ACCOUNT SIGN-IN, ACCOUNT CREATION --------*/}
            <Route path="/lobby" element={<LobbyPage />} />
            <Route
              path="/matrix"
              element={isAuth ? <MatrixPage /> : <Navigate to="/lobby" />}
            />
            <Route
              path="/profile/:userId"
              element={isAuth ? <ProfilePage /> : <Navigate to="/lobby" />}
            />
            {/*-------- HIDDEN (PUBLIC) AREAS --------*/}
            <Route path="/ll1l1l1ll" element={<HiddenPage />} />
            <Route path="/ll1l1l1ll/test" element={<TestPage />} />
            {/*-------- IP-ACCESS LIMITED, HIDDEN AREAS, INTRANET/SECURE --------*/}
            <Route path="/citadel" element={<CitadelPage />} />
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;

