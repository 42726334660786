
import { useNavigate } from "react-router-dom";
// import * as Config  from "../../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Divider,
  IconButton,
} from "@mui/material";
import { ZoomIn } from "@mui/icons-material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import FlexBetween from "../../../components/FlexBetween";
import WidgetWrapper from "../../../components/WidgetWrapper";
import ProjectImage from "../../../components/ProjectImage";
import TechChip from "../../../components/TechChip";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */


const ProjectSolarWidget = ({
    smallMode = false,
  }) => {
  const theme = useTheme();
  const pal = theme.palette;
  // const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  const isNonTinyScreens = useMediaQuery("(min-width: 800px)");
  const navigate = useNavigate();

  const imageFileProject = "thm/ansiblemyec2-340x246-thumb.png";
  const projectUrl = "/project/ansible";
  const imageAlt = "SunPower Machine-Learning Solar Design App (screenshot)";


  return (
    <WidgetWrapper>
      {/* -------- FIRST ROW -------- */}
      <FlexBetween
        gap="1.5rem"
        pb="1.1rem"
      >
        <FlexBetween gap="1.5rem">
          { isNonTinyScreens && (
            <Box
              sx={{
                display: "block",
                // display: "flex",
                // flexDirection: "column",
                // justifyContent: "flex-start",
                // gap: "1rem",
                // flexBasis: "100%",
                // flexGrow: 1,
              }}
            >
              <ProjectImage
                sx={{
                  // display: "flex",
                  // flexDirection: "column",
                  // justifyContent: "flex-start",
                  // gap: "1rem",
                  // flexBasis: "100%",
                  // flexBasis: "max-content",
                  // flexGrow: 1,
                }}
                smallMode={smallMode}
                imageFileProject={imageFileProject}
                imageAlt={imageAlt}
                imageLinkUrl={projectUrl}
              />
            </Box>
          )}
          <Box>
            <FlexBetween gap="0.5rem">
              <Typography
                variant="h4"
                color={pal.neutral.dark}
                fontWeight="600"
                marginBottom="0.75rem"
              >
                AnsibleMyEC2 Open Source Project
              </Typography>
              {/* -------- ZOOM NAVIGATE - ONLY IN SMALL MODE -------- */}
              { smallMode && (
                  <IconButton
                    onClick={() => navigate(projectUrl)}
                  >
                    <ZoomIn
                      sx={{
                        fontSize: "1.8rem",
                        color: pal.primary.main,
                      }}
                    />
                  </IconButton>
                )}
            </FlexBetween>
            {/* -------- FIRST ROW - SUB-HEADING & BULLETS -------- */}
            <Typography
              variant="h5"
              color={pal.neutral.dark}
              fontWeight="500"
              marginBottom="0.75rem"
            >
              Developed in 2023 and used in an Automation Dev Contract with Everest Global 2023-2024
            </Typography>
            <FlexBetween gap="1.0rem">
              <List sx={{ pt: 0, pb: 0, listStyleType: 'disc', color: pal.neutral.main }}>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >
                  Automate your EC2 instances with clean/simple Ansible.
                </ListItem>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >
                  Create, setup, deploy and operate containerized full stack apps.
                </ListItem>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >
                  Perfect for teams to run a dev environment or EC2 production VMs.
                </ListItem>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >
                  Academically straightforwad implementation of Ansible - Great for learning!
                </ListItem>
              </List>
              <List sx={{ pt: 0, pb: 0, listStyleType: 'disc', color: pal.neutral.main }}>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >
                  I wrote custom Python tools to leverage Network Security Device APIs for migrations
                </ListItem>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >
                  I managed the Machine Learning facilities of Cisco DNA for AI Global Network Observability
                </ListItem>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >
                  I managed and integrated the SolarWinds monitoring and automation application
                </ListItem>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >
                  A team of 5 gained significant Linux skills though my bi-weekly training sessions
                </ListItem>
              </List>
            </FlexBetween>
          </Box>
        </FlexBetween>
      </FlexBetween>

      <Divider color={pal.warning.dark} sx={{ "background-color": pal.warning.dark }} />

      {/* -------- SECOND ROW -------- */}
      <Box p="1rem 0 0">
        <FlexBetween mb="0.5rem">
          <Typography
            // variant="personal"
            fontSize="1.0rem"
            color={pal.neutral.dark}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;This is Ansible running locally in a Python virtual environment, set up in
            the simplest and cleanest possible way to empower developers who use AWS EC2 instances for
            straightforward, containerized full stack deployments.
            &nbsp;&nbsp;I develop The Nucleus Stack, a full stack starter kit and complete application stack with
            modular enhancement stacks like REST vs GraphQL backends and many more future stack module plans in areas like Big Data and ML.
            &nbsp;&nbsp;This is the Ansible set up I use to work with a handful of t2.micro to t2.medium instances
            to run different projects and different configurations of Nucleus and variants .. or anything at all I need to run.
            &nbsp;&nbsp;Docker and Docker Compose are installed on Amazon Linux.
            &nbsp;&nbsp;The Linux environment is very nicely configured.
            &nbsp;&nbsp;Your VM is ready for development or single instance production hosting.
            &nbsp;&nbsp;I'll keep this project simple.
            &nbsp;&nbsp;I have another project for a containerized Ansible stack which
            can be used in the same small team development context, or for enterprise DevOps deployment.
          </Typography>
        </FlexBetween>
      </Box>

      <Box p="1rem 0">
        <FlexBetween mb="0.5rem">
          <Typography
            // variant="personal"
            fontSize="1.0rem"
            color={pal.neutral.dark}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;Ask me about how I used AnsibleMyEC2 and many other Ansible playbooks,
            along with custom Python tools to help automate the global expansion of the Network and Network
            Security Infrastructure of Everest Global into 10 new international sites.
            &nbsp;&nbsp;At the same time, a large number of datacenter-resident apps from numerous different
            component companies were migrated into the cloud.
            &nbsp;&nbsp;My automation, leveraging decades of experience, saved thousands of man-hours
            and made some efforts, such as migrating thousands of firewall rules, actually possible as manual
            means and existing options were just not workable.
            &nbsp;&nbsp;Over more than 20 years, I have automated every kind of system (including a lot of
            different kinds of hardware devices, datacenter appliances and even IOT devices)
            and thus I can quickly come up with solutions to novel automation challenges and do so on short
            notice or in the middle of an outage or IT crisis to help save data and bring up systems under
            pressure in ways that are only possible with very flexible automation.
          </Typography>
        </FlexBetween>
      </Box>

      <Divider color={pal.warning.dark} sx={{ "background-color": pal.warning.dark }} />

      {/* -------- THIRD ROW -------- */}
      <Box p="1.0rem 0.0rem 0.0rem 0.0rem">
        <Box display="flex" flexWrap="wrap" gap="0.5rem" mb="0.0rem" mt="0.5rem"
             sx={{
               "& .MuiChip-label": { fontSize: ".85rem", fontWeight: "bold" }, // Slightly bigger chip label
             }}>
          <TechChip techName={"Ansible"} techColor={pal.secondary.dark} />
          <TechChip techName={"YAML"} techColor={pal.secondary.dark} />
          <TechChip techName={"Python"} techColor={pal.warning.main} />
          <TechChip techName={"AWS SDK for Python: Boto3"} techColor={pal.error.main} />
          <TechChip techName={"Linux: AMI (RedHat Variant)"} techColor={pal.primary.main} />
          <TechChip techName={"Linux: Format, Volumes, Auto-mount, Tuning"} techColor={pal.primary.main} />
          <TechChip techName={"Linux: Libraries, Tools Installs"} techColor={pal.primary.main} />
          <TechChip techName={"Linux: Docker Container Engine Install"} techColor={pal.primary.main} />
          <TechChip techName={"Linux: System Audit"} techColor={pal.primary.main} />
          <TechChip techName={"Security: Secret Separation"} techColor={pal.success.main} />
        </Box>

      </Box>
    </WidgetWrapper>
  );
};

export default ProjectSolarWidget;


// <a href="https://patents.justia.com/patent/11392730" target="_blank" rel="noreferrer">
//   PATENT # 11392730 - SUNPOWER CORPORATION - Generating a Photovoltaic System Design for a Building, 2022 - Lead Developer: James Mannix
// </a>
// <br />
// <br />
// <a href="https://patents.justia.com/patent/10754999" target="_blank" rel="noreferrer">
//   PATENT # 10754999 - SUNPOWER CORPORATION - Generating a Photovoltaic System Design for a Building, 2020 - Lead Developer: James Mannix
// </a>
// <br />
// <br />
// <a href="https://patents.justia.com/patent/20200364382" target="_blank" rel="noreferrer">
//   PATENT PUBLICATION # 20200364382 - SUNPOWER CORPORATION - Generating a Photovoltaic System Design for a Building, 2020 - Lead Developer: James Mannix
// </a>
// <br />
// <br />
// <a href="https://patents.justia.com/inventor/james-mannix" target="_blank" rel="noreferrer">
//   PATENTS & FILINGS BY INVENTOR JAMES MANNIX #'S 11392730, 20200364382, 10754999
// </a>
// <br />
// <br />

