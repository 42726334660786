
import React, {
  useEffect,
  useState,
} from "react";
import * as Config  from "../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  InputBase,
  Divider,
  Button, TextField,
} from "@mui/material";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import FlexBetween from "../../components/FlexBetween";
import WidgetWrapper from "../../components/WidgetWrapper";
import ForgeIcon from "../../components/ForgeIcon";
import ProgressCircleGradient from "../../components/ProgressCircleGradient";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */


const ForgeWidget = () => {
  const picturePath = "stable-diff-icon-400x400.jpg";
  const defaultImage = "forge-flow-512x512.png";
  const theme = useTheme();
  const pal = theme.palette;

  const [post, setPost] = useState("");
  const [state, setState] = useState('default'); // 'default', 'waiting', 'completed', 'error'
  const [result, setResult] = useState('');
  const [seed, setSeed] = useState('');
  const [executionTime, setExecutionTime] = useState('');
  const [delayTime, setDelayTime] = useState('');
  const [workerId, setWorkerId] = useState('');
  const [randImage, setRandImage] = useState('');

  const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  };

  const defaultImages = [
    {
      img: 'forge-burst-512x512.png',
      prompt: 'blah blah blah',
    },
    {
      img: 'forge-flow-512x512.png',
      prompt: 'blah blah blah',
    },
    {
      img: 'forge-fountain-512x512.png',
      prompt: 'blah blah blah',
    },
    {
      img: 'forge-morph-512x512.png',
      prompt: 'blah blah blah',
    },
    {
      img: 'forge-spindrels-512x512.png',
      prompt: 'blah blah blah',
    },
  ]

  useEffect(() => {
    const randIndex = Math.floor(Math.random() * defaultImages.length);
    setRandImage(defaultImages[randIndex].img);

    // console.log(randIndex);
    // console.log(randImage);
  }, []);


  // # FROM MY-POSTS
  // const dispatch = useDispatch();
  // const [isImage, setIsImage] = useState(false);
  // const [image, setImage] = useState(null);
  // const { _id } = useSelector((state) => state.user);
  // const token = useSelector((state) => state.token);
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");


  // # FROM MY-POSTS
  const handlePost = async () => {
    const newFormData = {
      prompt: post,
    }
    console.log(newFormData);
    setState('waiting');

    try {
      const response = await fetch(`${Config.fastApiUri}/start-job`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newFormData),
      });

      // setState('waiting');

      const response_json = await response.json();
      console.log("RESPONSE---------------------------:");
      console.log(response_json);
      console.log("-----------------------------------:");

      const jobId = response_json.id;
      await pollJobStatus(jobId);
    } catch (error) {
      setState('error');
      console.error('Error submitting job:', error);
    }
  };

  const pollJobStatus = async (jobId) => {
    try {
      setState('waiting');
      let jobComplete = false;
      while (!jobComplete) {

        const response = await fetch(`${Config.fastApiUri}/job-status/${jobId}`, {
          method: "GET",
        });

        const response_json = await response.json();
        console.log("RESPONSE---------------------------:");
        console.log(response_json);
        console.log("-----------------------------------:");

        if (response_json.status === 'COMPLETED') {
          setState('completed');
          const imageBase64 = response_json.output[0].image;
          const seedInt = response_json.output[0].seed;
          const executionTimeInt = response_json.executionTime;
          const delayTimeInt = response_json.delayTime;
          const workerIdInt = response_json.workerId;
          setResult(imageBase64);
          setSeed(seedInt);
          setExecutionTime(executionTimeInt);
          setDelayTime(delayTimeInt);
          setWorkerId(workerIdInt);
          jobComplete = true;
        } else if (response_json.status === 'ERROR') {
          setState('error');
          console.error('Job encountered an error:', response_json.error);
          jobComplete = true;
        } else {
          await sleep(3000);
        }
      }
    } catch (error) {
      setState('error');
      console.error('Error polling job status:', error);
    }
  };


  return (
    <WidgetWrapper>
      <Typography
        variant="mainlogo"
        fontWeight="bold"
        fontSize="32px"
        color={pal.warning.main}
        sx={{
          "&:hover": {
            cursor: "default",
          },
        }}
      >
        SmartMetal&nbsp;&nbsp;<span style={{fontStyle: "italic" }}>Forge</span>
      </Typography>
      <Typography
        fontWeight="500"
        fontSize="0.9rem"
        fontStyle="italic"
        display="inline"
        sx={{ mb: "1.0rem" }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;AI &nbsp;Text-to-Image &nbsp;Generator
      </Typography>


      <Typography
        color={pal.neutral.main}
        fontWeight="500"
        variant="h6"
        sx={{ mt: "0.2rem", mb: "0.1rem" }}
      >
        Model: &nbsp;&nbsp;&nbsp;Stable Diffusion 1.5 (2023) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;GPUs: &nbsp;&nbsp;&nbsp;RTX A5000, RTX 3090
      </Typography>
      <Typography
        color={pal.neutral.main}
        fontWeight="500"
        variant="h6"
        sx={{ mb: "1.0rem" }}
      >
        Platform: &nbsp;&nbsp;&nbsp;RunPod Serverless &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Backend: &nbsp;&nbsp;&nbsp;FastAPI, Python
      </Typography>


      <Divider sx={{ margin: "1.25rem 0" }} color={pal.primary.main} />

      <FlexBetween gap="0.75rem">
        {/*<ForgeIcon image={picturePath} />*/}
        {/*<InputBase*/}
        {/*  placeholder="Type your AI image generation prompt here ..."*/}
        {/*  onChange={(e) => setPost(e.target.value)}*/}
        {/*  value={post}*/}
        {/*  sx={{*/}
        {/*    width: "100%",*/}
        {/*    backgroundColor: pal.neutral.least_ink,*/}
        {/*    borderRadius: "2rem",*/}
        {/*    padding: "1rem 2rem",*/}
        {/*  }}*/}
        {/*/>*/}

        <TextField
          label="enter your prompt here"
          multiline
          rows={4} // Shows two lines initially
          // placeholder="Prompt"
          // onBlur={handleBlur}
          onChange={(e) => setPost(e.target.value)}
          value={post}
          name="default"
          // error={
          //   Boolean(touched.firstName) && Boolean(errors.firstName)
          // }
          // helperText="Describe in detail, the image you would like to be generated"
          sx={{
            width: "100%",
            '& .MuiInputBase-root': {
              whiteSpace: 'normal', // Ensures text wraps and no horizontal scroll
              overflowY: 'auto', // Adds vertical scrollbar when content overflows
            },
          }}
        />
        <Button
          disabled={!post}
          onClick={handlePost}
          sx={{
            fontWeight: "bold",
            // m: "2rem 0",
            pl: "2.0rem",
            pr: "2.0rem",
            ml: "0.5rem",
            backgroundColor: pal.primary.main,
            color: pal.bg.lift,
            "&:hover": { color: pal.primary.main },
          }}
        >
          GENERATE
        </Button>
      </FlexBetween>

      {/*<FlexBetween>*/}
      {/*  <Button*/}
      {/*    disabled={!post}*/}
      {/*    onClick={handlePost}*/}
      {/*    sx={{*/}
      {/*      fontWeight: "bold",*/}
      {/*      // m: "2rem 0",*/}
      {/*      // p: "1rem",*/}
      {/*      ml: "0.5rem",*/}
      {/*      backgroundColor: pal.primary.main,*/}
      {/*      color: pal.bg.lift,*/}
      {/*      "&:hover": { color: pal.primary.main },*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    GENERATE*/}
      {/*  </Button>*/}
      {/*</FlexBetween>*/}

      <Divider sx={{ margin: "1.25rem 0" }} color={pal.primary.main} />

      {state === 'default' && (
        <Typography
          fontWeight="500"
          fontSize="0.75rem"
          fontStyle={"italic"}
          sx={{
            color: pal.neutral.mediumMain,
            mt: "0.07rem",
            mb: "1.0rem",
            mr: "0.7rem",
          }}
        >
          [ example image - SD 1.5 ] &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Enter your prompt above and generate something cool!
        </Typography>
      )}

      {state === 'default' && (
        <img
          src={`${Config.expressUri}/assets/img/forge/${randImage}`} // Assuming the image is in PNG format
          alt="Default AI image"
          style={{
            width: '768px',
            // maxWidth: '100%',
            // maxHeight: '512px',
            borderRadius: "4px",
          }}
        />
      )}

      {state === 'waiting' && (
        <Box width="768px">
          <FlexBetween>
            <ProgressCircleGradient />
            <ProgressCircleGradient />
            <ProgressCircleGradient />
            <ProgressCircleGradient />
            <ProgressCircleGradient />
          </FlexBetween>
          <br /><br /><br /><br /><br /><br />
          <FlexBetween>
            <ProgressCircleGradient />
            <ProgressCircleGradient />
            <ProgressCircleGradient />
            <ProgressCircleGradient />
            <ProgressCircleGradient />
          </FlexBetween>
          <br /><br /><br /><br /><br /><br />
          <FlexBetween>
            <ProgressCircleGradient />
            <ProgressCircleGradient />
            <ProgressCircleGradient />
            <ProgressCircleGradient />
            <ProgressCircleGradient />
          </FlexBetween>
          <br /><br /><br /><br /><br /><br />
          <FlexBetween>
            <ProgressCircleGradient />
            <ProgressCircleGradient />
            <ProgressCircleGradient />
            <ProgressCircleGradient />
            <ProgressCircleGradient />
          </FlexBetween>
          <br /><br /><br /><br /><br /><br />
          <FlexBetween>
            <ProgressCircleGradient />
            <ProgressCircleGradient />
            <ProgressCircleGradient />
            <ProgressCircleGradient />
            <ProgressCircleGradient />
          </FlexBetween>
        </Box>
      )}

      {state === 'completed' && (
        <img
          src={`data:image/png;base64,${result}`} // Assuming the image is in PNG format
          alt="Generated result"
          style={{
            width: '768px',
            // maxWidth: '100%',
            // maxHeight: '512px',
            borderRadius: "4px",
          }}
        />
      )}

      {state === 'error' && ("Something went wrong. Please try again.")}

      <Divider sx={{ margin: "1.25rem 0" }} color={pal.primary.main} />

      {state === 'completed' && (
        <Typography
          fontWeight="500"
          fontSize="0.75rem"
          sx={{
            color: pal.neutral.dark,
            mt: "0.07rem",
            mb: "0.05rem",
            mr: "0.7rem",
          }}
        >
          Seed: &nbsp;&nbsp;&nbsp;{seed}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Execute: &nbsp;&nbsp;&nbsp;{executionTime} ms
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Delay: &nbsp;&nbsp;&nbsp;{delayTime} ms
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Worker ID: &nbsp;&nbsp;&nbsp;{workerId}
        </Typography>
      )}

      {(state === 'default' || state === 'waiting') && (
        <Typography
          fontWeight="500"
          fontSize="0.75rem"
          sx={{
            color: pal.neutral.mediumMain,
            mt: "0.07rem",
            mb: "0.05rem",
            mr: "0.7rem",
          }}
        >
          Seed: &nbsp;&nbsp;&nbsp;[____]
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Execute: &nbsp;&nbsp;&nbsp;[____] ms
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Delay: &nbsp;&nbsp;&nbsp;[____] ms
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Worker ID: &nbsp;&nbsp;&nbsp;[____]
        </Typography>
      )}



    </WidgetWrapper>
  );
};

export default ForgeWidget;

