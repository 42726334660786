
import React from "react";
// import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { ScrollToTopOnLoad as LoadFresh } from "../../../utils";
// import * as Config from "../../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  Box,
  useMediaQuery,
} from "@mui/material";
import { Helmet } from 'react-helmet';
import NavbarPublic from "../../navbarPublic";
import Footer from "../../footer";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
// import ProjectSolarWidget from "../../widgets/projects/ProjectSolarWidget";
// import DetailSolarWidget from "../../widgets/projects/DetailSolarWidget";
// import DetailSolarWidget2 from "../../widgets/projects/DetailSolarWidge2";
import UnderConstructionWidget from "../../widgets/projects/UnderConstructionWidget";
import ProjectAnsibleWidget from "../../widgets/projects/ProjectAnsibleWidget";
import DetailAnsibleWidget from "../../widgets/projects/DetailAnsibleWidget";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */


const ProjectAnsiblePage = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");


  return (
    <div>
      <Helmet>
        <title>AnsibleMyEC2 - Open Source - Ansible Automation: AWS/EC2 - Cloud Migrations</title>
        <meta name="description" content="AnsibleMyEC2 - Open Source - Ansible Automation: AWS/EC2 - Cloud Migrations" />
      </Helmet>
      <Box>
        <NavbarPublic/>
        <Box
          width="100%"
          padding="2rem 4% 0"
          display={isNonMobileScreens ? "flex" : "block"}
          gap="1.5rem"
          justifyContent="center"
        >
          <ProjectAnsibleWidget />
        </Box>
        <Box
          width="100%"
          padding="2rem 4% 2rem"
          display={isNonMobileScreens ? "flex" : "block"}
          // gap="1.5rem"
          justifyContent="center"
        >
          <DetailAnsibleWidget />
        </Box>


        <Box
          display="block"
          width="100%"
          gap="1.5rem"
          padding="0.0rem"
        >
          <Footer />
        </Box>
      </Box>
      <LoadFresh />
    </div>
  );
};

export default ProjectAnsiblePage;


// https://github.com/jimmygizmo/AnsibleMyEC2

