import * as Config  from "../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {Box, IconButton, Typography, useTheme} from "@mui/material";
import FlexBetween from "./FlexBetween";
import React from "react";


const TechChip = ({
     techName,
     techColor,
   }) => {
  const theme = useTheme();
  const pal = theme.palette


  return (
    <FlexBetween
      // borderRadius="0.2rem"
      // borderRadius="2px"
      gap="0.7rem"
      justifyContent="space-between"
      sx={{
        textAlign: "center",
        borderRadius: "4px",
        border: '1px solid #808080',
      }}
    >
      <Box
        // width="0.35rem"
        width="6px"
        height="100%"
        backgroundColor={techColor}
        sx={{
          borderRadius: "3px 0px 0px 3px",
        }}
      ></Box>
      <Typography
        fontWeight="500"
        fontSize="0.75rem"
        sx={{
          color: pal.neutral.dark,
          mt: "0.07rem",
          mb: "0.05rem",
          mr: "0.7rem",
        }}
      >
        {techName}
      </Typography>
    </FlexBetween>
  );
};

export default TechChip;

