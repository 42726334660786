
import { useNavigate } from "react-router-dom";
import * as Config  from "../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Divider,
} from "@mui/material";
import {
  LocationOnOutlined,
  AccessTimeOutlined,
  WorkOutlineOutlined,
} from "@mui/icons-material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import FlexBetween from "../../components/FlexBetween";
import WidgetWrapper from "../../components/WidgetWrapper";
import HostImage from "../../components/HostImage";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */


const HostWidget = ({
    smallMode = false,
  }) => {
  const theme = useTheme();
  const pal = theme.palette;
  // const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  const isNonTinyScreens = useMediaQuery("(min-width: 800px)");
  const navigate = useNavigate();

  const picturePath = "JimmyGizmo-256x256-Fix2-otw.jpg";
  // TODO: Maybe upgrade HostImage so it is more like ProjectImage. The usage and vars here would also change.


  return (
    <WidgetWrapper>
      {/* -------- FIRST ROW -------- */}
      <FlexBetween
        gap="1.5rem"
        pb="1.1rem"
      >
        <FlexBetween gap="1.5rem">

          { isNonTinyScreens && (
            <HostImage image={picturePath} borderColor={pal.primary.main}/>
          )}
          <Box mb="0.5rem">
            <Typography
              variant="h3"
              color={pal.neutral.dark}
              fontWeight="600"
              marginBottom="0.75rem"
            >
              James Mannix
            </Typography>
            <Typography
              variant="h4"
              color={pal.neutral.dark}
              fontWeight="500"
              marginBottom="0.75rem"
            >
              Lead Developer from Elite Teams at Apple, Yahoo, SunPower, eToys
            </Typography>
            <FlexBetween gap="1.0rem">
              <List sx={{ pt: 0, pb: 0, listStyleType: 'disc', color: pal.neutral.main }}>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >Senior Full Stack Developer - 20 yrs</ListItem>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >Lead Python Programmer - 19 yrs</ListItem>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >Senior Linux & DevOps Engineer - 22 yrs</ListItem>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >Open Source Developer - 10 yrs</ListItem>
              </List>
              <List sx={{ pt: 0, pb: 0, listStyleType: 'disc', color: pal.neutral.main }}>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >React Developer - 9 yrs</ListItem>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >Frontend Developer & UI Designer - 20 yrs</ListItem>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >Cloud Architect, Developer (AWS+) - 12 yrs</ListItem>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >2 Machine Learning, Full Stack Patents</ListItem>
              </List>
            </FlexBetween>
          </Box>
        </FlexBetween>
      </FlexBetween>

      <Divider color={pal.warning.dark} sx={{ "background-color": pal.warning.dark }} />

      {/* -------- SECOND ROW -------- */}
      <Box p="1rem 0">
        <FlexBetween mb="0.5rem">
          <Typography variant="personal" color={pal.neutral.dark}>Welcome to my flagship portfolio site!</Typography>
        </FlexBetween>
        <FlexBetween>
          <Typography variant="personal" color={pal.neutral.dark} mb="0.5rem">
            &nbsp;&nbsp;&nbsp;&nbsp;I'm back on the market for a new contract or direct role.
            &nbsp;&nbsp;This site is where I showcase my paid career work as well as my
            Open Source and personal work.
            &nbsp;&nbsp;&nbsp;&nbsp;~ Jim</Typography>
        </FlexBetween>
      </Box>

      <Divider color={pal.warning.dark} sx={{ "background-color": pal.warning.dark }} />

      {/* -------- THIRD ROW -------- */}
      <Box p="1.0rem 0.0rem 1.0rem 0.0rem">
        <FlexBetween gap="2rem">
          <FlexBetween gap="1rem">
            <Box display="flex" alignItems="center" gap="1rem">
              <LocationOnOutlined fontSize="large" sx={{ color: pal.neutral.main }} />
              <Typography color={pal.neutral.medium}>100% Remote. &nbsp;Based in Northern CA</Typography>
            </Box>
          </FlexBetween>
          <FlexBetween gap="1rem">
            <Box display="flex" alignItems="center" gap="1rem">
              <AccessTimeOutlined fontSize="large" sx={{ color: pal.neutral.main }} />
              <Typography color={pal.neutral.medium}>Any schedule or timezone is OK</Typography>
            </Box>
          </FlexBetween>
          <FlexBetween gap="1rem">
            <Box display="flex" alignItems="center" gap="1rem">
              <WorkOutlineOutlined fontSize="large" sx={{ color: pal.neutral.main }} />
              <Typography color={pal.neutral.medium}>W2, Contract, Agency or Direct Hire</Typography>
            </Box>
          </FlexBetween>
        </FlexBetween>
      </Box>

      <Divider/>

      {/* -------- FOURTH ROW -------- */}
      <Box p="1.0rem 0.0rem 0.0rem 0.0rem">
        <FlexBetween gap="2rem">
          <FlexBetween gap="1rem">
            <img src={`${Config.expressUri}/assets/icons/youtube-24x24.png`} alt="YouTube" />
            <Box>
              <Typography color={pal.neutral.medium} fontSize="0.7rem" fontWeight="500">
                YouTube
              </Typography>
              <Typography color={pal.neutral.medium}>@jimmygizmo</Typography>
            </Box>
          </FlexBetween>

          <Typography color={pal.neutral.medium} height="100%">
            2019 Patent: (US10754999) ML/AI Full Stack, Solar System Design<br />
            2020 Patent: (US11392730) ML/AI Full Stack, Realtime/3D Solar Design
          </Typography>

          <FlexBetween gap="1rem">
            <img src={`${Config.expressUri}/assets/icons/linkedin.png`} alt="LinkedIn" />
            <Box>
              <Typography color={pal.neutral.medium} fontSize="0.7rem" fontWeight="500">
                Linkedin
              </Typography>
              <Typography color={pal.neutral.medium}>@jim-mannix</Typography>
            </Box>
          </FlexBetween>
        </FlexBetween>
      </Box>

    </WidgetWrapper>
  );
};

export default HostWidget;


// Patent into:
// https://patents.justia.com/assignee/sunpower-corporation?page=4

