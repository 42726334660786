
import React, {useState} from "react";  // For useState for POPUP for setOpen and setImageUrl
// import {useEffect} from "react";
import * as Config from "../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  Box,
  Typography,
  useTheme,
  Dialog,
  DialogContent,
  IconButton, useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import WidgetWrapper from "../../components/WidgetWrapper";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";


const DevListBasic = () => {
  const theme = useTheme();
  const pal = theme.palette;
  const [isMobileMenuToggled, setIsMobileMenuToggled] = useState(false);  // TODO: Might not need this. See below.
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  // FOR POPUP - BUT I DON'T SEE WHY I NEED THESE:
  const [open, setOpen] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState('');


  const handleLinkClickExt = (extUrl) => {
    if (!isNonMobileScreens) {
      setIsMobileMenuToggled(false);  // TODO: Correctness of this for ext link is debatable. Address everywhere.
    }
    window.open(
      extUrl,
      '_blank'
    );
  };


  const devCategories = [
    {
      nameLong: 'Python, Javascript, Shell, Linux - Full-Stack Core Languages',
      nameShort: 'Core Languages of Full-Stack',
      desc: 'The primary languages and commands of Full-Stack, including Python, Javascript/Node, BASH/ZSH/Linux, Docker. Flow-control, logic, execution, algorithms, data-structures, not UI stuff.',
      url: '/dev/full-stack/languages',
      token: 'full-stack-lang',
      keywords: 'full stack full-stack python javascript node shell bash zsh docker dockerfile compose algorithm data structure',
      id: '1',
    },
    {
      nameLong: 'CSS, HTML, React/JSX, MUI/MaterialUI - Full-Stack Frontend UI Frameworks',
      nameShort: 'Frontend Web UI Frameworks, Languages',
      desc: 'The primary frameworks and languages of frontend Web UI like CSS, HTML, React, JSX, JS (UI), Next.js,  MUI/Material UI, Tailwind, ChakraUI.',
      url: '/dev/full-stack/frontend',
      token: 'full-stack-lang',
      keywords: 'full stack full-stack css html mui material materialui react jsx next.js javascript tailwind chakraui chakra',
      id: '2',
    },
  ]

// TEMPLATE FOR CATEGORY ITEMS
  const templateCategoryItems = [
    {
      nameLong: '******** NAME-LONG ********',
      nameShort: '******** NAME-SHORT ********',
      desc: '******** DESCRIPTION ********',
      url: '******** URL (ROUTER PATH) ********',
      token: '******** TOKEN ********',
      keywords: '******** KEYWORDS ********',
      id: '0',
    },
  ]


  const devLinks = [
    {
      title: 'Tutorial Playlist: Procedural Cave Generation (Cellular Automata)',
      channel: 'Sebastian Lague (@SebastianLague)',
      site: 'YouTube',
      url: 'https://www.youtube.com/watch?v=v7yyZZjF1z4&list=PLFt_AvWsXl0eZgMK_DT5_biRkWXftAOf9',
      note: 'Amazing tutorial of a beautiful and powerful algorithm. Generating caves is beautiful coding. Sebastian rocks!',
      icon: 'yt',
      fmat: 'video/mp4/yt/playlist',
      format: 'video playlist',
    },
    {
      title: 'Tutorials Channel: Sebastian Lague - Coding/Algorithms/Gaming/3D/2D Tutorials',
      channel: 'Sebastian Lague (@SebastianLague)',
      site: 'YouTube',
      url: 'https://www.youtube.com/@SebastianLague/videos',
      note: 'One of my favorite people to learn absolutely amazing coding strategies from. God-level, fun tutorials.',
      icon: 'yt',
      fmat: 'video/mp4/yt/channel',
      format: 'video channel',
    },
    {
      title: 'How AI Understands Images (CLIP)',
      channel: 'Computerphile',
      site: 'YouTube',
      url: 'https://www.youtube.com/watch?v=KcSXcpluDe4',
      note: '(CLIP) Contrastive Language Image Pairs - A key aspect of image generation, Stable Diffusion.',
      icon: 'yt',
      fmat: 'video/mp4/yt',
      format: 'video',
    },
    {
      title: 'JavaScript ASYNC/AWAIT is easy!',
      channel: 'Bro Code',
      site: 'YouTube',
      url: 'https://www.youtube.com/watch?v=9j1dZwFEJ-c',
      note: 'A great video tutorial on asynchronous programming in Javascript.',
      icon: 'yt',
      fmat: 'video/mp4/yt',
      format: 'video',
    },
    {
      title: 'PyGAD - Python Genetic Algorithm!',
      channel: 'PyGad Open Source Project',
      site: 'ReadTheDocs.io - PyGAD',
      url: 'https://pygad.readthedocs.io/en/latest/',
      note: 'PyGAD is the primary library for leveraging Genetic Algorithms with Python.',
      icon: 'open-source-docs--or-docs',
      fmat: 'pages-home-static/docs',
      format: 'web page',
    },
    {
      title: 'BAML - Prompt Engineering Language - (Bad-Ass Machine Learning)',
      channel: 'BoundaryML',
      site: 'GitHub',
      url: 'https://github.com/BoundaryML/baml',
      note: 'BAML is a brand new language for formalizing and systematizing prompt engineering. BLEEDING EDGE!',
      icon: 'gh',
      fmat: 'repo-gh-home',
      format: 'code repo',
    },
    {
      title: 'How AI Image Generators Work (Stable Diffusion / Dall-E)',
      channel: 'Computerphile',
      site: 'YouTube',
      url: 'https://www.youtube.com/watch?v=1CIpzeNxIhU',
      note: 'Excellent explanation of how diffusion and AI/ML image-generation works',
      icon: 'yt',
      fmat: 'video/mp4/yt/tut',
      format: 'video',
    },
    {
      title: 'TensorFlow Tutorial 1 - Installation and Setup Deep Learning Environment (Anaconda and PyCharm)',
      channel: 'Aladdin Persson',
      site: 'YouTube',
      url: 'https://www.youtube.com/watch?v=5Ym-dOS9ssA&list=PLhhyoLH6IjfxVOdVC1P1L5z5azs0XjMsb',
      note: 'A very solid TensorFlow tutorial video. First of a series.',
      icon: 'yt',
      fmat: 'video/mp4/yt/tut',
      format: 'video',
    },
    {
      title: 'Watching Neural Networks Learn',
      channel: 'Emergent Garden',
      site: 'YouTube',
      url: 'https://www.youtube.com/watch?v=TkwXa7Cvfr8',
      note: 'It is very helpful to visualize how neural networks learn. Visualization is a powerful learning tool for abstract and complex concepts.',
      icon: 'yt',
      fmat: 'video/mp4/yt/tut',
      format: 'video',
    },
    {
      title: 'TensorFlow 2.0 Complete Course - Python Neural Networks for Beginners Tutorial',
      channel: 'freeCodeCamp.org - Tim Ruscica (Tech with Tim)',
      site: 'YouTube',
      url: 'https://www.youtube.com/watch?v=tPYj3fFJGjk',
      note: 'An excellent and complete course in TensorFlow in one long video.',
      icon: 'yt',
      fmat: 'video/mp4/yt/course',
      format: 'video',
    },
    {
      title: 'Making FPS Games in Python',
      channel: 'Sacko',
      site: 'YouTube',
      url: 'https://www.youtube.com/watch?v=ZC6vrcHni9E',
      note: 'A great and super fun way for Python coders to sharpen their coding chops, especially for real-time and 3D.',
      icon: 'yt',
      fmat: 'video/mp4/yt/course',
      format: 'video',
    },
    {
      title: '[Unity] Procedural Cave Generation (E01. Cellular Automata)',
      channel: 'Sebastian Lague',
      site: 'YouTube',
      url: 'https://www.youtube.com/watch?v=v7yyZZjF1z4&list=PLFt_AvWsXl0eZgMK_DT5_biRkWXftAOf9',
      note: 'For coders in any language - OUTSTANDING course on CELLULAR AUTOMATA (extremely powerful technique)',
      icon: 'yt',
      fmat: 'video/mp4/yt/course-playlist-1-of-9',
      format: 'video course playlist',
    },
    {
      title: 'Master Python by making 5 games [the new ultimate introduction to pygame]',
      channel: 'Clear Code',
      site: 'YouTube',
      url: 'https://www.youtube.com/watch?v=8OMghdHP-zs',
      note: 'For PyGame, Clear Code\'s tutorials are some of the best if not THE BEST. This is an amazing MEGA TUTORIAL!',
      icon: 'yt',
      fmat: 'video/mp4/yt/course-mega',
      format: 'mega video',
    },
    {
      title: 'Tutorial Overview - Custom Semantic Segmentation p.1',
      channel: 'Seth Adams',
      site: 'YouTube',
      url: 'https://www.youtube.com/watch?v=-Z7Sx2sS8z8&list=PLhA3b2k8R3t3zhbjDmqHudUTZqvRdELgd',
      note: 'Seth has created an excellent playlist of great videos comprising a complete course on IMAGE SEGMENTATION.',
      icon: 'yt',
      fmat: 'video/mp4/yt/course-playlist-1-of-14',
      format: 'video course playlist',
    },
    {
      title: 'How Stable Diffusion Works (AI Image Generation)',
      channel: 'Gonkee',
      site: 'YouTube',
      url: 'https://www.youtube.com/watch?v=sFztPP9qPRc',
      note: 'Quite an excellent and detailed deep-dive explanation of how Stable Diffusion works.',
      icon: 'yt',
      fmat: 'video/mp4/yt/tut',
      format: 'video',
    },
    {  // TODO: This is might actually be a series, just not in a playlist in this link. Figure that out.
      title: 'The Most Important Algorithm in Machine Learning (Backpropagation)',
      channel: 'Artem Kirsanov',
      site: 'YouTube',
      url: 'https://www.youtube.com/watch?v=SmZmBKc7Lrs',
      note: 'This is an important tutorial and series of videos on the algorithmic side of ML, with detailed explanations of the mathematics.',
      icon: 'yt',
      fmat: 'video/mp4/yt/tut',
      format: 'video',
    },
    {
      title: 'Tips for Optimizing Your React App’s Performance',
      channel: 'ArtConnor Peshek',
      site: 'SuperTokens',
      url: 'https://supertokens.com/blog/5-tips-for-optimizing-your-react-apps-performance',
      note: 'Optimizing React is a technically advanced topic. This article covers it well.',
      icon: 'webpage',
      fmat: 'pages-tut-static/tut',
      format: 'text tutorial',
    },
    {
      title: 'Navigating Dependencies: GPU, CUDA, TensorFlow',
      channel: 'Manuel Gonzalez-Rivero',
      site: '3DB Above',
      url: 'https://3dbabove.com/2020/04/23/navigating-dependencies-gpu-cuda-tensorflow/',
      note: 'A little outdated (2020) but still a great discussion of low level AI/GPU/CUDA/TensorFlow details.',
      icon: 'webpage',
      fmat: 'pages-tut-static/tut',
      format: 'text tutorial',
    },
    {
      title: 'Master Python by making 5 games [the new ultimate introduction to pygame]',
      channel: 'Clear Code',
      site: 'YouTube',
      url: 'https://www.youtube.com/watch?v=8OMghdHP-zs',
      note: '11 hours long and LOADED with the best PyGame dev info ever, from an absolute expert on PyGame.',
      icon: 'yt',
      fmat: 'video/mp4/yt/tut',
      format: 'video',
    },
  ]

// TEMPLATE FOR LINK ITEMS
const templateLinkItems = [
  {
    title: '******** TITLE ********',
    channel: '******** CHANNEL ********',
    site: '******** SITE ********',
    url: '******** URL ********',
    note: '******** NOTE ********',
    icon: '******** ICON ********',
    fmat: '******** FMAT ********',
    format: '******** FORMAT ********',
  },
]

  return (
    <WidgetWrapper>
      <Typography
        variant="h4"
        // fontWeight="bold"
        // fontSize="1.1rem"
        color={pal.neutral.dark}
        // variant="h5"
        // fontWeight="500"
        sx={{ mb: "1.5rem" }}
      >
        For Devs/Pros of AI/ML/Full-Stack/Python/React & more
      </Typography>
      <Box display="flex" flexDirection="column">
        {devLinks.map((item) => (
          <div>
            <Typography
              fontWeight="500"
              fontSize="1.0rem"
              color="primary"
              onClick={() => handleLinkClickExt(item.url)}
              sx={{
                "&:hover": {
                  cursor: "pointer",
                  color: pal.primary.less_ink,
                },
              }}
            >
              {item.title}
            </Typography>
            <Typography
              fontWeight="500"
              fontSize="0.75rem"
              sx={{
                color: pal.warning.main,
                mt: "0.07rem",
                mb: "0.05rem",
                mr: "0.7rem",
              }}
            >
              {item.note}
            </Typography>
            <Typography
              fontWeight="500"
              fontSize="0.75rem"
              color="primary"
              onClick={() => handleLinkClickExt(item.url)}
              sx={{
                "&:hover": {
                  cursor: "pointer",
                  color: pal.primary.less_ink,
                },
              }}
            >
              {item.url}
            </Typography>
            <Typography
              fontWeight="500"
              fontSize="0.75rem"
              sx={{
                color: pal.neutral.dark,
                mt: "0.07rem",
                mb: "0.05rem",
                mr: "0.7rem",
              }}
            >
              {item.channel} &nbsp;&nbsp;&nbsp;&nbsp;
              - &nbsp;&nbsp;&nbsp;&nbsp;{item.site} &nbsp;&nbsp;&nbsp;&nbsp;
              - &nbsp;&nbsp;&nbsp;&nbsp;({item.format})
            </Typography>
            <Box m="1.1rem 0" />
          </div>
        ))}
      </Box>
    </WidgetWrapper>
  );
};

export default DevListBasic;

