
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// import * as Config  from "../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import WidgetWrapper from "../../components/WidgetWrapper";
import UserTrending from "../../components/UserTrending";


const UsersTrendingWidgetFake = () => {
  const [isMobileMenuToggled, setIsMobileMenuToggled] = useState(false);
  const navigate = useNavigate();
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  const theme = useTheme();
  const pal = theme.palette

  const handleLinkClick = (path) => {
    navigate(path);
    if (!isNonMobileScreens) {
      setIsMobileMenuToggled(false); // Close the mobile menu after clicking a link
    }
  };

  const usersTrending = [
    {"_id":"63f88b6fd46a2f8f77116845","firstName":"Jimmy","lastName":"Gizmo",
      "picturePath":"JimmyGizmo-256x256-Fix2.png","location":"California"},
    {"_id":"63f88b6fd46a2f8f77116846","firstName":"SmartMetal","lastName":"Guest",
      "picturePath":"guest-200x200.png","location":"Earth"},
    {"_id":"63f88b6fd46a2f8f77116847","firstName":"C-3PO","lastName":" ",
      "picturePath":"c3po.jpeg","location":"Tatooine"},
    {"_id":"63f88b6fd46a2f8f77116848","firstName":"Sonny","lastName":" ",
      "picturePath":"sonny.jpeg","location":"NS-5 Factory, Tempe, AZ"},
    {"_id":"63f88b6fd46a2f8f77116849","firstName":"R2-D2","lastName":" ",
      "picturePath":"r2d2.jpeg","location":"Kingdom of Naboo"},
    {"_id":"63f88b6fd46a2f8f7711684a","firstName":"Futura","lastName":" ",
      "picturePath":"futura.jpeg","location":"Metropolis, Germany"},
    {"_id":"63f88b6fd46a2f8f7711684b","firstName":"HAL","lastName":"9000",
      "picturePath":"hal9000.jpeg","location":"Urbana, Illinois"},
    {"_id":"63f88b6fd46a2f8f7711684c","firstName":"T1000","lastName":" ",
      "picturePath":"t1000.jpeg","location":"Skynet Main Complex"},
    {"_id":"63f88b70d46a2f8f7711685b","firstName":"Atlas","lastName":" ",
      "picturePath":"atlas.jpeg","location":"Boston, Massachusetts"},
    {"_id":"63f88b70d46a2f8f7711685c","firstName":"Data","lastName":" ",
      "picturePath":"data.jpeg","location":"Omicron Theta"},
    {"_id":"63f88b70d46a2f8f7711685d","firstName":"Asimo","lastName":" ",
      "picturePath":"asimo.jpeg","location":"Tokyo, Japan"},
  ];


  // TODO: Add EVE and WALL-E and others maybe

  // TODO: All/Most code is here for the LINK TO MATRIX but the span is a problem.
  // Need to use typography but keep on the same line, because I need to use sx prop to get hover to work.
  // I can get it to work with span and span-style is bad for accessibility.


  return (
    <WidgetWrapper>
      <Typography
        color={pal.neutral.dark}
        fontSize="1.1rem"
        fontWeight="500"
        sx={{
          mb: "0.5rem",
      }}
      >
        <span
          onClick={() => handleLinkClick("/matrix")}
          style={{
          color: pal.warning.main,
          fontWeight: "600",
          fontStyle: "italic",
          "&:hover": {
            cursor: "pointer",
            color: pal.primary.less_ink,
          },
        }}>Matrix</span> &nbsp; - &nbsp; Trending
      </Typography>
      <Typography
        color={pal.neutral.medium}
        fontSize="0.8rem"
        fontWeight="500"
        sx={{ mb: "1.5rem" }}
      >
        An AI/ML Pro Network
      </Typography>
      <Box display="flex" flexDirection="column" gap="1.5rem">
        {usersTrending.map((user) => {
          console.log("= = = = UsersTrendingWidgetFake: " + JSON.stringify(user));
          return(
          <UserTrending
            key={user._id}
            userId={user._id}
            name={`${user.firstName} ${user.lastName}`}
            // subtitle={user.occupation}
            subtitle={user.location}
            userPicturePath={user.picturePath}
          />
          );
        }
        )}
      </Box>
    </WidgetWrapper>
  );
};

export default UsersTrendingWidgetFake;

