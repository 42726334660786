import React, { useState } from "react";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  setMode,
  setLogout
} from "../../state";
// import * as Config from "../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  InputBase,
  FormControl,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import {
  DarkMode,
  LightMode,
  Menu,
  Close,
  // Search,
  // Message,
  // Notifications,
  // Help,
} from "@mui/icons-material";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import FlexBetween from "../../components/FlexBetween";


const NavbarAuth = () => {
  const [isMobileMenuToggled, setIsMobileMenuToggled] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  const theme = useTheme();
  const pal = theme.palette;
  const dark = theme.palette.neutral.dark;

  const fullName = `${user.firstName} ${user.lastName}`;


  const handleLinkClick = (path) => {
    navigate(path);
    if (!isNonMobileScreens) {
      setIsMobileMenuToggled(false); // Close the mobile menu after clicking a link
    }
  };


  return (
    <FlexBetween
      padding="1rem 4%"
      backgroundColor={pal.bg.lift}
      sx={{
        borderBottom: '1px solid',
        borderColor: pal.warning.main,
      }}
    >
      <FlexBetween gap="1.2rem">
        <Typography
          variant="mainlogo"
          fontWeight="bold"
          fontSize="36px"
          color="primary"
          onClick={() => navigate("/")}
          sx={{
            mr: "1rem",
            "&:hover": {
              color: pal.primary.less_ink,
              cursor: "pointer",
            },
          }}
        >
          SmartMetal.ai
        </Typography>
        <Typography
          variant="mainlogo"
          fontWeight="bold"
          fontSize="36px"
          fontStyle="italic"
          color={pal.warning.main}
          onClick={() => navigate("/matrix")}
          sx={{
            "&:hover": {
              color: pal.primary.less_ink,
              cursor: "pointer",
            },
          }}
        >
          Matrix
        </Typography>
        {isNonMobileScreens && (
          <Box display="flex" flexDirection="row" gap="4rem" width="100%">
            <Typography
              fontWeight="500"
              fontSize="0.9rem"
              fontStyle="italic"
              display="inline"
              sx={{
                mt: "0.4rem",
                pt: "0.2rem",
              }}
            >
              &nbsp;&nbsp;AI/ML Network
            </Typography>
            <Typography
              fontWeight="600"
              fontSize="0.9rem"
              color="primary"
              onClick={() => handleLinkClick("/forge")}
              sx={{
                mt: "0.4rem",
                "&:hover": {
                  cursor: "pointer",
                  color: pal.primary.less_ink,
                },
              }}
            >
              Forge Generator
            </Typography>
            <Typography
              fontWeight="600"
              fontSize="0.9rem"
              color="primary"
              onClick={() => handleLinkClick("/dream")}
              sx={{
                mt: "0.4rem",
                "&:hover": {
                  cursor: "pointer",
                  color: pal.primary.less_ink,
                },
              }}
            >
              Dream-o-matic
            </Typography>
            <Typography
              fontWeight="600"
              fontSize="0.9rem"
              color="primary"
              onClick={() => handleLinkClick("/gallery")}
              sx={{
                mt: "0.4rem",
                "&:hover": {
                  cursor: "pointer",
                  color: pal.primary.less_ink,
                },
              }}
            >
              Gen AI Gallery
            </Typography>
            <Typography
              fontWeight="600"
              fontSize="0.9rem"
              color="primary"
              onClick={() => handleLinkClick("/dev")}
              sx={{
                mt: "0.4rem",
                "&:hover": {
                  cursor: "pointer",
                  color: pal.primary.less_ink,
                },
              }}
            >
              Developer Resources
            </Typography>
          </Box>
        )}
      </FlexBetween>

      {/* DESKTOP NAV */}
      {isNonMobileScreens ? (
        <FlexBetween gap="1.5rem">
          <IconButton onClick={() => dispatch(setMode())}>
            {theme.palette.mode === "dark" ? (
              <LightMode sx={{ color: dark, fontSize: "25px" }} />
            ) : (
              <DarkMode sx={{ fontSize: "25px" }} />
            )}
          </IconButton>
          {/*<Message sx={{ fontSize: "25px" }} />*/}
          {/*<Notifications sx={{ fontSize: "25px" }} />*/}
          {/*<Help sx={{ fontSize: "25px" }} />*/}
          <FormControl variant="standard" value={fullName}>
            <Select
              value={fullName}
              sx={{
                backgroundColor: pal.neutral.least_ink,
                width: "160px",
                borderRadius: "0.25rem",
                p: "0.25rem 1rem",
                "& .MuiSvgIcon-root": {
                  pr: "0.25rem",
                  width: "3rem",
                },
                "& .MuiSelect-select:focus": {
                  backgroundColor: pal.neutral.least_ink,
                },
              }}
              input={<InputBase />}
            >
              <MenuItem value={fullName}>
                <Typography>{fullName}</Typography>
              </MenuItem>
              <MenuItem onClick={() => dispatch(setLogout())}>SIGN OUT</MenuItem>
            </Select>
          </FormControl>
        </FlexBetween>
      ) : (
        <IconButton
          onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
        >
          <Menu />
        </IconButton>
      )}

      {/* MOBILE NAV */}
      {!isNonMobileScreens && isMobileMenuToggled && (
        <Box
          position="fixed"
          right="0"
          bottom="0"
          height="100%"
          zIndex="10"
          maxWidth="500px"
          minWidth="300px"
          backgroundColor={pal.bg.bg}
        >
          {/* CLOSE ICON */}
          <Box display="flex" justifyContent="flex-end" p="1rem">
            <IconButton
              onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
            >
              <Close />
            </IconButton>
          </Box>

          {/* MENU ITEMS */}
          <FlexBetween
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="3rem"
          >
            <Typography
              variant="h5"
              color="primary"
              onClick={() => handleLinkClick("/matrix")}
              sx={{
                "&:hover": {
                  cursor: "pointer",
                  color: pal.primary.less_ink,
                },
              }}
            >
              Matrix - AI/ML Network
            </Typography>
            <Typography
              variant="h5"
              color="primary"
              onClick={() => handleLinkClick("/forge")}
              sx={{
                "&:hover": {
                  cursor: "pointer",
                  color: pal.primary.less_ink,
                },
              }}
            >
              Forge Generator
            </Typography>
            <Typography
              variant="h5"
              color="primary"
              onClick={() => handleLinkClick("/dream")}
              sx={{
                "&:hover": {
                  cursor: "pointer",
                  color: pal.primary.less_ink,
                },
              }}
            >
              Dream-o-matic
            </Typography>
            <Typography
              variant="h5"
              color="primary"
              onClick={() => handleLinkClick("/gallery")}
              sx={{
                "&:hover": {
                  cursor: "pointer",
                  color: pal.primary.less_ink,
                },
              }}
            >
              Gen AI Gallery
            </Typography>
            <Typography
              fontSize="16px"
              color="primary"
              onClick={() => handleLinkClick("/dev")}
              sx={{
                mt: "0.4rem",
                "&:hover": {
                  cursor: "pointer",
                  color: pal.primary.less_ink,
                },
              }}
            >
              Developer Resources
            </Typography>

            <IconButton
              onClick={() => dispatch(setMode())}
              sx={{ fontSize: "25px" }}
            >
              {theme.palette.mode === "dark" ? (
                <LightMode sx={{ color: dark, fontSize: "25px" }} />
              ) : (
                <DarkMode sx={{ fontSize: "25px" }} />
              )}
            </IconButton>
            {/*<Message sx={{ fontSize: "25px" }} />*/}
            {/*<Notifications sx={{ fontSize: "25px" }} />*/}
            {/*<Help sx={{ fontSize: "25px" }} />*/}
            <FormControl variant="standard" value={fullName}>
              <Select
                value={fullName}
                sx={{
                  backgroundColor: pal.neutral.least_ink,
                  width: "160px",
                  borderRadius: "0.25rem",
                  p: "0.25rem 1rem",
                  "& .MuiSvgIcon-root": {
                    pr: "0.25rem",
                    width: "3rem",
                  },
                  "& .MuiSelect-select:focus": {
                    backgroundColor: pal.neutral.least_ink,
                  },
                }}
                input={<InputBase />}
              >
                <MenuItem value={fullName}>
                  <Typography>{fullName}</Typography>
                </MenuItem>
                <MenuItem onClick={() => dispatch(setLogout())}>SIGN OUT</MenuItem>
              </Select>
            </FormControl>
          </FlexBetween>
        </Box>
      )}
    </FlexBetween>
  );
};

export default NavbarAuth;

