
import {useNavigate} from "react-router-dom";
import * as Config  from "../../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Divider,
  IconButton,
} from "@mui/material";
import { ZoomIn } from "@mui/icons-material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import FlexBetween from "../../../components/FlexBetween";
import WidgetWrapper from "../../../components/WidgetWrapper";
import ProjectImage from "../../../components/ProjectImage";
import TechChip from "../../../components/TechChip";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */


const ProjectBotFolkWidget = ({
    smallMode = false,
  }) => {
  const theme = useTheme();
  const pal = theme.palette;
  // const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  const isNonTinyScreens = useMediaQuery("(min-width: 800px)");
  const navigate = useNavigate();

  const imageFileProject = "thm/botfolk-1920x1508-thumb.png";
  const projectUrl = "/project/botfolk";
  const imageAlt = "BotFolk.ai - Next.js Prototype Full Stack, Synthetic Life (screenshot)";


  return (
    <WidgetWrapper>
      {/* -------- FIRST ROW -------- */}
      <FlexBetween
        gap="1.5rem"
        pb="1.1rem"
      >
        <FlexBetween gap="1.5rem">
          { isNonTinyScreens && (
            <ProjectImage
              smallMode={smallMode}
              imageFileProject={imageFileProject}
              imageAlt={imageAlt}
              imageLinkUrl={projectUrl}
            />
          )}
          <Box>
            <FlexBetween gap="0.5rem">
              <Typography
                variant="h4"
                color={pal.neutral.dark}
                fontWeight="600"
                marginBottom="0.75rem"
              >
                BotFolk.ai - Next.js Prototype Full Stack, Synthetic Life
              </Typography>
              {/* -------- ZOOM NAVIGATE - ONLY IN SMALL MODE -------- */}
              { smallMode && (
                <IconButton
                  onClick={() => navigate(projectUrl)}
                >
                  <ZoomIn
                    sx={{
                      fontSize: "1.8rem",
                      color: pal.primary.main,
                    }}
                  />
                </IconButton>
              )}
            </FlexBetween>

            {/* -------- FIRST ROW - SUB-HEADING & BULLETS -------- */}
            <Typography
              variant="h5"
              color={pal.neutral.dark}
              fontWeight="500"
              marginBottom="0.75rem"
            >
              Next.js Open Source Prototype, Hobby Project - 2021 to 2023, ongoing
            </Typography>
            <FlexBetween gap="1.0rem">
              <List sx={{ pt: 0, pb: 0, listStyleType: 'disc', color: pal.neutral.main }}>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >
                  Next.js - SEO-Optimized, Ultra-Fast React/Node
                </ListItem>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >
                  Next.js is the SSR (Server-Side Rendering) React
                </ListItem>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >
                  Next.js Operates with A Node-based Backend Server
                </ListItem>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >
                  3D via Three.js, React Three Fiber, Custom Code
                </ListItem>
              </List>
              <List sx={{ pt: 0, pb: 0, listStyleType: 'disc', color: pal.neutral.main }}>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >
                  Fully Automated Build and Deployment via BASH/ZSH
                </ListItem>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >
                  Custom Python Makes Unique SVG Faces from Data DNA
                </ListItem>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >
                  Custom Javascript Extrudes Unique Face SVGs to 3D GLTF
                </ListItem>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >
                  Simulated Genetics, Evolution, Ecosystem, Community
                </ListItem>
              </List>
            </FlexBetween>
          </Box>
        </FlexBetween>
      </FlexBetween>

      <Divider color={pal.warning.dark} sx={{ "background-color": pal.warning.dark }} />

      {/* -------- SECOND ROW -------- */}
      <Box p="1rem 0">
        <FlexBetween mb="0.5rem">
          <Typography
            // variant="personal"
            fontSize="1.0rem"
            color={pal.neutral.dark}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;BotFolk.ai is my prototype implementation of Next.js, an ultra-fast React variant, best for SEO.
            &nbsp;&nbsp;Next.js uses Server-Side Rendering on a Node.js server.
            &nbsp;&nbsp;The BotFolk project itself is a game-like simulation of simple human-like life forms and ecosystems.
            &nbsp;&nbsp;This is a remake of a winning Science Fair project with some experimental
            modules bolted on.</Typography>
        </FlexBetween>
      </Box>

      <Divider color={pal.warning.dark} sx={{ "background-color": pal.warning.dark }} />

      {/* -------- THIRD ROW -------- */}
      <Box p="1.0rem 0.0rem 0.0rem 0.0rem">
        <Box display="flex" flexWrap="wrap" gap="0.5rem" mb="0.0rem" mt="0.5rem"
             sx={{
               "& .MuiChip-label": { fontSize: ".85rem", fontWeight: "bold" }, // Slightly bigger chip label
             }}>
          <TechChip techName={"Next.js Server-Side Rendering"} techColor={pal.primary.main} />
          <TechChip techName={"React"} techColor={pal.primary.main} />
          <TechChip techName={"Tailwind CSS"} techColor={pal.primary.main} />
          <TechChip techName={"3D: Three.js, React Three Fiber"} techColor={pal.primary.main} />
          <TechChip techName={"3D: Custom JS SVG Extrusion"} techColor={pal.primary.main} />
          <TechChip techName={"Auth/Auth: JWT (JSON Web Token)"} techColor={pal.primary.main} />
          <TechChip techName={"Express API/Data Model"} techColor={pal.warning.main} />
          <TechChip techName={"PostgreSQL DB"} techColor={pal.secondary.main} />
          <TechChip techName={"Pydantic"} techColor={pal.error.main} />
          <TechChip techName={"Forms: Formic"} techColor={pal.primary.main} />
          <TechChip techName={"Validation: Yup"} techColor={pal.warning.main} />
          <TechChip techName={"Uploads: Multer"} techColor={pal.primary.main} />
          <TechChip techName={"Components: Material UI"} techColor={pal.primary.main} />
          <TechChip techName={"Postgres Admin IDE: PgAdmin"} techColor={pal.primary.main} />
          <TechChip techName={"Testing: Playwright"} techColor={pal.primary.main} />
          <TechChip techName={"Styling: Material UI"} techColor={pal.primary.main} />
          <TechChip techName={"Python: SVG Bot Avatars from DNA"} techColor={pal.warning.main} />
          <TechChip techName={"4-5 Docker Container Stack"} techColor={pal.neutral.main} />
          <TechChip techName={"Docker Compose"} techColor={pal.neutral.main} />
          <TechChip techName={"Build Auto.: BASH/ZSH"} techColor={pal.secondary.dark} />
          <TechChip techName={"Deploy Auto.: BASH/ZSH"} techColor={pal.secondary.dark} />
          <TechChip techName={"CI/CD-Ready"} techColor={pal.secondary.dark} />
          <TechChip techName={"NGINX A+ SSL Security"} techColor={pal.success.main} />
          <TechChip techName={"NGINX Acceleration, WebSockets"} techColor={pal.success.main} />
          <TechChip techName={"NGINX Advanced Multi-Domain"} techColor={pal.success.main} />
          <TechChip techName={"ENV Config, Secret Security"} techColor={pal.neutral.main} />
          <TechChip techName={"4-5 Docker Container Stack"} techColor={pal.neutral.main} />
          <TechChip techName={"Docker Compose - Advanced"} techColor={pal.neutral.main} />
          <TechChip techName={"DataDog-Ready"} techColor={pal.neutral.main} />
          <TechChip techName={"Cloud-Ready (AWS+)"} techColor={pal.neutral.main} />
        </Box>

      </Box>
    </WidgetWrapper>
  );
};

export default ProjectBotFolkWidget;

