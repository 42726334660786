import React, {
  useEffect,
  // useState,
} from "react";
// import { useSelector } from "react-redux";
// import { useParams } from "react-router-dom";
import { ScrollToTopOnLoad as LoadFresh } from "../../utils";
// import * as Config from "../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Helmet } from "react-helmet";
import NavbarPublic from "../navbarPublic";
import Footer from "../footer";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import GalleryWidgetA from "../widgets/GalleryWidgetA";


const ExhibitPage = () => {
  // const [user, setUser] = useState(null);
  // const { userId } = useParams();
  // const token = useSelector((state) => state.token);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const { palette } = useTheme();
  // FOR POPUP - BUT I DON'T SEE WHY I NEED THESE - MAYBE IT's JUST HOW THE POPUP GETS ITS ARGS:
  // const [open, setOpen] = React.useState(false);
  // const [imageUrl, setImageUrl] = React.useState('');

  // const getUser = async () => {
  //   const response = await fetch(`${Config.expressUri}/users/${userId}`, {
  //     method: "GET",
  //     // headers: { Authorization: `Bearer ${token}` },
  //   });
  //   const data = await response.json();
  //   setUser(data);
  // };

  useEffect(() => {
    // getUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div>
      <Helmet>
        <title>SmartMetal.ai - Exhibit of AI-Generated Images & Video</title>
        <meta name="description" content="AI Generated Video & Images - Resources for Machine Learning Developers" />
      </Helmet>
      <Box>
        <NavbarPublic />
        <Box
          display="block"
          width="100%"
          padding="2rem 4%"
          gap="1.5rem"
          justifyContent="left"
          paddingBottom="0.0rem"
          // sx={{ pb: "0.0rem" }}  // SAME AS ABOVE paddingBottom="0.0rem". BOTH WORK.
        >
          <Typography
            fontWeight="bold"
            fontSize="1.2rem"
            color={palette.neutral.dark}
            // variant="h5"
            // fontWeight="500"
            // sx={{ mb: "0.7rem" }}
          >
            Exhibit of AI-Generated Images & Video
          </Typography>
        </Box>
        <Box
          width="100%"
          padding="2rem 4%"
          display={isNonMobileScreens ? "flex" : "block"}
          gap="2rem"
          justifyContent="center"
        >
          <Box flexBasis="88%">
            <GalleryWidgetA/>
            <Box m="2rem 0"/>
          </Box>
        </Box>
        <Box
          display="block"
          width="100%"
          gap="1.5rem"
          padding="0.0rem"
        >
          <Footer />
        </Box>
      </Box>
      <LoadFresh />
    </div>
  );
};

export default ExhibitPage;

