
import React, {
  Component,
  // useEffect,
} from 'react';
import ReactPlayer from 'react-player';
import * as Config from "../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */

// This page is minimal because it is to test the CssBaseline setting of the default background color in the
// context of our theme usage. Issue was not being able to change the keys of theme.palette.background.
// This test proved that the BG was being set somewhere in the early/low-level rendering of CssBaseline which I
// could NOT see in the app code. Some special method is required to change the names of the theme/palette keys.
// Goal is to have my custom theme names which are less confusing to me but also I want them very short.
// Still working on that. We could have many test pages like these and under /ll1l1l1ll/ is the correct place for
// them. under /citadel/ would be fine too,
// but I don't think such test pages need the IP Access control of /citadel/.


// const TestPage = () => {
//
//   let container = document.getElementById('container');
//   let myUrl = 'https://www.youtube.com/watch?v=ysz5S6PUM-U';
//
//   const script = document.createElement('script');
//   script.src = 'https://cdn.jsdelivr.net/npm/react-player/dist/ReactPlayer.standalone.js';
//   script.async = true;
//   document.head.appendChild(script);
//
//   useEffect(() => {
//     const script = document.createElement('script');
//     script.src = 'https://cdn.jsdelivr.net/npm/react-player/dist/ReactPlayer.standalone.js';
//     script.async = true;
//     document.body.appendChild(script);
//
//     return () => {
//       document.body.removeChild(script); // Clean up on unmount
//     };
//   }, []);
//
//   return (
//     <div>
//       {/*<script src='https://cdn.jsdelivr.net/npm/react-player/dist/ReactPlayer.standalone.js'></script>*/}
//       <script>
//         renderReactPlayer(container, { myUrl })
//
//         {/*function pausePlayer () {*/}
//         {/*  renderReactPlayer(container, { myUrl })*/}
//         {/*};*/}
//       </script>
//
//       {/*<ReactPlayer url='https://www.youtube.com/watch?v=ysz5S6PUM-U' playing />*/}
//       {/*<ReactPlayer url='/express/assets/projects/vid/SunPowerVid02.mp4' />*/}
//
//       {/*{ renderReactPlayer(container, { myUrl }) }*/}
//
//       <div id='container'></div>
//     </div>
//   );
// };


// Initial, simplest attempt
const TestPage = () => {

  const videoUrl  = `${Config.expressUri}/assets/projects/vid/SunPowerVid02.mp4`;
  // const videoUrl  = "https://www.youtube.com/watch?v=ysz5S6PUM-U";

  return (
    <div>
      {/*<ReactPlayer url='https://www.youtube.com/watch?v=ysz5S6PUM-U' playing />*/}
      <ReactPlayer url={ videoUrl } />
    </div>
  );
};


// Per the latest docs an extended class is used (???)
// class TestPage extends Component {
//
//   render () {
//     return (
//       // <div>
//       //   <ReactPlayer url='https://www.youtube.com/watch?v=ysz5S6PUM-U' playing />
//         <ReactPlayer url='/express/assets/projects/vid/SunPowerVid02.mp4' />
//       // </div>
//     );
//   };
// }

export default TestPage;

