import React from "react";
// import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { ScrollToTopOnLoad as LoadFresh } from "../../../utils";
// import * as Config from "../../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  Box,
  useMediaQuery,
} from "@mui/material";
import { Helmet } from 'react-helmet';
import NavbarPublic from "../../navbarPublic";
import Footer from "../../footer";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import ProjectNucleusWidget from "../../widgets/projects/ProjectNucleusWidget";


const ProjectNucleusPage = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  // const { _id, picturePath } = useSelector((state) => state.user);

  // const navigate = useNavigate();
  // const handleLinkClick = (path) => {
  //   navigate(path);
  //   // if (!isNonMobileScreens) {
  //   //   setIsMobileMenuToggled(false); // Close the mobile menu after clicking a link
  //   // }
  // };


  return (
    <div>
      <Helmet>
        <title>SmartMetal.ai - The Nucleus Stack - Open Source Project - React MERN Stack - MongoDB, Apollo GraphQL, NGINX, React</title>
        <meta name="description" content="The Nucleus Stack - Open Source Project - React MERN Stack - MongoDB, Apollo GraphQL, NGINX, React" />
      </Helmet>
      <Box>
        <NavbarPublic/>
        <Box
          width="100%"
          padding="2rem 4%"
          display={isNonMobileScreens ? "flex" : "block"}
          gap="1.5rem"
          justifyContent="center"
        >
          <ProjectNucleusWidget />
          <a href="https://github.com/jimmygizmo/nucleus" target="_blank" rel="noreferrer">
            GitHub: The Nucleus Stack
          </a>
        </Box>
        <Box
          display="block"
          width="100%"
          gap="1.5rem"
          padding="0.0rem"
        >
          <Footer />
        </Box>
      </Box>
      <LoadFresh />
    </div>
  );
};

export default ProjectNucleusPage;

