import React from "react";
// import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { ScrollToTopOnLoad as LoadFresh } from "../../../utils";
// import * as Config from "../../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  Box,
  useMediaQuery,
} from "@mui/material";
import { Helmet } from 'react-helmet';
import NavbarPublic from "../../navbarPublic";
import Footer from "../../footer";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
// import HostWidget from "../../widgets/HostWidget";
import RTX5090AdvertWidget from "../../widgets/ads/RTX5090AdvertWidget";
// import UsersTrendingWidgetFake from "../../widgets/UsersTrendingWidgetFake";
// import GalleryWidgetPop from "../../widgets/GalleryWidgetPop";


const RTX5090Page = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  // const { _id, picturePath } = useSelector((state) => state.user);

  // const navigate = useNavigate();
  // const handleLinkClick = (path) => {
  //   navigate(path);
  //   // if (!isNonMobileScreens) {
  //   //   setIsMobileMenuToggled(false); // Close the mobile menu after clicking a link
  //   // }
  // };


  return (
    <div>
      <Helmet>
        <title>SmartMetal.ai - NVIDIA RTX 5090 - 32 GB GPU - Lowest Prices</title>
        <meta name="description" content="NVIDIA RTX 5090 - 32 GB GPU - Lowest Prices" />
      </Helmet>
      <Box>
        <NavbarPublic/>
        <Box
          width="100%"
          padding="2rem 4%"
          display={isNonMobileScreens ? "flex" : "block"}
          gap="1.5rem"
          justifyContent="center"
        >
          <a href="https://marketplace.nvidia.com/en-us/consumer/graphics-cards/gigabyte-nvidia-geforce-rtx-5090-windforce-overclocked-triple-fan/" target="_blank" rel="noreferrer">
            {/*<a href="https://www.nvidia.com/en-us/geforce/graphics-cards/50-series/" target="_blank" rel="noreferrer">*/}
            <RTX5090AdvertWidget />
          </a>
        </Box>
        <Box
          display="block"
          width="100%"
          gap="1.5rem"
          padding="0.0rem"
        >
          <Footer />
        </Box>
      </Box>
      <LoadFresh />
    </div>
  );
};

export default RTX5090Page;

