
// FUNCTIONAL THEME COLORS - PERSPECTIVE IS FROM LIGHT MODE, BLACK INK ON PAPER.
// SAY "BIT MORE INK" OR "MUCH MORE INK" or "BIT LESS INK" OR "BIT MORE INK"
// This can be white ink on black paper or black ink on white paper.

// EXCEPTIONS: Background is [literally darker than] background-raised for both light/dark.
// So Background(dark) = LEAST INK (normal)  [less white ink = literally darker]
//     and Background-raised(dark) = BIT MORE INK (normal)  [literally lighter]
// *BUT* Background(light) is *NOT* LEAST INK (not normal)
//     Background-raised(light) =LEAST INK (not normal) [less black ink = literally lighter]
// * These two factors are so far in the LEAST/LESS INK END that there should be no conflict affecting other values
// like hover, so I don't expect any more non-normal cases.
// Everything should now progress logically towards MAXIMUM INK for the most prominent and most foreground elements.

// * If there are any more non-normal cases, it might be for HOVER.

// The best symbols will be short and easily convey the EFFECT, LOOK OR FUNCTION IN THE UI AS THE USER EXPERIENCES IT

// bg.bg         Background                      background.default
// bg.lift       Background-raised               background.alt

// pri.glow      Primer-glow-or-notice  (PROPOSED) Possibly would be MOST INK POSSIBLE (making prime slightly less ink)
// pri.prime     Primary                            primary.dark
// pri.fade      Primary-faded-or-hover             primary.main
// pri.sub       Primary-subdued


// primary.light   where? Hover on user profile name. Hover on main log and nav links.

// Secondary
// Secondary-faded-or-hover

// Text-Main
// Text-Normal
// Text-Subdued

// Link
// Link-Hover

// * Primary and Secondary have one "fade level" hence we use that word "faded" for it.
//    However, Text has two "fade levels" with LESS INK than Main, so we call those Normal and Subdued.
//    Subdued does not need to be super easy to read, but Normal must be easy to read TODO: Fix this for dark mode.






export const colorSets = {
  original_grey: {
    0: "#FFFFFF",
    10: "#F6F6F6",
    50: "#F0F0F0",
    100: "#E0E0E0",
    200: "#C2C2C2",
    300: "#A3A3A3",
    400: "#858585",
    500: "#666666",
    600: "#4D4D4D",
    700: "#333333",
    800: "#1A1A1A",
    900: "#0A0A0A",
    1000: "#000000",
  },
  adjusted_grey: {
    0: "#FFFFFF",
    10: "#F6F6F6",
    50: "#F0F0F0",
    100: "#E0E0E0",
    200: "#C2C2C2",
    300: "#A3A3A3",
    400: "#858585",
    500: "#666666",
    600: "#4D4D4D",
    700: "#333333",
    800: "#1A1A1A",
    900: "#0A0A0A",
    1000: "#000000",
  },
  original_primary: {
    50: "#E6FBFF",
    100: "#CCF7FE",
    200: "#99EEFD",
    300: "#66E6FC",
    400: "#33DDFB",
    500: "#00D5FA",
    600: "#00A0BC",
    700: "#006B7D",
  },
  adjusted_primary: {
    50: "#E6FBFF",
    100: "#B3EFFF",
    200: "#99EEFD",
    300: "#66D7F9",
    400: "#33C8F5",
    500: "#00B8E6",
    600: "#008C9E",
    700: "#00565A",
    800: "#003D47", // (Dark navy blue with a hint of green)
    900: "#002B34", // (Even darker, deep ocean blue)
    1000: "#001A1F", // (Rich, almost midnight blue)
  },
};


// color design tokens export
export const colorTokens = {
  grey: colorSets.adjusted_grey,
  primary: colorSets.adjusted_primary,
};


// color design tokens export
// export const colorTokens = {
//   grey: {
//     0: "#FFFFFF",
//     10: "#F6F6F6",
//     50: "#F0F0F0",
//     100: "#E0E0E0",
//     200: "#C2C2C2",
//     300: "#A3A3A3",
//     400: "#858585",
//     500: "#666666",
//     600: "#4D4D4D",
//     700: "#333333",
//     800: "#1A1A1A",
//     900: "#0A0A0A",
//     1000: "#000000",
//   },
//   primary: {
//     50: "#E6FBFF",
//     100: "#CCF7FE",
//     200: "#99EEFD",
//     300: "#66E6FC",
//     400: "#33DDFB",
//     500: "#00D5FA",
//     600: "#00A0BC",
//     700: "#006B7D",
//     800: "#00353F",
//     900: "#001519",
//   },
// };

// NOTE: There appears to be typescript strict typing for the keys in the palette dictionary and you cannot
//     change top level keys background and probably also primary and maybe also neutral.
//     It looks like palette.background.default takes effect behind the scenes during rendering of CssBaseline,
//     because it cannot be seen anywhere in the app code. So for now I cannot change that.
//     My goal is to use bg.bg for the default BG instead of background.default (way too long). And I am using
//     bg.lift.   So until I can change the key names in the theme system, I will have to work around this
//     strict typing.
//     FOR NOW, just keep these the same in the dark and the same in the light, liek this:
// DARK:
//   bg.bg = background.default
// LIGHT:
//   bg.bg = background.default
// Just make sure the values are the same until I can make "bg" work or fall back to using "background" for everything.

// Think of high shade numbers like 700, 800, 900 as Black, Dark, Dark-Colored Ink.
// Think of low shade numbers like 100, 200, 300 as White, Light or Light-Colored Ink.

// mui theme settings
export const themeSettings = (mode) => {
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
          // palette values for dark mode
          primary: {
            more_ink: colorTokens.primary[200],
            main: colorTokens.primary[500],
            less_ink: colorTokens.primary[800],
          },
          neutral: {
            dark: colorTokens.grey[100],  // *  MAX_INK (TEXT USER NAME)
            main: colorTokens.grey[200],  // *
            mediumMain: colorTokens.grey[300],  // ONLY used on MyPostWidget:
            medium: colorTokens.grey[400],  // *  HOVER for mediumMain on MyPostWidget
            least_ink: colorTokens.grey[700],  // ONLY used: Auth pulldown, BG for post text box MOST_INK ???
          },
          bg: {
            bg: colorTokens.grey[900],
            lift: colorTokens.grey[800], // Raised/framing backgrounds for layout. Lighter than default on both light/dark.
          },
          background: {
            default: colorTokens.grey[900],
            // lift: colorTokens.grey[800],
          },
        }
        : {
          // palette values for light mode
          primary: {
            more_ink: colorTokens.primary[1000],
            main: colorTokens.primary[600],
            less_ink: colorTokens.primary[300],
          },
          neutral: {
            dark: colorTokens.grey[700],  // *
            main: colorTokens.grey[500],  // *
            mediumMain: colorTokens.grey[400],
            medium: colorTokens.grey[300],  // *
            least_ink: colorTokens.grey[100], // NEED something slightly darker. Maybe shift EVERYTHING darker here.
          },
          bg: {
            bg: colorTokens.grey[100],
            lift: colorTokens.grey[10], // Raised/framing backgrounds for layout. Lighter than default on both light/dark.
          },
          background: {
            default: colorTokens.grey[100],
            // lift: colorTokens.grey[10],
          },
        }),
    },
    paletteOld: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // palette values for dark mode
            primary: {
              dark: colorTokens.primary[200],
              main: colorTokens.primary[500],
              light: colorTokens.primary[800],
            },
            neutral: {
              dark: colorTokens.grey[100],  // *
              main: colorTokens.grey[200],  // *
              mediumMain: colorTokens.grey[300],
              medium: colorTokens.grey[400],  // *
              light: colorTokens.grey[700],
            },
            background: {
              default: colorTokens.grey[900],
              alt: colorTokens.grey[800],
            },
          }
        : {
            // palette values for light mode
            primary: {
              dark: colorTokens.primary[1000],
              main: colorTokens.primary[500],
              light: colorTokens.primary[300],
            },
            neutral: {
              dark: colorTokens.grey[700],  // *
              main: colorTokens.grey[500],  // *
              mediumMain: colorTokens.grey[400],
              medium: colorTokens.grey[300],  // *
              light: colorTokens.grey[50],
            },
            background: {
              default: colorTokens.grey[100],
              alt: colorTokens.grey[10],
            },
          }),
    },
    typography: {
      fontFamily: ["Lato", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Lato", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Lato", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Lato", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Lato", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Lato", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Lato", "sans-serif"].join(","),
        fontSize: 14,
      },
      mainlogo: {
        fontFamily: ["Genos", "sans-serif"].join(","),
        fontSize: 44,
      },
      personal: {
        fontFamily: ["Caveat", "serif"].join(","),
        fontSize: 26,
      },
      // personal: {
      //   fontFamily: ["Patrick Hand", "cursive"].join(","),
      //   fontSize: 24,
      // },
      // personal: {
      //   fontFamily: ["Oooh Baby", "cursive"].join(","),
      //   fontSize: 24,
      // },
    },
  };
};


// Thanks for providing the list of colors. Let's analyze this color set, which seems to follow a gradient from light to dark shades of blue, with some color adjustments for consistency. Here's my feedback and suggested improvements based on best practices:
//
//   ### Observations:
//   1. **Gradient Flow**: The colors are moving from lighter to darker shades of blue, which is good for creating depth. However, the jump between some of the colors is a bit abrupt.
// 2. **Contrast and Readability**: The darker shades (600 and 700) could create more contrast against lighter backgrounds or text. At the same time, the light shades (50-400) could be too subtle for certain text or UI elements if they lack enough contrast against the background.
// 3. **Saturation Consistency**: The saturation of some colors seems to shift, which can create visual imbalance.
//
// ### Suggested Adjustments:
//   To keep a more consistent flow, ensure readability, and maintain the color harmony, here are some adjustments:
//
// 1. **50 (#E6FBFF)**: This is a nice soft light blue. Keep it as is.
// 2. **100 (#CCF7FE)**: This is also a light blue, but it might be too close to 50. Consider darkening it slightly or changing the hue for a smoother progression.
// - Suggested: **#B3EFFF** (A bit more contrast from 50, and still in the soft blue family).
// 3. **200 (#99EEFD)**: This one is fine, but it's starting to move toward a bright, almost pastel-like tone. Keep the saturation consistent.
// - Suggested: Keep this as is, but ensure the transition from 100 to 200 isn't too sharp.
// 4. **300 (#66E6FC)**: This is a good medium light blue, but it might contrast too much with the previous shades. It can be made slightly darker or less saturated.
// - Suggested: **#66D7F9** (A bit more muted for consistency).
// 5. **400 (#33DDFB)**: A vibrant blue. This might still work, but it could be a little too bright compared to 300. Consider toning it down slightly for more balanced shades.
// - Suggested: **#33C8F5** (A slightly deeper tone for better gradation).
// 6. **500 (#00D5FA)**: This is the pure saturated blue, and it's strong. It's a great choice, but it might be a little too intense next to the more muted tones. This could be slightly desaturated to align better with the rest.
// - Suggested: **#00B8E6** (A little less saturated but still vibrant enough).
// 7. **600 (#00A0BC)**: This is a darker shade, but the contrast with the lighter shades above may feel harsh. It could be darkened slightly or shifted to a less intense blue.
// - Suggested: **#008C9E** (Slightly more muted for balance).
// 8. **700 (#006B7D)**: This is a deep shade, which is great for depth but might be too close to 600. Consider darkening it further for more distinct separation.
// - Suggested: **#00565A** (A deeper tone for better contrast and visual separation).
//
// ### Summary of Adjustments:
//
//   - **50:** Keep as is.
// - **100:** Adjust to **#B3EFFF** (a little darker for smoother progression).
// - **200:** Keep as is, with attention to the flow from 100 to 200.
// - **300:** Adjust to **#66D7F9** (slightly muted).
// - **400:** Adjust to **#33C8F5** (a little deeper).
// - **500:** Adjust to **#00B8E6** (slightly desaturated).
// - **600:** Adjust to **#008C9E** (more muted).
// - **700:** Adjust to **#00565A** (darker for better contrast).
//
// These adjustments will make the set flow more evenly and ensure better visual consistency, with each color having a distinct but harmonious place in the gradient.
//


