import React from "react";
// import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ScrollToTopOnLoad as LoadFresh } from "../../utils";
// import * as Config from "../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  Box, Typography,
  useMediaQuery, useTheme,
} from "@mui/material";
import { Helmet } from 'react-helmet';
import NavbarPublic from "../navbarPublic";
import Footer from "../footer";
import WidgetWrapper from "../../components/WidgetWrapper";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import HostWidget from "../widgets/HostWidget";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import ProjectSolarWidget from "../widgets/projects/ProjectSolarWidget";
import ProjectBedrockWidget from "../widgets/projects/ProjectBedrockWidget";
import ProjectBotFolkWidget from "../widgets/projects/ProjectBotFolkWidget";
import ProjectAnsibleWidget from "../widgets/projects/ProjectAnsibleWidget";
// import ProjectNucleusWidget from "../widgets/projects/ProjectNucleusWidget";
import UnderConstruction from "../../components/UnderConstruction";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import RTX5090AdvertWidget from "../widgets/ads/RTX5090AdvertWidget";
import H100AdvertWidget from "../widgets/ads/H100AdvertWidget";
import UsersTrendingWidgetFake from "../widgets/UsersTrendingWidgetFake";
import ProjectStrip from "../widgets/ProjectStrip";
import GalleryStrip from "../widgets/GalleryStrip";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";


const HomePage = () => {
  const theme = useTheme();
  const pal = theme.palette;
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const isNarrowScreens = useMediaQuery("(min-width:1280px)");
  // const { _id, picturePath } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const handleLinkClick = (path) => {
    navigate(path);
    // if (!isNonMobileScreens) {
    //   setIsMobileMenuToggled(false); // Close the mobile menu after clicking a link
    // }
  };


  return (
    <div>
      <Helmet>
        <title>SmartMetal.ai - Resources for AI Developers & Creators. Generative AI Images & Video</title>
        <meta name="description" content="Resources for AI Developers & Creators. Generative AI Images & Video" />
      </Helmet>
      <Box>
        <NavbarPublic />
        <Box
          width="100%"
          padding="2rem 4%"
          display={isNonMobileScreens ? "flex" : "block"}
          gap="1.5rem"
          justifyContent="space-between"
        >
          <Box flexBasis={isNonMobileScreens ? (!isNarrowScreens ? "70%" : "66%") : undefined}>
            {/*<HostWidget userId={_id} picturePath={picturePath}/>*/}
            <HostWidget smallMode={true} />
            <Box m="2rem 0" />

            {/*TODO: MOVE THIS TO A WIDGET*/}
            {/*-------- CAREER HIGHLIGHTS --------*/}
            <WidgetWrapper>
              <Typography
                variant="h4"
                color={pal.warning.main}
                fontWeight="600"
                fontStyle={"italic"}
                marginBottom="0.75rem"
              >
                Career Highlights
              </Typography>
              <List sx={{ pt: 0, pb: 0, listStyleType: 'disc', color: pal.neutral.main, fontSize: '1.0rem' }}>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >
                  Apple Inc: Lead Developer, Direct Marketing Email Team "Chatterbox" system, promoted
                  to Operations Lead of the largest transactional/DM email system in the World in 2007.
                </ListItem>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >
                  Apple Inc: Designer and Developer of Apple's first Automation System for cellular radio
                  testing in Faraday chambers, including beautiful data visualizations in 2016.
                </ListItem>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >
                  Yahoo! Inc: Lead Developer and Operations Lead for the beacon system which measures
                  page-load performance of all of Yahoo's sites in 2012. Our team, "Exceptional Performance"
                  also created the first web browser Developer Tools.
                </ListItem>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >
                  Apple Inc: Designer and Developer of the custom CI/CD and DevOps system for the
                  core platform team of Apple's Applied Machine Learning (AML), Big Data "Athena"
                  system in 2015.
                </ListItem>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >
                  SunPower Inc: Achieved 2 patents in 2019 and 2020 as Lead Developer and Manager
                  of a 100% remote team of top-tier developers from Ukraine, Poland and the US.
                </ListItem>
              </List>
            </WidgetWrapper>
            <Box m="2rem 0" />


            <ProjectSolarWidget smallMode={true} />
            <Box m="2rem 0" />
            <ProjectAnsibleWidget smallMode={true} />
            <Box m="2rem 0" />
            <ProjectBedrockWidget smallMode={true} />
            <Box m="2rem 0" />
            <ProjectBotFolkWidget smallMode={true} />
            <Box m="2rem 0" />
            {/*<ProjectNucleusWidget />*/}
            {/*<Box m="2rem 0" />*/}

            {/*-------- UNDER CONSTRUCTION --------*/}
            <WidgetWrapper>
              <Typography
                variant="h5"
                color={pal.neutral.dark}
                fontWeight="500"
                // fontStyle={"italic"}
                marginBottom="0.75rem"
              >
                * Many more project and experience highlights are coming to this site in 2025. Like the handful of
                projects featured above, I plan to showcase about 20-30 of my professional/paid, Open-Source and
                personal projects, going back over 20 years. I'll even show some of the fun programs I wrote
                and cool electronic projects I built in my childhood.
              </Typography>
              <UnderConstruction />
            </WidgetWrapper>
            <Box m="2rem 0" />

          </Box>
          <Box
            flexBasis={isNonMobileScreens ? (!isNarrowScreens ? "30%" : "20%") : undefined}
            mt={isNonMobileScreens ? undefined : "2rem"}
          >
            {/*<MyPostWidget picturePath={picturePath}/>*/}
            {/*<PostsWidget userId={_id}/>*/}
            <ProjectStrip />
            <Box m="2rem 0" />
            <Box
              onClick={() => handleLinkClick("/feature/nvidia-h100")}
              sx={{
                mt: "0.4rem",
                "&:hover": {
                  cursor: "pointer",
                  // color: primaryLight,
                },
              }}
            >
              <H100AdvertWidget />
            </Box>
            <Box m="2rem 0" />

            <Box
              onClick={() => handleLinkClick("/feature/nvidia-rtx-5090")}
              sx={{
                mt: "0.4rem",
                "&:hover": {
                  cursor: "pointer",
                  // color: primaryLight,
                },
              }}
            >
              <RTX5090AdvertWidget />
            </Box>
            <Box m="2rem 0" />

            <UsersTrendingWidgetFake />
          </Box>


          {isNarrowScreens && (
            <Box flexBasis="14%">
              <GalleryStrip />
              {/*<Box m="2rem 0" />*/}
              {/*<UsersTrendingWidgetFake />*/}
            </Box>
          )}
        </Box>
        <Box
          display="block"
          width="100%"
          gap="1.5rem"
          padding="0.0rem"
        >
          <Footer />
        </Box>
      </Box>
      <LoadFresh />
    </div>
  );
};

export default HomePage;

