// import * as Config from "../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  Box,
  Typography,
  useTheme,
} from "@mui/material";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import FlexBetween from "./FlexBetween";
import UserImage from "./UserImage";

// TODO: Fix how pallete var is used and names etc.

const UserTrending = ({ userId, name, subtitle, userPicturePath }) => {
  const { palette } = useTheme();
  // const primaryLight = palette.primary.less_ink;
  // const primaryDark = palette.primary.more_ink;
  const main = palette.neutral.main;
  const medium = palette.neutral.medium;

  return (
    <FlexBetween>
      <FlexBetween gap="1rem">
        <UserImage image={userPicturePath} size="55px" />
        <Box
          // onClick={() => {
          //   navigate(`/profile/${userId}`);
          //   navigate(0);
          // }}
        >
          <Typography
            color={main}
            variant="h5"
            fontWeight="500"
            // sx={{
            //   "&:hover": {
            //     color: palette.primary.less_ink,
            //     cursor: "pointer",
            //   },
            // }}
          >
            {name}
          </Typography>
          <Typography color={medium} fontSize="0.75rem">
            {subtitle}
          </Typography>
        </Box>
      </FlexBetween>
      {/*IconButton was here*/}
    </FlexBetween>
  );
};

export default UserTrending;

