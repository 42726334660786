import React from "react";
import { ScrollToTopOnLoad as LoadFresh } from "../../utils";
// import * as Config from "../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Helmet } from "react-helmet";
import NavbarPublic from "../navbarPublic";
import Footer from "../footer";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import Form from "./Form";
import UsersTrendingWidgetFake from "../widgets/UsersTrendingWidgetFake";


const LobbyPage = () => {
  const theme = useTheme();
  const pal = theme.palette;
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  const medium = theme.palette.neutral.medium;


  return (
    <div>
      <Helmet>
        <title>SmartMetal.ai Matrix - Lobby: Sign-in, Sign-up - Social Network for AI/ML Professionals</title>
        <meta name="description" content="AI Generated Video & Images - Resources for Machine Learning Developers" />
      </Helmet>
      <Box>
        <NavbarPublic/>
        <Box
          width={isNonMobileScreens ? "50%" : "93%"}
          p="1.8rem"
          m="2rem auto"
          borderRadius="0.5rem"
          backgroundColor={theme.palette.bg.lift}
        >
          <Typography
            variant="mainlogo"
            fontWeight="bold"
            fontSize="32px"
            color={pal.warning.main}
            sx={{
              "&:hover": {
                cursor: "default",
              },
            }}
          >
            SmartMetal&nbsp;&nbsp;<span style={{fontStyle: "italic" }}>Matrix</span>
          </Typography>
          <Typography
            fontWeight="500"
            fontSize="0.9rem"
            fontStyle="italic"
            display="inline"
            sx={{ mb: "1.0rem" }}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A Social Network for AI/ML Professionals
          </Typography>
          <Typography
            color={pal.neutral.main}
            fontWeight="500"
            variant="h6"
            sx={{ mb: "1.7rem" }}
          >
            Resources for artificial intelligence & machine learning developers, researchers, artists, filmmakers and more!
          </Typography>
          <Form />
          <UsersTrendingWidgetFake />
        </Box>
        <Box
          display="block"
          width="100%"
          gap="1.5rem"
          padding="0.0rem"
        >
          <Footer />
        </Box>
      </Box>
      <LoadFresh />
    </div>
  );
};

export default LobbyPage;

