
import React from "react";
// import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { ScrollToTopOnLoad as LoadFresh } from "../../utils";
// import * as Config from "../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  Box,
  useMediaQuery,
} from "@mui/material";
import { Helmet } from 'react-helmet';
import NavbarPublic from "../navbarPublic";
import Footer from "../footer";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import UnderConstructionWidget from "../widgets/projects/UnderConstructionWidget";
import ForgeWidget from "../widgets/ForgeWidget";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */


const ForgePage = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");


  return (
    <div>
      <Helmet>
        <title>SmartMetal Forge - AI Video Generation, AI Image Generation, LLM Chat - SmartMetal.ai</title>
        <meta name="description" content="SmartMetal Forge - AI Video Generation, AI Image Generation, LLM Chat" />
      </Helmet>
      <Box>
        <NavbarPublic/>
        <Box
          width="100%"
          // width={isNonMobileScreens ? "50%" : "93%"}
          padding="2rem 4% 2rem"
          display={isNonMobileScreens ? "flex" : "block"}
          gap="1.5rem"
          justifyContent="center"
        >
          <ForgeWidget />
        </Box>


        <Box
          display="block"
          width="100%"
          gap="1.5rem"
          padding="0.0rem"
        >
          <Footer />
        </Box>
      </Box>
      <LoadFresh />
    </div>
  );
};

export default ForgePage;

