import { useEffect } from "react";
import { useLocation } from "react-router-dom";
// import * as Config from "./config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */


// WHen navigating between routes/pages, if the starting page is scrolled down, the destination page might also be
// scrolled, even if it is the first visit. All pages will force scroll to the top when the route has just changed.
// To make things clear, I like to use this import style in the client code:
// import { ScrollToTopOnLoad as LoadFresh } from "../../utils";

// Then in the outermost <div></div> of the page's return(), just before the closing </div>, put: <LoadFresh />


export const ScrollToTopOnLoad = () => {
  const routePath = useLocation();
  const scrollToOrigin = () => {
    window.scrollTo(0, 0);
  }
  useEffect(() => {
    scrollToOrigin()
  }, [routePath]);
  return null;
}

