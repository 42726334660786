
import React from "react";  // For useState for POPUP for setOpen and setImageUrl
// import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Config from "../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  Box,
  Typography,
  useTheme,
  Dialog,
  DialogContent,
  IconButton, useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import WidgetWrapper from "../../components/WidgetWrapper";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */


const ProjectStrip = () => {
  const { palette } = useTheme();
  // FOR POPUP - BUT I DON'T SEE WHY I NEED THESE - I REALIZE THERE IS A REASON. RESEARCH THIS.
  const [open, setOpen] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState('');

  const navigate = useNavigate();
  // const user = useSelector((state) => state.user);
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

// FOR POPUP
  const ImagePopup = ({
      imageUrl,
      open,
      onClose,
    }) => {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.9)', // Dark background behind image
            boxShadow: 'none',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '2%',
          },
        }}
      >
        <DialogContent
          style={{
            // position: 'relative',  // This for sure makes it INSIDE the image.
            // padding: '4%',
            height: '100vh', // Ensure the popup takes up the entire viewport
            overflow: 'hidden', // Hide any overflow from image or background
          }}
        >
          <img
            onClick={onClose}
            src={imageUrl}
            alt="Generative AI"
            style={{
              cursor: "pointer",
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain', // Prevents scrollbars and resizes image to fit
              borderRadius: '0.75rem',
              border: '1px solid #808080',
              boxShadow: '0px 8px 12px rgba(0, 0, 0, 0.8)',
              // margin: '4%',  // Cuts off some of the image and hides some rounded corners (right side)
            }}
          />
          <IconButton
            onClick={onClose}
            style={{
              margin: '.5rem',
              position: 'absolute',
              // top: '4%',
              // left: 'calc(100% - 1.0rem)', // Position the button to the right of the image  // ???? Not
              color: 'white',
              border: '1px solid #808080',
              zIndex: 10,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogContent>
      </Dialog>
    );
  };

  // FOR POPUP
  const handleOpen = (url) => {
    setImageUrl(url);
    setOpen(true);
  };

  // FOR POPUP
  const handleClose = () => {
    setOpen(false);
  };


  const galleryPieces = [
    {
      thumb: 'thm/solar-design-1920x1399-thumb.png',
      title: 'Machine Learning Solar Design',
      author: '2 Patents, Dev Team Manager',
      link: '/project/solar',
    },
    {
      thumb: 'thm/FLUX-bedrock-great03-crop2-thumb.png',
      title: 'Bedrock - Python FastAPI Stack',
      author: 'Python, FastAPI, NGINX, Docker',
      link: '/project/bedrock',
    },
    {
      thumb: 'thm/ansiblemyec2-340x246-thumb.png',
      title: 'AnsibleMyEC2 Open Source',
      author: 'Cloud Migration, Automation, Python',
      link: '/project/ansible',
    },
    // {
    //   thumb: 'thm/SMLogo003-thumb.png',
    //   title: 'SmartMetal.ai - (this site!)',
    //   author: 'MERN Stack',
    //   link: '/project/smartmetal',
    // },
    {
      thumb: 'thm/botfolk-1920x1508-thumb.png',
      title: 'BotFolk.ai - Next.js Full Stack',
      author: 'Next.js, Tailwind, NGINX, Docker',
      link: '/project/botfolk',
    },
    // {
    //   thumb: 'thm/autodash-stream-chart-1920x1388-thumb.png',
    //   title: 'AutoDash.app - Nucleus Stack',
    //   author: 'React, GraphQL, Apollo, MongoDB',
    //   link: '/project/nucleus',
    // },
    // {
    //   thumb: 'thm/FLUX-snake-01-crop-800x800-thumb.png',
    //   title: 'Bedrock - Python Stack',
    //   author: 'Python, FastAPI, Postgres, NGINX',
    //   link: '/project/bedrock',
    // },
  ]


  return (
    <WidgetWrapper>
      <Typography
        variant="h4"
        // fontWeight="bold"
        // fontSize="1.1rem"
        color={palette.neutral.dark}
        // variant="h5"
        // fontWeight="500"
        sx={{ mb: "1.5rem" }}
      >
        Projects
      </Typography>
      <Box display="flex" flexDirection="column">

        <ImageList
          cols={1}
          gap="2.0rem"
          sx={{
            margin: "0.0rem",
            width: "100%",
            height: "100%",
          }}
        >
          {galleryPieces.map((item) => (
            <ImageListItem
              key={item.img}
              sx={{
                "&:hover": {
                  // color: primaryLight,
                  cursor: "pointer",
                },
              }}
            >
              <img
                style={{
                  cursor: "pointer",
                  objectFit: "cover",
                  borderRadius: "0.5rem",
                  border: '1px solid #808080',
                }}
                // srcSet={`${Config.expressUri}/assets/projects/${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                // src={`${Config.expressUri}/assets/projects/${item.img}?w=248&fit=crop&auto=format`}
                srcSet={`${Config.expressUri}/assets/projects/${item.thumb}?w=248&fit=crop&auto=format&dpr=2 2x`}
                src={`${Config.expressUri}/assets/projects/${item.thumb}?w=248&fit=crop&auto=format`}
                // src={`${item.img}?w=248&fit=crop&auto=format`}
                alt={item.title}
                loading="lazy"
                // onClick={() => handleOpen(`${Config.expressUri}/assets/projects/${item.img}`)}
                onClick={() => navigate(item.link)}
              />
              <ImageListItemBar
                title={item.title}
                subtitle={<span>{item.author}</span>}
                // subtitle={<span>by: {item.author}</span>}
                position="below"
                // fontSize={'2.7rem'}  // NO EFFECT
                sx={{
                  // fontSize: '2.7rem', // NO EFFECT
                  background: "rgba(0, 0, 0, 0.5)",
                  color: "white",
                  padding: "0.0rem 0.6rem 0.0rem 0.6rem",
                  borderRadius: "0.5rem",
                  border: '1px solid #808080',
                  "border-top": "none",
                  "& .MuiImageListItemBar-titleWrap.MuiImageListItemBar-titleWrapBelow": {
                    // padding: "0.0rem 0.2rem 0.0rem 0.6rem",
                    paddingBottom: "7px",
                  }, //styles for bottom of Bar
                  "& .MuiImageListItemBar-title": {
                    fontSize: '0.75rem',
                    lineHeight: '1.0rem',
                    textWrap: "wrap",
                  }, //styles for title
                  "& .MuiImageListItemBar-subtitle": {
                    fontSize: '0.65rem',
                    lineHeight: '0.8rem',
                    textWrap: "wrap",
                  }, //styles for subtitle
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>

        {/* FOR POPUP */}
        <ImagePopup imageUrl={imageUrl} open={open} onClose={handleClose} />

      </Box>
    </WidgetWrapper>
  );
};

export default ProjectStrip;

