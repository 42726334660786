
import * as Config  from "../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import { Box } from "@mui/material";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */


const HostImage = ({
    image,
    borderColor = "#808080",
    size = "140px",
  }) => {
  return (
    <Box width={size}>
      <img
        style={{
          objectFit: "cover",
          borderRadius: "50%",
          border: '2px solid',
          borderColor: borderColor,
        }}
        width={size}
        alt="user"
        src={`${Config.expressUri}/assets/users/${image}`}
      />
    </Box>
  );
};

export default HostImage;

