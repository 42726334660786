
// import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import * as Config  from "../../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  Box,
  Typography,
  useTheme,
  Divider,
  Chip, useMediaQuery, IconButton,
} from "@mui/material";

/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import FlexBetween from "../../../components/FlexBetween";
import WidgetWrapper from "../../../components/WidgetWrapper";
import ProjectImage from "../../../components/ProjectImage";
import {useNavigate} from "react-router-dom";
import {ZoomIn} from "@mui/icons-material";


// const HostWidget = ({ userId, picturePath }) => {
const ProjectBedrockWidget = ({
    smallMode = false,
  }) => {
  const theme = useTheme();
  const pal = theme.palette;
  // const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  const isNonTinyScreens = useMediaQuery("(min-width: 800px)");
  const navigate = useNavigate();

  const imageFileProject = "thm/SMLogo003-thumb.png";
  const projectUrl = "/project/smartmetal";
  const imageAlt = "SmartMetal.ai - Prototype for The NEW Nucleus Stack, Social Network App (screenshot)";


  return (
    <WidgetWrapper>
      {/* -------- FIRST ROW -------- */}
      <FlexBetween
        gap="1.5rem"
        pb="1.1rem"
      >
        <FlexBetween gap="1.5rem">
          <ProjectImage
            smallMode={smallMode}
            imageFileProject={imageFileProject}
            imageAlt={imageAlt}
            imageLinkUrl={projectUrl}
          />
          <Box>
            {/*<Typography*/}
            {/*  variant="h4"*/}
            {/*  color={pal.neutral.dark}*/}
            {/*  fontWeight="600"*/}
            {/*  marginBottom="0.75rem"*/}
            {/*  // sx={{*/}
            {/*  //   "&:hover": {*/}
            {/*  //     color: palette.primary.less_ink,*/}
            {/*  //     cursor: "pointer",*/}
            {/*  //   },*/}
            {/*  // }}*/}
            {/*>*/}
            {/*  SmartMetal.ai - Prototype for The NEW Nucleus Stack, Social Network App*/}
            {/*</Typography>*/}
            <FlexBetween gap="0.5rem">
              <Typography
                variant="h4"
                color={pal.neutral.dark}
                fontWeight="600"
                marginBottom="0.75rem"
              >
                SmartMetal.ai - Prototype for The NEW Nucleus Stack, Social Network App
              </Typography>
              {/* -------- ZOOM NAVIGATE - ONLY IN SMALL MODE -------- */}
              { smallMode && (
                <IconButton
                  onClick={() => navigate(projectUrl)}
                >
                  <ZoomIn
                    sx={{
                      fontSize: "1.8rem",
                      color: pal.primary.main,
                    }}
                  />
                </IconButton>
              )}
            </FlexBetween>
            {/* -------- FIRST ROW - SUB-HEADING & BULLETS -------- */}
            <Typography
              variant="h5"
              color={pal.neutral.dark}
              fontWeight="500"
              marginBottom="0.75rem"
            >
              Open Source Project - Oct 2024 to Present
            </Typography>
            <FlexBetween gap="2rem">
              <Typography color={pal.neutral.medium} height="100%">
                Senior Full Stack Developer - 20 yrs<br />
                Lead Python Programmer - 20 yrs<br />
                Senior Linux & DevOps Engineer - 20 yrs<br />
                Open Source Developer - 10 yrs
              </Typography>
              <Typography color={pal.neutral.medium} height="100%">
                {/*Cloud Architect (AWS, GCP, Azure) - 12 yrs<br />*/}
                React Developer - 7 yrs<br />
                Frontend Developer & UI Designer - 20 yrs<br />
                Patent 1 (US11392730) Machine Learning Full Stack App<br />
                Patent 2 (US10754999) Machine Learning Full Stack App
              </Typography>
            </FlexBetween>
          </Box>
        </FlexBetween>
      </FlexBetween>

      <Divider />

      {/* SECOND ROW */}
      <Box p="1rem 0">
        <FlexBetween mb="0.5rem">
          <Typography variant="personal" color={pal.neutral.dark}>
            &nbsp;&nbsp;FastAPI is now by far the fastest Full Stack backend framework.
            &nbsp;&nbsp;Significant advancements have recently come to the Python ecosystem.</Typography>
        </FlexBetween>
        <FlexBetween>
          <Typography variant="personal" color={pal.neutral.dark} mb="0.5rem">
            I present my new State of the Art Python Full Stack: The Bedrock Stack.</Typography>
        </FlexBetween>
      </Box>

      <Divider />

      {/* THIRD ROW */}
      <Box p="1.0rem 0.0rem 0.0rem 0.0rem">
        <Typography fontSize="1rem" color={pal.neutral.main} fontWeight="500" mb="1rem">
          Technology Components of The Bedrock Stack
        </Typography>

        <Box display="flex" flexWrap="wrap" gap="1rem" mb="0.0rem"
             sx={{
               "& .MuiChip-label": { fontSize: ".85rem", fontWeight: "bold" }, // Slightly bigger chip label
             }}>
          <Chip label="Python Framework: FastAPI" color="primary"/>
          <Chip label="Python User Mgmt: FastAPI-Users" color="success" />
          <Chip label="Python ORM: SQLAlchemy" color="warning" />
          <Chip label="Docker Containers" />
          <Chip label="Docker Compose" />
          <Chip label="NGINX Accelerator" color="success" />
          <Chip label="PostgreSQL DB" color="info" />
          <Chip label="NGINX" color="warning" />
          <Chip label="Pydantic" color="error" />
          <Chip label="Build Automation: BASH" color="primary"/>
          <Chip label="Deploy Automation: BASH" color="primary"/>
          <Chip label="React" color="warning" />
          <Chip label="Forms: Formic" color="error" />
          <Chip label="Uploads: Multer" color="warning" />
          <Chip label="Components: Material UI" color="info" />
          <Chip label="DB Admin IDE: PgAdmin" />
          <Chip label="Playright" color="error" />
          <Chip label="Styling: Material UI" color="success" />
        </Box>

      </Box>
    </WidgetWrapper>
  );
};

export default ProjectBedrockWidget;


// ************************************************************************************
// https://github.com/fastapi/full-stack-fastapi-template

