
import React, {
  useEffect,
  useState,
} from "react";
import * as Config  from "../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  InputBase,
  Divider,
  Button,
} from "@mui/material";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import FlexBetween from "../../components/FlexBetween";
import WidgetWrapper from "../../components/WidgetWrapper";
// import ForgeIcon from "../../components/ForgeIcon";
import ProgressCircleGradient from "../../components/ProgressCircleGradient";
// import PsychedelicImage from "../../components/PsychedelicImage";  // TODO: Could not get this to work. TRY AGAIN!
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */


const DreamWidget = () => {
  const picturePath = "stable-diff-icon-400x400.jpg";
  const defaultImage = "forge-flow-512x512.png";
  const theme = useTheme();
  const pal = theme.palette;

  const [post, setPost] = useState("");
  const [state, setState] = useState('default'); // 'default', 'waiting', 'completed', 'error'
  const [result, setResult] = useState('');
  const [seed, setSeed] = useState('');
  const [executionTime, setExecutionTime] = useState('');
  const [delayTime, setDelayTime] = useState('');
  const [workerId, setWorkerId] = useState('');
  const [randImage, setRandImage] = useState('');

  const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  };

  const defaultImages = [
    {
      img: 'dream-city-512x512.png',
      prompt: 'blah blah blah',
    },
    {
      img: 'dream-face-tv-512x512.png',
      prompt: 'blah blah blah',
    },
    {
      img: 'dream-girl-512x512.png',
      prompt: 'blah blah blah',
    },
    {
      img: 'dream-people-512x512.png',
      prompt: 'blah blah blah',
    },
    {
      img: 'dream-picnic-512x512.png',
      prompt: 'blah blah blah',
    },
    {
      img: 'dream-couple-512x512.png',
      prompt: 'blah blah blah',
    },
    {
      img: 'dream-bird-512x512.png',
      prompt: 'blah blah blah',
    },
    {
      img: 'dream-view-512x512.png',
      prompt: 'blah blah blah',
    },
    {
      img: 'dream-wright-512x512.png',
      prompt: 'blah blah blah',
    },
  ]

  useEffect(() => {
    const randIndex = Math.floor(Math.random() * defaultImages.length);
    setRandImage(defaultImages[randIndex].img);

    // console.log(randIndex);
    // console.log(randImage);
  }, []);


  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");


  const handleDream = async (dreamParams) => {
  // const handleDream = async (dreamMode) => {
    // const { dreamType, action, data } = dreamParams;  // Example of multiple params
    const { mode } = dreamParams;  // Params, just single in this case.
    const newFormData = {
      mode: mode,
    }
    // const newFormData = {
    //   mode: dreamMode,
    // }
    console.log(newFormData);
    setState('waiting');

    try {
      const response = await fetch(`${Config.fastApiUri}/start-dream`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newFormData),
      });

      // setState('waiting');

      const response_json = await response.json();
      console.log("RESPONSE from /start-dream ---------------------------:");
      console.log(response_json);
      console.log("-----------------------------------:");

      const jobId = response_json.id;
      // TODO: IMPORTANT: Check the existence and the validity of the id here.
      // TODO: IMPORTANT: Check the existence and the validity of the id here.
      await pollJobStatus(jobId);
    } catch (error) {
      setState('error');
      console.error('Error submitting job:', error);
    }
  };

  const pollJobStatus = async (jobId) => {
    try {
      setState('waiting');
      let jobComplete = false;
      while (!jobComplete) {

        const response = await fetch(`${Config.fastApiUri}/job-status/${jobId}`, {
          method: "GET",
        });

        const response_json = await response.json();
        console.log("RESPONSE from /job-status ---------------------------:");
        console.log(response_json);
        console.log("-----------------------------------:");

        if (response_json.status === 'COMPLETED') {
          setState('completed');
          const imageBase64 = response_json.output[0].image;
          const seedInt = response_json.output[0].seed;
          const executionTimeInt = response_json.executionTime;
          const delayTimeInt = response_json.delayTime;
          const workerIdInt = response_json.workerId;
          setResult(imageBase64);
          setSeed(seedInt);
          setExecutionTime(executionTimeInt);
          setDelayTime(delayTimeInt);
          setWorkerId(workerIdInt);
          jobComplete = true;
        } else if (response_json.status === 'ERROR') {
          setState('error');
          console.error('Job encountered an error:', response_json.error);
          jobComplete = true;
        } else {
          await sleep(3000);
        }
      }
    } catch (error) {
      setState('error');
      console.error('Error polling job status:', error);
    }
  };


  return (
    <WidgetWrapper>
      <Typography
        variant="mainlogo"
        fontWeight="bold"
        fontSize="32px"
        color={pal.warning.main}
        sx={{
          "&:hover": {
            cursor: "default",
          },
        }}
      >
        SmartMetal&nbsp;&nbsp;<span style={{ fontStyle: "italic" }}>Dream-o-matic</span>
      </Typography>
      <Typography
        fontWeight="500"
        fontSize="0.9rem"
        fontStyle="italic"
        display="inline"
        sx={{ mb: "1.0rem" }}
      >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A &nbsp;Gen AI &nbsp;Thing
      </Typography>
      {/*<Typography*/}
      {/*  color={pal.neutral.main}*/}
      {/*  fontWeight="500"*/}
      {/*  variant="h6"*/}
      {/*  sx={{ mt: "0.2rem", mb: "0.1rem" }}*/}
      {/*>*/}
      {/*  Model: &nbsp;&nbsp;&nbsp;Stable Diffusion 1.5 (2023) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;GPUs: &nbsp;&nbsp;&nbsp;RTX A5000, RTX 3090*/}
      {/*</Typography>*/}
      {/*<Typography*/}
      {/*  color={pal.neutral.main}*/}
      {/*  fontWeight="500"*/}
      {/*  variant="h6"*/}
      {/*  sx={{ mb: "1.0rem" }}*/}
      {/*>*/}
      {/*  Platform: &nbsp;&nbsp;&nbsp;RunPod Serverless &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Backend: &nbsp;&nbsp;&nbsp;FastAPI, Python*/}
      {/*</Typography>*/}

      {/*<FlexBetween gap="1.5rem" marginTop={"0.5rem"}>*/}
      {/*  /!*<ForgeIcon image={picturePath} />*!/*/}
      {/*  <InputBase*/}
      {/*    placeholder="Type your AI image generation prompt here ..."*/}
      {/*    onChange={(e) => setPost(e.target.value)}*/}
      {/*    value={post}*/}
      {/*    sx={{*/}
      {/*      width: "100%",*/}
      {/*      backgroundColor: pal.neutral.least_ink,*/}
      {/*      borderRadius: "2rem",*/}
      {/*      padding: "1rem 2rem",*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</FlexBetween>*/}

      <Divider sx={{ margin: "1.25rem 0" }} color={pal.primary.main} />

      <FlexBetween>
        <Button
          fullWidth
          onClick={() => handleDream({mode: "daydream"})}
          // onClick={handleDream("daydream")}
          sx={{
              fontWeight: "bold",
              mr: "0.5rem",
              backgroundColor: pal.primary.main,
              color: pal.bg.lift,
              "&:hover": { color: pal.primary.main },
          }}
        >
          DAY DREAM
        </Button>
        <Button
          fullWidth
          onClick={() => handleDream({mode: "nicedream"})}
          // onClick={handleDream("nicedream")}
          sx={{
            fontWeight: "bold",
            ml: "0.5rem",
            mr: "0.5rem",
            backgroundColor: pal.primary.main,
            color: pal.bg.lift,
            "&:hover": { color: pal.primary.main },
          }}
        >
          NICE DREAM
        </Button>
        <Button
          fullWidth
          onClick={() => handleDream({mode: "strangedream"})}
          // onClick={handleDream("strangedream")}
          sx={{
            fontWeight: "bold",
            ml: "0.5rem",
            mr: "0.5rem",
            backgroundColor: pal.primary.main,
            color: pal.bg.lift,
            "&:hover": { color: pal.primary.main },
          }}
        >
          STRANGE DREAM
        </Button>
        <Button
          fullWidth
          onClick={() => handleDream({mode: "nightmare"})}
          // onClick={handleDream("nightmare")}
          sx={{
            fontWeight: "bold",
            ml: "0.5rem",
            backgroundColor: pal.primary.main,
            color: pal.bg.lift,
            "&:hover": { color: pal.primary.main },
          }}
        >
          NIGHTMARE
        </Button>
      </FlexBetween>

      <Divider sx={{ margin: "1.25rem 0" }} color={pal.primary.main} />

      {state === 'default' && (
        <Typography
          fontWeight="500"
          fontSize="0.75rem"
          fontStyle={"italic"}
          sx={{
            color: pal.neutral.mediumMain,
            mt: "0.07rem",
            mb: "1.0rem",
            mr: "0.7rem",
          }}
        >
          [ example image - SD 1.5 ] &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Click a dream button above to generate a unique new dream image!
        </Typography>
      )}

      {state === 'default' && (
        <img
          src={`${Config.expressUri}/assets/img/dream/${randImage}`} // Assuming the image is in PNG format
          alt="Default AI image"
          style={{
            width: '768px',
            // maxWidth: '100%',
            // maxHeight: '512px',
            borderRadius: "4px",
          }}
        />
      )}

      {state === 'waiting' && (
        <Box width="768px">
          <FlexBetween>
            <ProgressCircleGradient />
            <ProgressCircleGradient />
            <ProgressCircleGradient />
            <ProgressCircleGradient />
            <ProgressCircleGradient />
          </FlexBetween>
          <br /><br /><br /><br /><br /><br />
          <FlexBetween>
            <ProgressCircleGradient />
            <ProgressCircleGradient />
            <ProgressCircleGradient />
            <ProgressCircleGradient />
            <ProgressCircleGradient />
          </FlexBetween>
          <br /><br /><br /><br /><br /><br />
          <FlexBetween>
            <ProgressCircleGradient />
            <ProgressCircleGradient />
            <ProgressCircleGradient />
            <ProgressCircleGradient />
            <ProgressCircleGradient />
          </FlexBetween>
          <br /><br /><br /><br /><br /><br />
          <FlexBetween>
            <ProgressCircleGradient />
            <ProgressCircleGradient />
            <ProgressCircleGradient />
            <ProgressCircleGradient />
            <ProgressCircleGradient />
          </FlexBetween>
          <br /><br /><br /><br /><br /><br />
          <FlexBetween>
            <ProgressCircleGradient />
            <ProgressCircleGradient />
            <ProgressCircleGradient />
            <ProgressCircleGradient />
            <ProgressCircleGradient />
          </FlexBetween>
        </Box>
      )}

      {state === 'completed' && (
        <img
          src={`data:image/png;base64,${result}`} // Assuming the image is in PNG format
          alt="Generated result"
          style={{
            width: '768px',
            // maxWidth: '100%',
            // maxHeight: '512px',
            borderRadius: "4px",
          }}
        />
      )}

      {state === 'error' && ("Something went wrong. Please try again.")}

      <Divider sx={{ margin: "1.25rem 0" }} color={pal.primary.main} />

      {state === 'completed' && (
        <Typography
          fontWeight="500"
          fontSize="0.75rem"
          sx={{
            color: pal.neutral.dark,
            mt: "0.07rem",
            mb: "0.05rem",
            mr: "0.7rem",
          }}
        >
          Seed: &nbsp;&nbsp;&nbsp;{seed}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Execute: &nbsp;&nbsp;&nbsp;{executionTime} ms
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Delay: &nbsp;&nbsp;&nbsp;{delayTime} ms
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Worker ID: &nbsp;&nbsp;&nbsp;{workerId}
        </Typography>
      )}

      {(state === 'default' || state === 'waiting') && (
        <Typography
          fontWeight="500"
          fontSize="0.75rem"
          sx={{
            color: pal.neutral.mediumMain,
            mt: "0.07rem",
            mb: "0.05rem",
            mr: "0.7rem",
          }}
        >
          Seed: &nbsp;&nbsp;&nbsp;[____]
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Execute: &nbsp;&nbsp;&nbsp;[____] ms
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Delay: &nbsp;&nbsp;&nbsp;[____] ms
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Worker ID: &nbsp;&nbsp;&nbsp;[____]
        </Typography>
      )}


    {/*<PsychedelicImage imageUrl={`${Config.expressUri}/assets/img/forge-flow-512x512.png`}/>*/}

    </WidgetWrapper>
  );
};

export default DreamWidget;

