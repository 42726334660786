import {
  useEffect,
  // useState,
} from "react";
// import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
// import * as Config  from "../../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  Box,
  Typography,
  useTheme,
  Divider, useMediaQuery,
} from "@mui/material";
import {
  ManageAccountsOutlined,
  // EditOutlined,
  LocationOnOutlined,
  WorkOutlineOutlined,
} from "@mui/icons-material";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import FlexBetween from "../../../components/FlexBetween";
import WidgetWrapper from "../../../components/WidgetWrapper";
import ProjectImage from "../../../components/ProjectImage";
// import PackageInfoCard from "../../../components/PackageInfoCard";


// const HostWidget = ({ userId, picturePath }) => {
const DetailBedrockWidget = () => {
  const theme = useTheme();
  const pal = theme.palette;
  // const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  const isNonTinyScreens = useMediaQuery("(min-width: 800px)");
  const navigate = useNavigate();

  const dark = pal.neutral.dark;
  const medium = pal.neutral.medium;
  const main = pal.neutral.main;

  const picturePath = "thm/SMLogo003-thumb.png";
  const imageFileProject = "thm/SMLogo003-thumb.png";
  const projectUrl = "/project/smartmetal";
  const imageAlt = "SmartMetal.ai - Prototype for The NEW Nucleus Stack, Social Network App (screenshot)";

  // HACK (or perm) - Never used on home page. Make it an arg to this comp is it is needed from home page or elsewhere.
  const smallMode = false;


  const firstName = "*** THIS CONTENT IS UNDER CONSTRUCTION ***";
  const lastName = "";
  const location = "*** THIS CONTENT IS UNDER CONSTRUCTION ***";
  const occupation  = "*** THIS CONTENT IS UNDER CONSTRUCTION ***";
  const viewedProfile = "1,234";
  const impressions = "5,678";
  // const friends = ["1", "2", "3", "4", "5"];

  return (
    <WidgetWrapper>
      {/* FIRST ROW */}
      <FlexBetween
        gap="0.5rem"
        pb="1.1rem"
        // onClick={() => navigate(`/profile/${userId}`)}
        onClick={() => navigate(`/`)}
      >
        <FlexBetween gap="1rem">
          <ProjectImage
            smallMode={smallMode}
            imageFileProject={imageFileProject}
            imageAlt={imageAlt}
            imageLinkUrl={projectUrl}
          />
          <Box>
            <Typography
              variant="h4"
              color={dark}
              fontWeight="500"
              sx={{
                "&:hover": {
                  color: pal.primary.less_ink,
                  cursor: "pointer",
                },
              }}
            >
              {firstName} {lastName}
            </Typography>
            {/*<Typography color={medium}>{friends.length} friends</Typography>*/}
            <Typography color={medium}>{37} friends</Typography>
          </Box>
        </FlexBetween>
        <ManageAccountsOutlined />
      </FlexBetween>

      <Divider />

      {/* SECOND ROW */}
      <Box p="1rem 0">
        <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
          <LocationOnOutlined fontSize="large" sx={{ color: main }} />
          <Typography color={medium}>{location}</Typography>
        </Box>
        <Box display="flex" alignItems="center" gap="1rem">
          <WorkOutlineOutlined fontSize="large" sx={{ color: main }} />
          <Typography color={medium}>{occupation}</Typography>
        </Box>
      </Box>

      <Divider />

      {/* THIRD ROW */}
      <Box p="1.0rem 0.0rem 0.0rem 0.0rem">
        <FlexBetween mb="0.5rem">
          <Typography color={medium}>*** THIS CONTENT IS UNDER CONSTRUCTION ***</Typography>
          <Typography color={main} fontWeight="500">
            {viewedProfile}
          </Typography>
        </FlexBetween>
        <FlexBetween>
          <Typography color={medium}>*** THIS CONTENT IS UNDER CONSTRUCTION ***</Typography>
          <Typography color={main} fontWeight="500">
            {impressions}
          </Typography>
        </FlexBetween>
      </Box>

      <Divider />

      {/* FOURTH ROW */}
      <Box p="1rem 0">
        {/*<PackageInfoCard version={"1.0.1"} coverage={"93"} downloads={"12,241"} />*/}
      </Box>
    </WidgetWrapper>
  );
};

export default DetailBedrockWidget;

