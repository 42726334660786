
import React from "react";
// import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { ScrollToTopOnLoad as LoadFresh } from "../../../utils";
// import * as Config from "../../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  Box,
  useMediaQuery,
} from "@mui/material";
import { Helmet } from 'react-helmet';
import NavbarPublic from "../../navbarPublic";
import Footer from "../../footer";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import ProjectSmartMetalWidget from "../../widgets/projects/ProjectSmartMetalWidget";
import DetailSmartMetalWidget from "../../widgets/projects/DetailSmartMetalWidget";
import UnderConstructionWidget from "../../widgets/projects/UnderConstructionWidget";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */


const ProjectSmartMetalPage = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");


  return (
    <div>
      <Helmet>
        <title>SmartMetal.ai - SmartMetal Project (this site) - MERN Stack, Express, MongoDB,  NGINX, React</title>
        <meta name="description" content="SmartMetal Project (this site) - MERN Stack, Express, MongoDB,  NGINX, React" />
      </Helmet>
      <Box>
        <NavbarPublic/>
        <Box
          width="100%"
          padding="2rem 4% 0"
          display={isNonMobileScreens ? "flex" : "block"}
          gap="1.5rem"
          justifyContent="center"
        >
          <ProjectSmartMetalWidget />
        </Box>
        <Box
          width="100%"
          padding="2rem 4% 0rem"
          display={isNonMobileScreens ? "flex" : "block"}
          gap="1.5rem"
          justifyContent="center"
        >
          <DetailSmartMetalWidget />
        </Box>
        <Box
          width="100%"
          padding="2rem 4% 0rem"
          display={isNonMobileScreens ? "flex" : "block"}
          gap="1.5rem"
          justifyContent="center"
        >
          <UnderConstructionWidget />
        </Box>
        <Box
          width="100%"
          padding="2rem 4% 2rem"
          display={isNonMobileScreens ? "flex" : "block"}
          gap="1.5rem"
          justifyContent="center"
        >
          <a href="https://smartmetal.ai/" target="_blank" rel="noreferrer">
            {/*<a href="https://www.nvidia.com/en-us/geforce/graphics-cards/50-series/" target="_blank" rel="noreferrer">*/}
            smartmetal.ai (this site)
          </a>
        </Box>
        <Box
          display="block"
          width="100%"
          gap="1.5rem"
          padding="0.0rem"
        >
          <Footer />
        </Box>
      </Box>
      <LoadFresh />
    </div>
  );
};

export default ProjectSmartMetalPage;

