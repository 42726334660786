import {
  useEffect,
  // useState,
} from "react";
// import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Config  from "../../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  Box,
  Typography,
  useTheme,
  Divider,
} from "@mui/material";
import {
  ManageAccountsOutlined,
  EditOutlined,
  LocationOnOutlined,
  WorkOutlineOutlined,
} from "@mui/icons-material";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import FlexBetween from "../../../components/FlexBetween";
import WidgetWrapper from "../../../components/WidgetWrapper";
import ProjectImage from "../../../components/ProjectImage";


// const HostWidget = ({ userId, picturePath }) => {
const ProjectNucleusWidget = () => {
  // const [user, setUser] = useState(null);
  const { palette } = useTheme();
  const navigate = useNavigate();
  // const token = useSelector((state) => state.token);
  const dark = palette.neutral.dark;
  const medium = palette.neutral.medium;
  const main = palette.neutral.main;

  const picturePath = "thm/autodash-stream-chart-1920x1388-thumb.png";

  // const getUser = async () => {
  //   const response = await fetch(`${Config.expressUri}/users/${userId}`, {
  //     method: "GET",
  //     headers: { Authorization: `Bearer ${token}` },
  //   });
  //   const data = await response.json();
  //   setUser(data);
  // };

  useEffect(() => {
    // getUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // if (!user) {
  //   return null;
  // }

  // const {
  //   firstName,
  //   lastName,
  //   location,
  //   occupation,
  //   viewedProfile,
  //   impressions,
  //   friends,
  // } = user;


  const firstName = "*** THIS CONTENT IS UNDER CONSTRUCTION ***";
  const lastName = "";
  const location = "*** THIS CONTENT IS UNDER CONSTRUCTION ***";
  const occupation  = "*** THIS CONTENT IS UNDER CONSTRUCTION ***";
  const viewedProfile = "1,234";
  const impressions = "5,678";
  // const friends = ["1", "2", "3", "4", "5"];

  return (
    <WidgetWrapper>
      {/* FIRST ROW */}
      <FlexBetween
        gap="0.5rem"
        pb="1.1rem"
        // onClick={() => navigate(`/profile/${userId}`)}
        onClick={() => navigate(`/`)}
      >
        <FlexBetween gap="1rem">
          <ProjectImage image={picturePath} />
          <Box>
            <Typography
              variant="h4"
              color={dark}
              fontWeight="500"
              sx={{
                "&:hover": {
                  color: palette.primary.less_ink,
                  cursor: "pointer",
                },
              }}
            >
              {firstName} {lastName}
            </Typography>
            {/*<Typography color={medium}>{friends.length} friends</Typography>*/}
            <Typography color={medium}>{37} friends</Typography>
          </Box>
        </FlexBetween>
        <ManageAccountsOutlined />
      </FlexBetween>

      <Divider />

      {/* SECOND ROW */}
      <Box p="1rem 0">
        <Box display="flex" alignItems="center" gap="1rem" mb="0.5rem">
          <LocationOnOutlined fontSize="large" sx={{ color: main }} />
          <Typography color={medium}>{location}</Typography>
        </Box>
        <Box display="flex" alignItems="center" gap="1rem">
          <WorkOutlineOutlined fontSize="large" sx={{ color: main }} />
          <Typography color={medium}>{occupation}</Typography>
        </Box>
      </Box>

      <Divider />

      {/* THIRD ROW */}
      <Box p="1rem 0">
        <FlexBetween mb="0.5rem">
          <Typography color={medium}>*** THIS CONTENT IS UNDER CONSTRUCTION ***</Typography>
          <Typography color={main} fontWeight="500">
            {viewedProfile}
          </Typography>
        </FlexBetween>
        <FlexBetween>
          <Typography color={medium}>*** THIS CONTENT IS UNDER CONSTRUCTION ***</Typography>
          <Typography color={main} fontWeight="500">
            {impressions}
          </Typography>
        </FlexBetween>
      </Box>

      <Divider />

      {/* FOURTH ROW */}
      <Box p="1rem 0">
        <Typography fontSize="1rem" color={main} fontWeight="500" mb="1rem">
          Social Profiles
        </Typography>

        <FlexBetween gap="1rem" mb="0.5rem">
          <FlexBetween gap="1rem">
            <img src={`${Config.expressUri}/assets/icons/twitter.png`} alt="twitter" />
            <Box>
              <Typography color={main} fontWeight="500">
                Twitter
              </Typography>
              <Typography color={medium}>Social Network</Typography>
            </Box>
          </FlexBetween>
          <EditOutlined sx={{ color: main }} />
        </FlexBetween>

        <FlexBetween gap="1rem">
          <FlexBetween gap="1rem">
            <img src={`${Config.expressUri}/assets/icons/linkedin.png`} alt="linkedin" />
            <Box>
              <Typography color={main} fontWeight="500">
                Linkedin
              </Typography>
              <Typography color={medium}>Network Platform</Typography>
            </Box>
          </FlexBetween>
          <EditOutlined sx={{ color: main }} />
        </FlexBetween>
      </Box>
    </WidgetWrapper>
  );
};

export default ProjectNucleusWidget;

