
import * as Config  from "../../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  Typography,
  useTheme,
} from "@mui/material";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import FlexBetween from "../../../components/FlexBetween";
import WidgetWrapper from "../../../components/WidgetWrapper";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */


const RTX5090AdvertWidget = () => {
  const { palette } = useTheme();
  const dark = palette.neutral.dark;
  const main = palette.neutral.main;
  const medium = palette.neutral.medium;

  return (
    <WidgetWrapper>
      <FlexBetween>
        <Typography color={dark} variant="h7" fontWeight="400" fontSize={'0.7rem'}>
          Sponsored
        </Typography>
        <Typography color={medium} fontSize={'0.7rem'}>Ad Info</Typography>
      </FlexBetween>
      <img
        width="100%"
        height="auto"
        alt="advert"
        // src={`${Config.expressUri}/assets/ads/nvidia-300x243.jpeg`}
        src={`${Config.expressUri}/assets/ads/Nvidia-Geforce-RTX-5090-3.webp`}
        style={{
          borderRadius: "0.55rem",
          margin: "0.75rem 0",
          border: '1px solid #808080',
        }}
      />
      <FlexBetween>
        <Typography color={main} fontSize={'0.7rem'}>NVIDIA GeForce RTX 5090 - 32GB</Typography>
        <Typography color={medium} fontSize={'0.6rem'}>nvidia.com</Typography>
      </FlexBetween>
      <Typography color={medium} m="0.5rem 0" fontSize={'0.7rem'}>
        Machine-learning power at the lowest prices!
      </Typography>
    </WidgetWrapper>
  );
};

export default RTX5090AdvertWidget;

