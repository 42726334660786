// import * as Config  from "../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  Box,
  Typography,
  useTheme,
} from "@mui/material";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import WidgetWrapper from "../../components/WidgetWrapper";
import GalleryPiece from "../../components/GalleryPiece";


const GalleryWidgetA = () => {
  const { palette } = useTheme();

  const galleryPieces = [
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_Salvador_Dali_painting_of_a_glossy_Pieter__a54d9be3-4d0f-4c16-bb8f-8358458668ce.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_of_a_snake_with_the_5c02f156-ea23-40fd-ab11-3e7b388e3c80.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_3d_rendering_of_the_salvador_dali_painting_4ac7b5be-b626-4aa6-8cc2-9983fa22079e.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_Salvador_Dali_painting_of_a_society_of_ele_1db2f8a8-a36e-4623-9ab1-31906e827273.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_Salvador_Dali_painting_of_a_society_of_ele_3cae9403-76d5-42d9-a6b6-f77f6ba3fe18.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_Salvador_Dali_painting_of_a_society_of_ele_3f69ec43-45df-4986-aa98-3b2bc929d3ad.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_07b00f85-5ce0-4abb-93b2-e1a43a8bda8e.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_14d7d26b-360f-44c1-b374-52ed13ecea60.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_1fbbf06f-9891-4531-8708-5279b5bda2b2.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_590c4c35-f991-4492-8515-65b785348f63.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_60eb2642-1992-461d-a5ee-418f8c775d1d.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_64afd972-4578-4234-af7c-57fdbc1b5226.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_64eea1f5-0223-4b87-a04e-ad3ea004caf1.png"},
    {"model":"FLUX-Dev-FP8","picturePath":"FLUXfp8-2025-01-10-132236__0.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_salvador_dali_painting_of_an_ed_roth_painting_c3bb1a4e-0994-46c3-9b32-e8ef90ab4f2d.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_e55ca932-da53-4040-bd9b-60176f841d24.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_of_a_snake_with_the_5c904a2d-c528-4e3a-a837-57637a0612f6.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_of_dozens_of_glossy_93716696-bcda-40b8-b6db-81eeeb34ff25.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_of_dozens_of_stray__6c1afaf0-bb3e-4b5d-a701-61d9e54b171a.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvarod_dali_painting_of_a_glossy_ed_roth_8d4ab021-cb1d-4616-bedc-a8cf9d7e15ed.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_salvador_dali_painting_of_an_ed_roth_painting_02c9a37d-bb60-4322-bcff-e8ac1660f185.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_salvador_dali_painting_of_an_ed_roth_painting_6e8a18e7-370c-46cd-a2f8-3514a629e522.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_d7c9795e-b8b4-402d-8e91-88f26d5e0ce3.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_sentient_machine_thinking_about_the_definition_of_52482968-9ae6-4580-8bbf-9a7b494ec422.png"},
    {"model":"FLUX-Dev-FP8","picturePath":"FLUXfp8-2025-01-10-143348__0.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_sentient_machine_thinking_about_the_definition_of_6251b6a4-ac68-4d9e-8718-ba02e7c960a9.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_a015bdc6-48c6-4b42-9be5-664aa548bb0a.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_c159f170-430b-4daa-b2e8-4cdf45a76e25.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_pho_3e189153-c0f4-430d-b65d-47c58e0f4bbb.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_pho_51e53497-b36a-4c51-98ac-a48da7b94099.png"},
    // {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_1950s_couple_mowing_3f4b24b1-c020-4419-816a-7832a2defbd1.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_1950s_couple_mowing_bcc6082f-6406-43fc-ad09-e15320e85f63.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_18c8dfae-7bcf-43fb-810b-235c1de1bfa8.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_4d7c9236-b1f7-407c-86be-69c453f583bf.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_4fe9a9ee-66f4-4715-b39c-0abdb82cdb7a.png"},
    {"model":"Stable Diffusion 3.5","picturePath":"SD35-ComfyUI_00055_.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_700cfb9f-b8b1-4d7c-aaee-ca1a91e1acbd.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_82f61c3f-0542-4fee-9283-6c1e24f5b4f3.png"},
    // {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_943cb0a1-63cc-4f38-a3e8-e6b6c2dfcf29.png"},
    {"model":"Stable Diffusion 3.5","picturePath":"SD35-ComfyUI_00060_.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_92b7400f-5ec3-4f97-912b-15bcb0902ecd.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_35mm_photograph_of_a_massive_homeless_encampment__20360a4e-3868-473d-ab15-cfc0c7a9742a.png"},
    {"model":"DALL-E 2","picturePath":"DALLbugeyesdiscoball.png"},
    {"model":"DALL-E 2","picturePath":"DALLfarm.png"},
    {"model":"DALL-E 2","picturePath":"DALLmosruins.png"},
    {"model":"Midjourney3","picturePath":"thedreambox_a_glossy_ed_roth_painting_of_babies_in_prison._the__adfa0bbd-92ab-43bd-b6b3-9cea62d8a3df.png"},
    // {"model":"FLUX-Dev-FP8","picturePath":"FLUXfp8-2024-12-27-212745__0.png"},
    {"model":"FLUX-Dev-FP8","picturePath":"FLUXfp8-2024-12-27-213440__0.png"},
    {"model":"Stable Diffusion 3.5","picturePath":"SD35-ComfyUI_00012_.png"},
    {"model":"Stable Diffusion 3.5","picturePath":"SD35-ComfyUI_00019_.png"},
    {"model":"Stable Diffusion 3.5","picturePath":"SD35-ComfyUI_00056_.png"},
  ];


  return (
    <WidgetWrapper>
      <Typography
        color={palette.neutral.dark}
        variant="h5"
        fontWeight="500"
        sx={{ mb: "1.5rem" }}
      >
        {/*Model: Midjourney3*/}
      </Typography>
      <Box display="flex" flexDirection="column" gap="1.5rem">
        {galleryPieces.map((galleryPiece) => {
            console.log("= = = = GalleryWidgetA: " + JSON.stringify(galleryPiece));
            return(
              <GalleryPiece
                // key={galleryPiece._id}
                // userId={galleryPiece._id}
                // model={`${galleryPiece.model} ${galleryPiece.details}`}
                model={`${galleryPiece.model}`}  // Don't need the extra quote stuff. Leftovers.
                // subtitle={galleryPiece.occupation}
                // subtitle={galleryPiece.location}
                galleryPiecePath={galleryPiece.picturePath}
              />
            );
          }
        )}
      </Box>
    </WidgetWrapper>
  );
};

export default GalleryWidgetA;

