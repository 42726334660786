
import React from "react";
// import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { ScrollToTopOnLoad as LoadFresh } from "../../../utils";
// import * as Config from "../../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  Box,
  useMediaQuery,
} from "@mui/material";
import { Helmet } from 'react-helmet';
import NavbarPublic from "../../navbarPublic";
import Footer from "../../footer";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import ProjectBedrockWidget from "../../widgets/projects/ProjectBedrockWidget";
import DetailBedrockWidget from "../../widgets/projects/DetailBedrockWidget";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */


const ProjectBotFolkPage = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  // const { _id, picturePath } = useSelector((state) => state.user);

  // const navigate = useNavigate();
  // const handleLinkClick = (path) => {
  //   navigate(path);
  //   // if (!isNonMobileScreens) {
  //   //   setIsMobileMenuToggled(false); // Close the mobile menu after clicking a link
  //   // }
  // };


  return (
    <div>
      <Helmet>
        <title>SmartMetal.ai - Bedrock Project - Python-based Full Stack, Fast API, PostgreSQL, FastAPI-Users,  NGINX, React</title>
        <meta name="description" content="Python-based Full Stack, Fast API, PostgreSQL, FastAPI-Users,  NGINX, React" />
      </Helmet>
      <Box>
        <NavbarPublic/>
        <Box
          width="100%"
          padding="2rem 4% 0"
          display={isNonMobileScreens ? "flex" : "block"}
          gap="1.5rem"
          justifyContent="center"
        >
          <ProjectBedrockWidget />
        </Box>
        <Box
          width="100%"
          padding="2rem 4% 2rem"
          display={isNonMobileScreens ? "flex" : "block"}
          // gap="1.5rem"
          justifyContent="center"
        >
          <DetailBedrockWidget />
        </Box>
        {/*<Box*/}
        {/*  width="100%"*/}
        {/*  padding="2rem 4% 0"*/}
        {/*  display={isNonMobileScreens ? "flex" : "block"}*/}
        {/*  // gap="1.5rem"*/}
        {/*  justifyContent="center"*/}
        {/*>*/}
        {/*  <a href="https://bedrock.zone/" target="_blank" rel="noreferrer">*/}
        {/*    /!*<a href="https://www.nvidia.com/en-us/geforce/graphics-cards/50-series/" target="_blank" rel="noreferrer">*!/*/}
        {/*    bedrock.zone*/}
        {/*  </a>*/}
        {/*</Box>*/}
        <Box
          display="block"
          width="100%"
          gap="1.5rem"
          padding="0.0rem"
        >
          <Footer />
        </Box>
      </Box>
      <LoadFresh />
    </div>
  );
};

export default ProjectBotFolkPage;

