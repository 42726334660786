import React, {
  useEffect,
  // useState,
} from "react";
// import { useSelector } from "react-redux";
// import { useParams } from "react-router-dom";
import { ScrollToTopOnLoad as LoadFresh } from "../../utils";
// import * as Config from "../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  Box,
  Typography,
  // useTheme,
  useMediaQuery,
} from "@mui/material";
import { Helmet } from "react-helmet";
import NavbarPublic from "../navbarPublic";
import Footer from "../footer";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */



const CitadelPage = () => {
  // const [user, setUser] = useState(null);
  // const { userId } = useParams();
  // const token = useSelector((state) => state.token);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  // const { palette } = useTheme();
  // FOR POPUP - BUT I DON'T SEE WHY I NEED THESE - MAYBE IT's JUST HOW THE POPUP GETS ITS ARGS:
  // const [open, setOpen] = React.useState(false);
  // const [imageUrl, setImageUrl] = React.useState('');

  // const getUser = async () => {
  //   const response = await fetch(`${Config.expressUri}/users/${userId}`, {
  //     method: "GET",
  //     // headers: { Authorization: `Bearer ${token}` },
  //   });
  //   const data = await response.json();
  //   setUser(data);
  // };

  useEffect(() => {
    // getUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <div>
      <Helmet>
        <title>SmartMetal.ai - Citadel</title>
        <meta name="description" content="Citadel" />
      </Helmet>
      <Box>
        <NavbarPublic />
        <Box
          display="block"
          width="100%"
          padding="2rem 4%"
          gap="1.5rem"
          justifyContent="left"
          paddingBottom="0.0rem"
          // sx={{ pb: "0.0rem" }}  // SAME AS ABOVE paddingBottom="0.0rem". BOTH WORK.
        >
          <Typography
            fontWeight="bold"
            fontSize="1.2rem"
            color="red"
            // variant="h5"
            // fontWeight="500"
            // sx={{ mb: "0.7rem" }}
          >
            AUTHORIZED ACCESS ONLY
          </Typography>
        </Box>
        <Box
          width="100%"
          padding="2rem 4%"
          display={isNonMobileScreens ? "flex" : "block"}
          gap="2rem"
          justifyContent="center"
        >
          <Box>

            <span style={{ color: "red", fontWeight: "bold"}}>WARNING!</span>&nbsp;&nbsp;&nbsp;&nbsp; This is a
            &nbsp;&nbsp;&nbsp;<span style={{ color: "red", fontWeight: "bold"}}>RESTRICTED AREA
            &nbsp;&nbsp;&nbsp;&nbsp;[CITADEL]</span>&nbsp;&nbsp;&nbsp;&nbsp; of the SmartMetal.ai site.
            You are now in the restricted area under the URL path of /citadel/. Access to this
            area is granted on an individual basis via explicit authorization and instructions from Jim Mannix or a
            designated agent of SmartMetal.ai. This area is restricted, regardless of password protection or any
            other access control. Access requires explicit permission via an authorizing email, at the least.
            If you are not authorized to access this section /citadel/ and continue unauthorized access,
            you may be held criminally and/or civilly liable.
            <br />
            <strong>[18 U.S. Code § 1030, Cal. Penal Code § 502]</strong>

          </Box>
        </Box>
        <Box
          display="block"
          width="100%"
          gap="1.5rem"
          padding="0.0rem"
        >
          <Footer />
        </Box>
      </Box>
    <LoadFresh />
    </div>
  );
};

export default CitadelPage;

