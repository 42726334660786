
import React, {useRef, useState} from 'react';
import ReactPlayer from 'react-player';
import { useNavigate } from "react-router-dom";
// import * as Config  from "../../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  Box,
  Typography,
  useTheme,
  Divider, useMediaQuery,
} from "@mui/material";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import FlexBetween from "../../../components/FlexBetween";
import WidgetWrapper from "../../../components/WidgetWrapper";
import UnderConstruction from "../../../components/UnderConstruction";
import * as Config from "../../../config";
import {useDispatch} from "react-redux";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */


const DetailSolarWidget = () => {
  const [isMobileMenuToggled, setIsMobileMenuToggled] = useState(false);
  const theme = useTheme();
  const pal = theme.palette;
  const navigate = useNavigate();
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  const videoLeftUrl  = `${Config.expressUri}/assets/projects/vid/SunPowerVid02.mp4`;
  const videoRightUrl  = `${Config.expressUri}/assets/projects/vid/SunPowerVid03.mp4`;
  const videoRightImageUrl  = `${Config.expressUri}/assets/projects/vid/SunPowerVid03-Image.jpg`;

  const playerRef = useRef(null);

  // const handleVideoReady = () => {
  //   if (playerRef.current) {
  //     playerRef.current.seekTo(1, 'seconds'); // Seek to 2 seconds after the video loads
  //   }
  // };


  const handleLinkClickExt = (extUrl) => {
    if (!isNonMobileScreens) {
      setIsMobileMenuToggled(false);  // TODO: Correctness of this for ext link is debatable. Address everywhere.
    }
    window.open(
      extUrl,
      '_blank'
    );
  };


  return (
    <WidgetWrapper>
      <FlexBetween
        gap="1.5rem"
        pb="1.1rem"
      >
        <Box
          background={pal.neutral.dark}
          width="100%"
          padding={"0.5rem 0.5rem"}
          sx={{
            objectFit: "cover",
            borderRadius: "6px",
            border: '1px solid #808080',
          }}
        >
          <ReactPlayer
            url={ videoLeftUrl }
            playing
            controls={ true }
            loop
            width="100%"
            height="100%"
          />
        </Box>
        <Box
          background={pal.neutral.dark}
          width="100%"
          padding={"0.5rem 0.5rem"}
          sx={{
            objectFit: "cover",
            borderRadius: "6px",
            border: '1px solid #808080',
          }}
        >
          {/*DIV TRICK USED TO FIX THUMB SIZING PROBLEM:*/}
          <div style={{position: 'relative', paddingTop: '56.25%'}}>
            <ReactPlayer
              url={ videoRightUrl }
              playing
              controls={ true }
              // onReady={ handleVideoReady }
              light={ videoRightImageUrl }
              width="100%"
              height="100%"
              style={{position: 'absolute', top: 0, left: 0}}
              className="react-player"
              // sx={{
              //   '& .react-player__light': {
              //     width: '100%',  // Adjust the width of the light image
              //     height: '100%', // Adjust the height of the light image
              //     // objectFit: 'cover', // Optional: Ensures the image fits within the container
              //   },
              //   '& .react-player__play-icon': {
              //     // width: '100%',  // Adjust the width of the play icon
              //     // height: '100%', // Adjust the height of the play icon
              //   },
              // }}
            />
          </div>
        </Box>
      </FlexBetween>
      <Box p="0.0rem 0">
        <FlexBetween mb="1.5rem">
          <Typography
            // variant="personal"
            fontSize="1.0rem"
            color={pal.neutral.dark}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;SunPower Inc. was acquired and most operations were shut down.
            &nbsp;&nbsp;This was an amazing application and we even had a module which output
            construction-ready architectural drawings, incorporating localized permit requirements!
            &nbsp;&nbsp;This was one of the best practical applications of Machine Learning I have
            seen in a web app and one of the most sophisticated full stacks I have helped build.
            &nbsp;&nbsp;I'm proud of having helped to advance this great project on this great team.
          </Typography>
        </FlexBetween>
      </Box>

      <Divider color={pal.warning.dark} sx={{ "background-color": pal.warning.dark }} />

      <Box m="1.5rem 0" />

      <Typography
        variant="h4"
        // fontWeight="bold"
        // fontSize="1.1rem"
        color={pal.neutral.dark}
        // variant="h5"
        // fontWeight="500"
        sx={{ mb: "1.5rem" }}
      >
        Patents
      </Typography>

      <Typography
        fontWeight="500"
        fontSize="1.0rem"
        color="primary"
        onClick={() => handleLinkClickExt("https://patents.justia.com/patent/10754999")}
        sx={{
          // mt: "0.4rem",
          "&:hover": {
            cursor: "pointer",
            color: pal.primary.less_ink,
          },
        }}
      >
        2019 Patent (US 10,754,999) - Solar System Design using Machine Learning and Satellite Imagery
        &nbsp;(Generating a Photovoltaic System Design for a Building 1)
      </Typography>

      <Box m="1.1rem 0" />

      <Typography
        fontWeight="500"
        fontSize="1.0rem"
        color="primary"
        onClick={() => handleLinkClickExt("https://patents.justia.com/patent/11392730")}
        sx={{
          // mt: "0.4rem",
          "&:hover": {
            cursor: "pointer",
            color: pal.primary.less_ink,
          },
        }}
      >
        2020 Patent (US 11,392,730) - Realtime/3D Solar Design via Machine Learning and Satellite Imagery
        &nbsp;(Generating a Photovoltaic System Design for a Building 2)
      </Typography>

      <Box m="1.1rem 0" />

      <Typography
        fontWeight="500"
        fontSize="1.0rem"
        color="primary"
        onClick={() => handleLinkClickExt("https://patents.justia.com/inventor/james-mannix")}
        sx={{
          // mt: "0.4rem",
          "&:hover": {
            cursor: "pointer",
            color: pal.primary.less_ink,
          },
        }}
      >
        Patents and Filings by Inventor James Mannix
      </Typography>

    </WidgetWrapper>
  );
};

export default DetailSolarWidget;

// 2019 Patent: (US10754999) ML/AI Full Stack, Solar System Design
// 2020 Patent: (US11392730) ML/AI Full Stack, Realtime/3D Solar Design



// REACT PLAYER THUMB SIZING DIV FIX:
// https://stackoverflow.com/questions/73056189/change-height-and-width-of-a-react-player-thumbnail-using-light-prop


// SPECIAL CSS FOR REACT PLAYER THUMB IMAGE AND THE ICON TO PLAY (LIGHT MODE STUFF)
// sx={{
//   '& .react-player__light': {
//     width: '100%',  // Adjust the width of the light image
//     height: '100%', // Adjust the height of the light image
//     // objectFit: 'cover', // Optional: Ensures the image fits within the container
//   },
//   '& .react-player__play-icon': {
//     // width: '100%',  // Adjust the width of the play icon
//     // height: '100%', // Adjust the height of the play icon
//   },
// }}


// <a href="https://patents.justia.com/patent/11392730" target="_blank" rel="noreferrer">
//   PATENT # 11392730 - SUNPOWER CORPORATION - Generating a Photovoltaic System Design for a Building, 2022 - Lead Developer: James Mannix
// </a>
// <br />
// <br />
// <a href="https://patents.justia.com/patent/10754999" target="_blank" rel="noreferrer">
//   PATENT # 10754999 - SUNPOWER CORPORATION - Generating a Photovoltaic System Design for a Building, 2020 - Lead Developer: James Mannix
// </a>
// <br />
// <br />
// <a href="https://patents.justia.com/patent/20200364382" target="_blank" rel="noreferrer">
//   PATENT PUBLICATION # 20200364382 - SUNPOWER CORPORATION - Generating a Photovoltaic System Design for a Building, 2020 - Lead Developer: James Mannix
// </a>
// <br />
// <br />
// <a href="https://patents.justia.com/inventor/james-mannix" target="_blank" rel="noreferrer">
//   PATENTS & FILINGS BY INVENTOR JAMES MANNIX #'S 11392730, 20200364382, 10754999
// </a>
// <br />
// <br />

