// import * as Config from "../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  // Box,
  // Typography,
  useTheme,
} from "@mui/material";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import FlexBetween from "./FlexBetween";
import GalleryPieceImage from "./GalleryPieceImage";


// const GalleryPiece = ({ userId, model, subtitle, galleryPiecePath }) => {
const GalleryPiece = ({
    model,
    galleryPiecePath,
  }) => {
  const { palette } = useTheme();
  // const primaryLight = palette.primary.less_ink;
  // const primaryDark = palette.primary.more_ink;
  // const main = palette.neutral.main;
  // const medium = palette.neutral.medium;

  return (
    <FlexBetween>
      <FlexBetween gap="1rem">
        {/*<GalleryPieceImage image={galleryPiecePath} size="55px" />*/}
        <GalleryPieceImage image={galleryPiecePath} />
      </FlexBetween>
      {/*IconButton was here*/}
    </FlexBetween>
  );
};

export default GalleryPiece;


//   return (
//     <FlexBetween>
//       <FlexBetween gap="1rem">
//         {/*<GalleryPieceImage image={galleryPiecePath} size="55px" />*/}
//         <GalleryPieceImage image={galleryPiecePath} />
//         <Box
//           // onClick={() => {
//           //   navigate(`/profile/${userId}`);
//           //   navigate(0);
//           // }}
//         >
//           <Typography
//             color={main}
//             variant="h5"
//             fontWeight="500"
//             // sx={{
//             //   "&:hover": {
//             //     color: palette.primary.less_ink,
//             //     cursor: "pointer",
//             //   },
//             // }}
//           >
//             {model}
//           </Typography>
//           <Typography color={medium} fontSize="0.75rem">
//             {/*{subtitle}*/} . . .
//           </Typography>
//         </Box>
//       </FlexBetween>
//       {/*IconButton was here*/}
//     </FlexBetween>
//   );
// };

