
import React from 'react';
// import * as Config  from "../../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  Box,
  Typography,
  useTheme,
} from '@mui/material';
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import UnderConstructionBar from "./UnderConstructionBar";


const UnderConstruction = () => {
  const theme = useTheme();
  const pal = theme.palette;


  return (
    <div>
      <Box p="2rem 0">
        <UnderConstructionBar/>
      </Box>

      <Box textAlign={"center"} display={"flex"} flexDirection={"column"} p="1rem 0">
        <Typography variant="h5" color={pal.neutral.medium}>
          &nbsp;&nbsp;&nbsp;&nbsp;CONSTRUCTION AREA
        </Typography>
        <br/>
        <Typography variant="h5" color={pal.neutral.medium}>
          &nbsp;&nbsp;&nbsp;&nbsp;Work here will be complete in March, 2025.
        </Typography>
      </Box>

      <Box p="2rem 0">
        <UnderConstructionBar/>
      </Box>
    </div>
  );
};

export default UnderConstruction;

