
import React from 'react';
// import { useNavigate } from "react-router-dom";
// import * as Config  from "../../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  // Box,
  // Typography,
  useTheme,
  // Divider,
} from "@mui/material";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
// import FlexBetween from "../../../components/FlexBetween";
import WidgetWrapper from "../../../components/WidgetWrapper";
import UnderConstruction from "../../../components/UnderConstruction";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */


const UnderConstructionWidget = () => {
  const theme = useTheme();
  // const pal = theme.palette;
  // const navigate = useNavigate();


  return (
    <WidgetWrapper>

      <UnderConstruction />

    </WidgetWrapper>
  );
};

export default UnderConstructionWidget;

