import { useEffect } from "react";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import { setFriends } from "../../state";
import * as Config  from "../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  Box,
  Typography,
  useTheme,
} from "@mui/material";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import WidgetWrapper from "../../components/WidgetWrapper";
import Friend from "../../components/Friend";


const FriendListWidget = ({ userId }) => {
  const dispatch = useDispatch();
  const { palette } = useTheme();
  const token = useSelector((state) => state.token);
  const friends = useSelector((state) => state.user.friends);

  const getFriends = async () => {
    const response = await fetch(
      `${Config.expressUri}/users/${userId}/friends`,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const data = await response.json();
    dispatch(setFriends({ friends: data }));
  };

  useEffect(() => {
    getFriends();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // console.log("* * * * FriendListWidget FRIENDS: " + JSON.stringify(friends));
  return (
    <WidgetWrapper>
      <Typography
        color={palette.neutral.dark}
        variant="h5"
        fontWeight="500"
        sx={{ mb: "1.5rem" }}
      >
        Friend List
      </Typography>
      <Box display="flex" flexDirection="column" gap="1.5rem">
        {friends.map((friend) => {
          // console.log("= = = = FriendListWidget: " + JSON.stringify(friend));
          return(
          <Friend
            key={friend._id}
            friendId={friend._id}
            name={`${friend.firstName} ${friend.lastName}`}
            subtitle={friend.occupation}
            userPicturePath={friend.picturePath}
          />
          );
        }
        )}
      </Box>
    </WidgetWrapper>
  );
};

export default FriendListWidget;

