
import React from "react";
// import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { ScrollToTopOnLoad as LoadFresh } from "../../utils";
// import * as Config from "../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  Box, Typography,
  useMediaQuery, useTheme,
} from "@mui/material";
import { Helmet } from 'react-helmet';
import NavbarPublic from "../navbarPublic";
import Footer from "../footer";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import UnderConstructionWidget from "../widgets/projects/UnderConstructionWidget";
import DevListBasic from "../widgets/DevListBasic";
import WidgetWrapper from "../../components/WidgetWrapper";
import UnderConstruction from "../../components/UnderConstruction";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */


const DevPage = () => {
  const theme = useTheme();
  const pal = theme.palette;
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");


  return (
    <div>
      <Helmet>
        <title>SmartMetal.ai - Developer Resources - </title>
        <meta name="description" content="AnsibleMyEC2 - Open Source - Ansible Automation: AWS/EC2" />
      </Helmet>
      <Box>
        <NavbarPublic/>

        <Box
          width="100%"
          padding="2rem 4% 0"
          display={isNonMobileScreens ? "flex" : "block"}
          gap="1.5rem"
          justifyContent="center"
        >
          <DevListBasic />
        </Box>

        <Box
          width="100%"
          padding="2rem 4% 2rem"
          display={isNonMobileScreens ? "flex" : "block"}
          // gap="1.5rem"
          justifyContent="center"
        >

          {/*-------- UNDER CONSTRUCTION --------*/}
          <WidgetWrapper>
            <Typography
              variant="h5"
              color={pal.neutral.dark}
              fontWeight="500"
              // fontStyle={"italic"}
              marginBottom="0.75rem"
            >
              * I have hundreds of very valuable links I will be adding here soon. There will be perhaps 20 to 30
              categories of links to resources in today's most relevant areas of technology.
            </Typography>
            <UnderConstruction />
          </WidgetWrapper>
          <Box m="2rem 0" />
        </Box>

        <Box
          display="block"
          width="100%"
          gap="1.5rem"
          padding="0.0rem"
        >
          <Footer />
        </Box>
      </Box>
      <LoadFresh />
    </div>
  );
};

export default DevPage;


// https://github.com/jimmygizmo/AnsibleMyEC2

