
import React from "react";
// import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { ScrollToTopOnLoad as LoadFresh } from "../../../utils";
// import * as Config from "../../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  Box,
  useMediaQuery,
} from "@mui/material";
import { Helmet } from 'react-helmet';
import NavbarPublic from "../../navbarPublic";
import Footer from "../../footer";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import ProjectSolarWidget from "../../widgets/projects/ProjectSolarWidget";
import DetailSolarWidget from "../../widgets/projects/DetailSolarWidget";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */


const ProjectSolarPage = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  // const { _id, picturePath } = useSelector((state) => state.user);

  // const navigate = useNavigate();
  // const handleLinkClick = (path) => {
  //   navigate(path);
  //   // if (!isNonMobileScreens) {
  //   //   setIsMobileMenuToggled(false); // Close the mobile menu after clicking a link
  //   // }
  // };


  return (
    <div>
      <Helmet>
        <title>SmartMetal.ai - Patented Machine Learning Design Application - Lead Developer</title>
        <meta name="description" content="Patented Machine Learning Design Application - Lead Developer" />
      </Helmet>
      <Box>
        <NavbarPublic/>
        <Box
          width="100%"
          padding="2rem 4% 0"
          display={isNonMobileScreens ? "flex" : "block"}
          gap="1.5rem"
          justifyContent="center"
        >
          <ProjectSolarWidget />
        </Box>
        <Box
          width="100%"
          padding="2rem 4% 2rem"
          display={isNonMobileScreens ? "flex" : "block"}
          // gap="1.5rem"
          justifyContent="center"
        >
          <DetailSolarWidget />
        </Box>
        {/*<Box*/}
        {/*  width="100%"*/}
        {/*  padding="2rem 4% 2rem"*/}
        {/*  display={isNonMobileScreens ? "flex" : "block"}*/}
        {/*  // gap="1.5rem"*/}
        {/*  justifyContent="center"*/}
        {/*>*/}
        {/*  <DetailSolarWidget2 />*/}
        {/*</Box>*/}

        <Box
          display="block"
          width="100%"
          gap="1.5rem"
          padding="0.0rem"
        >
          <Footer />
        </Box>
      </Box>
      <LoadFresh />
    </div>
  );
};

export default ProjectSolarPage;


// <a href="https://patents.justia.com/patent/11392730" target="_blank" rel="noreferrer">
//   PATENT # 11392730 - SUNPOWER CORPORATION - Generating a Photovoltaic System Design for a Building, 2022 - Lead Developer: James Mannix
// </a>
// <br />
// <br />
// <a href="https://patents.justia.com/patent/10754999" target="_blank" rel="noreferrer">
//   PATENT # 10754999 - SUNPOWER CORPORATION - Generating a Photovoltaic System Design for a Building, 2020 - Lead Developer: James Mannix
// </a>
// <br />
// <br />
// <a href="https://patents.justia.com/patent/20200364382" target="_blank" rel="noreferrer">
//   PATENT PUBLICATION # 20200364382 - SUNPOWER CORPORATION - Generating a Photovoltaic System Design for a Building, 2020 - Lead Developer: James Mannix
// </a>
// <br />
// <br />
// <a href="https://patents.justia.com/inventor/james-mannix" target="_blank" rel="noreferrer">
//   PATENTS & FILINGS BY INVENTOR JAMES MANNIX #'S 11392730, 20200364382, 10754999
// </a>
// <br />
// <br />