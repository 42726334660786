
console.log("-------- config.js:  process.env.NODE_ENV: ");
console.log(process.env.NODE_ENV);

// NOTE: For create-react-app APPS LIKE THIS ONE: ENV vars other than NODE_ENV must be prefixed with REACT_APP_ in order
//   to be readable by process.env

// OLDER IDEA: WE NEED CODE PRE-PROCESSING, BEFORE BUILD, TO PARSE IN SOME ENV VAR VALUES.
// * Possibly this idea came out of not prefixing create-react-app env vars with REACT_APP_
// CHECK OUT: https://github.com/kentcdodds/babel-plugin-preval
// ALSO CONSIDER: https://babeljs.io/docs/babel-plugin-transform-inline-environment-variables

// This value will ALWAYS take effect. The ability to override it via ENV can never occur because this env
// var can never be seen by any browser. (NODE_ENV is different. It can be seen but only via Node dev server and my
// development mode is often not this; rather I run the full stack, locally on non-priviliged ports.)
// USE THIS TO SET FOR EITHER PRODUCTION DEPLOYMENT OR LOCA FULL STACK TESTING.
// TODO: Automate setting of this value through some build step which can parse source code.

// IMPORTANT: In local hosts files on all platforms, hostnammes like "smartmetal-local" are mapped to 127.0.0.1.
//     So these *-local hostnames I use (about 4 or 5 of them) are all the equivalent to "localhost" but the difference
//     is that NGINX knows about them and will properly route virtual host requests using them. The NGINX in this
//     stack, which servers multiple domains on a single IP, cannot server all sites using "localhost" and many
//     services in the stack may not work. Use the special local hostnames like "smartmetal-local", "botfolk-local", etc.


// STACK LOCAL:     https://smartmetal-local:44443/express
// STACK DEPLOYED:  https://smartmetal.ai/express
// ********************************************************************************
// ********************************************************************************
// const FULL_STACK_MODE_EXPRESS_URI_FALLBACK = "https://smartmetal-local:44443/express"
// ********************************************************************************
// ********************************************************************************
const FULL_STACK_MODE_EXPRESS_URI_FALLBACK = "https://smartmetal.ai/express"
const FULL_STACK_MODE_FASTAPI_URI_FALLBACK = "https://smartmetal.ai/fastapi"
// ********************************************************************************
// ********************************************************************************


// Development server mode:    WEB: http://smartmetal-local:3000    API: http://smartmetal-local:46001
//   Makes use of the full stack Express+MongoDB. App served by Node CRA dev server.
// ------------------------
// Full STACK LOCAL mode:    WEB: https://smartmetal-local:44443    API: https://smartmetal-local:44443/express
//
// ------------------------
// Full STACK DEPLOYED mode:    WEB: https://smartmetal.ai    API: https://smartmetal.ai/express
//
// ------------------------
// TODO: We only need to switch in the App for apolloUri. the web uri is what you type in the browser etc.

// API:
// Dev server:      http://smartmetal-local:46001    TODO: Might be wrong port in this comment. Double check this.
// STACK LOCAL:     https://smartmetal-local:44443/express
// STACK DEPLOYED:  https://smartmetal.ai/express

// We need a runtime (browser-side) switch which can detect which mode our full stack is in.
// The key difference is different docker-compose.yml files.
// TODO: TEMPORARY COMPROMISE: WILL JUST USE DEV SERVER FOR NOW. STACK LOCAL WILL NOT WORK, BUT STACK DEPLOYED WILL.
//       UPDATE: above comments are outdated. local COMBINED stack works great, but this mechanism is not complete.
//               If it refers to still needing to un/comment at top of this file .. YES that is still unfortunately true.

// TODO: MISGUIDED ATTEMPT. FAILED. BROWSER CANNOT SEE THESE ENV VARS. NODE_ENV IS THE ONLY ONE THAT CAN BE SEEM
//    AND THIS IS ONLY WHEN THE DEV SERVER IS SERVING THE PAGES.
// fullStackExpressUri - Should defaul to what works in production.
// If compose provides the value, it should be for your local full-stack dev mode in the default docker-compose.yml for
// that purpose. Your production docker-compose.production.yml should also have the value but it should have the
// the prod DNS-correct hostname (and no port of course)
const fullStackExpressUri = process.env.FULL_STACK_MODE_EXPRESS_URI || FULL_STACK_MODE_EXPRESS_URI_FALLBACK

const fullStackFastApiUri = process.env.FULL_STACK_MODE_FASTAPI_URI || FULL_STACK_MODE_FASTAPI_URI_FALLBACK

export const expressUri = process.env.NODE_ENV ===
"development" ? "http://smartmetal-local:46001/express" : fullStackExpressUri;

export const fastApiUri = process.env.NODE_ENV ===
"development" ? "http://smartmetal-local:48000/fastapi" : fullStackFastApiUri;

// FOR GOING DIRECTLY TO FASTAPI LOCAL DEV SERVER - TO TAKE ADVANTAGE OF RAPID RESTARTS (AVOID COMPOSE BUILD WAITS)
// export const fastApiUri = process.env.NODE_ENV ===
// "development" ? "http://smartmetal-local:8000/fastapi" : fullStackFastApiUri;


// UPDATE: SOLUTION IN PLACE - TEST IT!!!
// TODO: So the one we have not covered is STACK LOCAL: https://smartmetal-local:44443/express

console.log("-------- config.js: expressUri: ");
console.log(expressUri);
console.log("-------- config.js: fastApiUri: ");
console.log(fastApiUri);


// FROM: https://stackoverflow.com/questions/35469836/detecting-production-vs-development-react-at-runtime

