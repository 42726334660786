
import React from "react";  // For useState for POPUP for setOpen and setImageUrl
import * as Config from "../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  Box,
  Typography,
  useTheme,
  Dialog,
  DialogContent,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import WidgetWrapper from "../../components/WidgetWrapper";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */


const GalleryStrip = () => {
  const theme = useTheme();
  const pal = theme.palette;
  // FOR POPUP - BUT I DON'T SEE WHY I NEED THESE:
  const [open, setOpen] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState('');


// FOR POPUP
  const ImagePopup = ({
      imageUrl,
      open,
      onClose,
    }) => {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
            boxShadow: 'none',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '2%',
          },
        }}
      >
        <DialogContent
          style={{
            height: '100vh',
            overflow: 'hidden',
          }}
        >
          <img
            onClick={onClose}
            src={imageUrl}
            alt="Generative AI"
            style={{
              cursor: "pointer",
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain', // Prevents scrollbars and resizes image to fit
              borderRadius: '0.75rem',
              border: '1px solid #808080',
              boxShadow: '0px 8px 12px rgba(0, 0, 0, 0.8)',
            }}
          />
          <IconButton
            onClick={onClose}
            style={{
              margin: '.5rem',
              position: 'absolute',
              color: 'white',
              border: '1px solid #808080',
              zIndex: 10,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogContent>
      </Dialog>
    );
  };


  // FOR POPUP
  const handleOpen = (url) => {
    setImageUrl(url);
    setOpen(true);
  };

  // FOR POPUP
  const handleClose = () => {
    setOpen(false);
  };


  const galleryPieces = [
    {
      img: '2025-02-12-104517__0.png',
      thumb: 'thm/2025-02-12-104517__0-thumb.png',
      title: 'FLUX1 Dev fp8',
      author: '@dreambox',
    },
    {
      img: 'thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_07574ce8-f596-426d-ab20-4b0156ed5f30.png',
      thumb: 'thm/thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_07574ce8-f596-426d-ab20-4b0156ed5f30-thumb.png',
      title: 'Midjourney 3',
      author: '@dreambox',
    },
    {
      img: 'FLUXfp8-2024-12-28-004615__0.png',
      thumb: 'thm/FLUXfp8-2024-12-28-004615__0-thumb.png',
      title: 'FLUX1 Dev fp8',
      author: '@dreambox',
    },
    {
      img: '2025-02-12-113003__0.png',
      thumb: 'thm/2025-02-12-113003__0-thumb.png',
      title: 'FLUX1 Dev fp8',
      author: '@dreambox',
    },
    {
      img: '2025-02-12-113412__0.png',
      thumb: 'thm/2025-02-12-113412__0-thumb.png',
      title: 'FLUX1 Dev fp8',
      author: '@dreambox',
    },
    {
      img: 'FLUXfp8-2025-01-10-135101__0.png',
      thumb: 'thm/FLUXfp8-2025-01-10-135101__0-thumb.png',
      title: 'FLUX1 Dev fp8',
      author: '@jimmygizmo',
    },
    {
      img: 'FLUX-bedrock-great02.png',
      thumb: 'thm/FLUX-bedrock-great02-thumb.png',
      title: 'FLUX1 Dev fp8',
      author: '@jimmygizmo',
    },
    {
      img: 'FLUXfp8-2025-01-10-143306__0.png',
      thumb: 'thm/FLUXfp8-2025-01-10-143306__0-thumb.png',
      title: 'FLUX1 Dev fp8',
      author: '@jimmygizmo',
    },
    {
      img: 'FLUXfp8-2024-12-28-011334__0.png',
      thumb: 'thm/FLUXfp8-2024-12-28-011334__0-thumb.png',
      title: 'FLUX1 Dev fp8',
      author: '@dreambox',
    },
    // {
    //   img: '2025-02-12-121848__0.png',
    //   thumb: 'thm/2025-02-12-121848__0-thumb.png',
    //   title: 'FLUX1 Dev fp8',
    //   author: '@dreambox',
    // },
    {
      img: 'thedreambox_a_glossy_photo-realistic_3d_rendering_of_a_hideous__4bd45f93-b781-4894-8eaa-0104b512e0d3.png',
      thumb: 'thm/thedreambox_a_glossy_photo-realistic_3d_rendering_of_a_hideous__4bd45f93-b781-4894-8eaa-0104b512e0d3-thumb.png',
      title: 'Midjourney 3',
      author: '@dreambox',
    },
    // {
    //   img: 'thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_baae72e6-67ac-4204-a4ab-9beb5a04d401.png',
    //   thumb: 'thm/thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_baae72e6-67ac-4204-a4ab-9beb5a04d401-thumb.png',
    //   title: 'Midjourney 3',
    //   author: '@dreambox',
    // },
    // {
    //   img: '2025-02-12-123358__0.png',
    //   thumb: 'thm/2025-02-12-123358__0-thumb.png',
    //   title: 'FLUX1 Dev fp8',
    //   author: '@dreambox',
    // },
    // {
    //   img: '2025-02-12-102012__0.png',
    //   thumb: 'thm/2025-02-12-102012__0-thumb.png',
    //   title: 'FLUX1 Dev fp8',
    //   author: '@dreambox',
    // },
    {
      img: 'thedreambox_a_glossy_Salvador_Dali_painting_of_a_society_of_ele_077e2401-78f9-4e56-a195-9bca5b684dae.png',
      thumb: 'thm/thedreambox_a_glossy_Salvador_Dali_painting_of_a_society_of_ele_077e2401-78f9-4e56-a195-9bca5b684dae-thumb.png',
      title: 'Midjourney 3',
      author: '@dreambox',
    },
    {
      img: 'FLUXfp8-2024-12-28-005622__0.png',
      thumb: 'thm/FLUXfp8-2024-12-28-005622__0-thumb.png',
      title: 'FLUX1 Dev fp8',
      author: '@dreambox',
    },
    {
      img: 'thedreambox_a_glossy_Salvador_Dali_painting_of_a_glossy_Pieter__92fea657-2072-470d-91d2-6065481d8b5d.png',
      thumb: 'thm/thedreambox_a_glossy_Salvador_Dali_painting_of_a_glossy_Pieter__92fea657-2072-470d-91d2-6065481d8b5d-thumb.png',
      title: 'Midjourney 3',
      author: '@dreambox',
    },
    // {
    //   img: 'thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_3b3977f8-e751-4982-8727-c7b7b17489a2.png',
    //   thumb: 'thm/thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_3b3977f8-e751-4982-8727-c7b7b17489a2-thumb.png',
    //   title: 'Midjourney 3',
    //   author: '@dreambox',
    // },
    // {
    //   img: 'thedreambox_a_clumpy_pile_of_oozing_opalescent_glossy_melting_g_72b5e0d4-4d4b-4729-9d26-a3599ac246a5.png',
    //   thumb: 'thm/thedreambox_a_clumpy_pile_of_oozing_opalescent_glossy_melting_g_72b5e0d4-4d4b-4729-9d26-a3599ac246a5-thumb.png',
    //   title: 'Midjourney 3',
    //   author: '@dreambox',
    // },
    // {
    //   img: 'thedreambox_a_salvador_dali_painting_of_an_ed_roth_painting_6a6c688a-8137-478a-b54f-68c8ddc52ec1.png',
    //   thumb: 'thm/thedreambox_a_salvador_dali_painting_of_an_ed_roth_painting_6a6c688a-8137-478a-b54f-68c8ddc52ec1-thumb.png',
    //   title: 'Midjourney 3',
    //   author: '@dreambox',
    // },
    // {
    //   img: 'SD35-ComfyUI_00082_.png',
    //   thumb: 'thm/SD35-ComfyUI_00082_-thumb.png',
    //   title: 'Stable Diffusion 3.5',
    //   author: '@dreambox',
    // },
    // {
    //   img: 'thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_898bed7d-fa85-4441-bfc2-e6190e4b34a1.png',
    //   thumb: 'thm/thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_898bed7d-fa85-4441-bfc2-e6190e4b34a1-thumb.png',
    //   title: 'Midjourney 3',
    //   author: '@dreambox',
    // },
    // {
    //   img: 'DALLantswaspss.png',
    //   thumb: 'thm/DALLantswaspss-thumb.png',
    //   title: 'DALL-E 2',
    //   author: '@dreambox',
    // },
    {
      img: '2025-02-12-105344__0.png',
      thumb: 'thm/2025-02-12-105344__0-thumb.png',
      title: 'FLUX1 Dev fp8',
      author: '@dreambox',
    },
    // {
    //   img: 'thedreambox_a_cinematic_photograph_from_an_architectural_digest_161f68a3-4c0e-48fe-bd3a-d00d6b68e6ca.png',
    //   thumb: 'thm/thedreambox_a_cinematic_photograph_from_an_architectural_digest_161f68a3-4c0e-48fe-bd3a-d00d6b68e6ca-thumb.png',
    //   title: 'Midjourney 3',
    //   author: '@dreambox',
    // },
    // {
    //   img: 'DALLopalglitterdemon.png',
    //   thumb: 'thm/DALLopalglitterdemon-thumb.png',
    //   title: 'DALL-E 2',
    //   author: '@dreambox',
    // },
    // {
    //   img: 'thedreambox_a_glossy_salvador_dali_painting_of_dozens_of_stray__b158ee0e-23a2-4a22-ab35-7896e37006d7.png',
    //   thumb: 'thm/thedreambox_a_glossy_salvador_dali_painting_of_dozens_of_stray__b158ee0e-23a2-4a22-ab35-7896e37006d7-thumb.png',
    //   title: 'Midjourney 3',
    //   author: '@dreambox',
    // },
    // {
    //   img: 'thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_ef505be9-f50b-430f-80ab-8d77d81faea6.png',
    //   thumb: 'thm/thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_ef505be9-f50b-430f-80ab-8d77d81faea6-thumb.png',
    //   title: 'Midjourney 3',
    //   author: '@dreambox',
    // },
    // {
    //   img: 'thedreambox_a_glossy_Salvador_Dali_painting_of_a_glossy_Pieter__8257b787-2e22-481f-95d2-907834bcee43.png',
    //   thumb: 'thm/thedreambox_a_glossy_Salvador_Dali_painting_of_a_glossy_Pieter__8257b787-2e22-481f-95d2-907834bcee43-thumb.png',
    //   title: 'Midjourney 3',
    //   author: '@dreambox',
    // },
    // {
    //   img: 'thedreambox_a_sentient_machine_thinking_about_the_definition_of_a0f993a7-8563-42e5-9c19-da045a46b51f.png',
    //   thumb: 'thm/thedreambox_a_sentient_machine_thinking_about_the_definition_of_a0f993a7-8563-42e5-9c19-da045a46b51f-thumb.png',
    //   title: 'Midjourney 3',
    //   author: '@dreambox',
    // },
    {
      img: '2025-02-12-124703__0.png',
      thumb: 'thm/2025-02-12-124703__0-thumb.png',
      title: 'FLUX1 Dev fp8',
      author: '@dreambox',
    },
    {
      img: 'FLUX-snake-00.png',
      thumb: 'thm/FLUX-snake-00-thumb.png',
      title: 'FLUX1 Dev fp8',
      author: '@jimmygizmo',
    },
  ]


  return (
    <WidgetWrapper>
      <Typography
        variant="h4"
        // fontWeight="bold"
        // fontSize="1.1rem"
        color={pal.neutral.dark}
        // variant="h5"
        // fontWeight="500"
        sx={{ mb: "1.5rem" }}
      >
        Gen AI
      </Typography>
      <Box display="flex" flexDirection="column">

        <ImageList
          cols={1}
          gap="2.0rem"
          sx={{
            margin: "0.0rem",
            width: "100%",
            height: "100%",
          }}
        >
          {galleryPieces.map((item) => (
            <ImageListItem
              key={item.img}
            >
              <img
                style={{
                  cursor: "pointer",
                  objectFit: "cover",
                  borderRadius: "0.5rem",
                  border: '1px solid #808080',
                }}
                srcSet={`${Config.expressUri}/assets/gallery/${item.thumb}?w=248&fit=crop&auto=format&dpr=2 2x`}
                src={`${Config.expressUri}/assets/gallery/${item.thumb}?w=248&fit=crop&auto=format`}
                alt={item.title}
                loading="lazy"
                onClick={() => handleOpen(`${Config.expressUri}/assets/gallery/${item.img}`)}
              />
              <ImageListItemBar
                title={item.title}
                // subtitle={<span>by: {item.author}</span>}
                position="below"
                sx={{
                  background: "rgba(0, 0, 0, 0.5)",
                  color: "white",
                  padding: "0.0rem 0.2rem 0.0rem 0.6rem",
                  borderRadius: "0.5rem",
                  border: '1px solid #808080',
                  "border-top": "none",
                  "& .MuiImageListItemBar-titleWrap.MuiImageListItemBar-titleWrapBelow": {
                    paddingBottom: "7px",
                  }, //styles for bottom of Bar
                  "& .MuiImageListItemBar-title": {
                    fontSize: '0.75rem',
                    lineHeight: '1.0rem',
                    textWrap: "wrap",
                  }, //styles for title
                  "& .MuiImageListItemBar-subtitle": {
                    fontSize: '0.65rem',
                    lineHeight: '0.8rem',
                    textWrap: "wrap",
                  }, //styles for subtitle
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>

        {/* FOR POPUP */}
        <ImagePopup imageUrl={imageUrl} open={open} onClose={handleClose} />

      </Box>
    </WidgetWrapper>
  );
};

export default GalleryStrip;

