
import { useNavigate } from "react-router-dom";
// import * as Config  from "../../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Divider,
  IconButton,
} from "@mui/material";
import { ZoomIn } from "@mui/icons-material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import FlexBetween from "../../../components/FlexBetween";
import WidgetWrapper from "../../../components/WidgetWrapper";
import ProjectImage from "../../../components/ProjectImage";
import TechChip from "../../../components/TechChip";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */


const ProjectBedrockWidget = ({
    smallMode = false,
  }) => {
  const theme = useTheme();
  const pal = theme.palette;
  // const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  const isNonTinyScreens = useMediaQuery("(min-width: 800px)");
  const navigate = useNavigate();

  const imageFileProject = "thm/FLUX-bedrock-great03-crop2-thumb.png";
  const projectUrl = "/project/bedrock";
  const imageAlt = "The Bedrock Stack - The Ultimate Python Full Stack (image of a python on an ocean rock)";


  return (
    <WidgetWrapper>
      {/* -------- FIRST ROW -------- */}
      <FlexBetween
        gap="1.5rem"
        pb="1.1rem"
      >
        <FlexBetween gap="1.5rem">
          { isNonTinyScreens && (
            <ProjectImage
              smallMode={smallMode}
              imageFileProject={imageFileProject}
              imageAlt={imageAlt}
              imageLinkUrl={projectUrl}
            />
          )}
          <Box>
            <FlexBetween gap="0.5rem">
              <Typography
                variant="h4"
                color={pal.neutral.dark}
                fontWeight="600"
                marginBottom="0.75rem"
              >
                The Bedrock Stack - The Ultimate Python Full Stack
              </Typography>
              {/* -------- ZOOM NAVIGATE - ONLY IN SMALL MODE -------- */}
              { smallMode && (
                <IconButton
                  onClick={() => navigate(projectUrl)}
                >
                  <ZoomIn
                    sx={{
                      fontSize: "1.8rem",
                      color: pal.primary.main,
                    }}
                  />
                </IconButton>
              )}
            </FlexBetween>

            {/* -------- FIRST ROW - SUB-HEADING & BULLETS -------- */}
            <Typography
              variant="h5"
              color={pal.neutral.dark}
              fontWeight="500"
              marginBottom="0.75rem"
            >
              Open Source Project - Oct 2024 to Present
            </Typography>
            <FlexBetween gap="1.0rem">
              <List sx={{ pt: 0, pb: 0, listStyleType: 'disc', color: pal.neutral.main }}>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >
                  FastAPI now preferred over Flask
                </ListItem>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >
                  Maximally-Asynchronous Implementation
                </ListItem>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >
                  Advanced NGINX Acceleration, Security
                </ListItem>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >
                  Advanced NGINX Multi-Domain, Logging
                </ListItem>
              </List>
              <List sx={{ pt: 0, pb: 0, listStyleType: 'disc', color: pal.neutral.main }}>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >
                  Industrial Strength Users/Accounts: FastAPI-Users
                </ListItem>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >
                  Modern React, Chakra UI Frontend (*soon)
                </ListItem>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >
                  Automated Build and Deployment via BASH/ZSH
                </ListItem>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >
                  PGAdmin Instance supports the PostgreSQL DB
                </ListItem>
              </List>
            </FlexBetween>
          </Box>
        </FlexBetween>
      </FlexBetween>

      <Divider color={pal.warning.dark} sx={{ "background-color": pal.warning.dark }} />

      {/* -------- SECOND ROW -------- */}
      <Box p="1rem 0">
        <FlexBetween mb="0.5rem">
          <Typography
            // variant="personal"
            fontSize="1.0rem"
            color={pal.neutral.dark}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;FastAPI is now the fastest Full Stack backend framework.
            &nbsp;&nbsp;The Python ecosystem is the very best foundation for almost any kind of distributed application.
            &nbsp;&nbsp;This is my new state-of-the-art Python Full Stack: The Bedrock Stack.</Typography>
        </FlexBetween>
      </Box>

      <Divider color={pal.warning.dark} sx={{ "background-color": pal.warning.dark }} />

      {/* -------- THIRD ROW -------- */}
      <Box p="1.0rem 0.0rem 0.0rem 0.0rem">
        <Box display="flex" flexWrap="wrap" gap="0.5rem" mb="0.0rem" mt="0.5rem"
             sx={{
               "& .MuiChip-label": { fontSize: ".85rem", fontWeight: "bold" }, // Slightly bigger chip label
             }}>
          <TechChip techName={"Python Framework: FastAPI"} techColor={pal.warning.main} />
          <TechChip techName={"Python User Mgmt: FastAPI-Users"} techColor={pal.warning.main} />
          <TechChip techName={"Python ORM: SQLAlchemy"} techColor={pal.warning.main} />
          <TechChip techName={"Python ORM Alt.: SQLModel"} techColor={pal.warning.main} />
          <TechChip techName={"DB Migrations: Alembic"} techColor={pal.warning.main} />
          <TechChip techName={"Validation: Pydantic"} techColor={pal.warning.main} />
          <TechChip techName={"Backend/API Testing: Pytest"} techColor={pal.warning.main} />
          <TechChip techName={"PostgreSQL DB"} techColor={pal.error.main} />
          <TechChip techName={"Postgres Admin IDE: PgAdmin"} techColor={pal.error.main} />
          <TechChip techName={"React, React Router"} techColor={pal.primary.main} />
          <TechChip techName={"React: Typescript, Hooks, Vite"} techColor={pal.primary.main} />
          <TechChip techName={"ChakraUI, Dark/Light (* soon)"} techColor={pal.primary.main} />
          <TechChip techName={"Auth/Auth: JWT (JSON Web Token)"} techColor={pal.primary.main} />
          <TechChip techName={"UI, Integration Testing: Playwright"} techColor={pal.primary.main} />
          <TechChip techName={"Email Pass. Recovery"} techColor={pal.primary.main} />
          <TechChip techName={"Build Auto.: BASH/ZSH"} techColor={pal.secondary.dark} />
          <TechChip techName={"Deploy Auto.: BASH/ZSH"} techColor={pal.secondary.dark} />
          <TechChip techName={"CI/CD-Ready"} techColor={pal.secondary.dark} />
          <TechChip techName={"NGINX A+ SSL Security"} techColor={pal.success.main} />
          <TechChip techName={"NGINX Acceleration (Reverse Proxy)"} techColor={pal.success.main} />
          <TechChip techName={"NGINX Advanced Multi-Domain"} techColor={pal.success.main} />
          {/*<TechChip techName={"Alt. LB/Acceleration: Traefik"} techColor={pal.success.main} />*/}
          <TechChip techName={"ENV Config, Secret Security"} techColor={pal.neutral.main} />
          <TechChip techName={"4-5 Docker Container Stack"} techColor={pal.neutral.main} />
          <TechChip techName={"Docker Compose - Advanced"} techColor={pal.neutral.main} />
          <TechChip techName={"DataDog-Ready"} techColor={pal.neutral.main} />
          <TechChip techName={"Cloud-Ready (AWS+)"} techColor={pal.neutral.main} />
        </Box>

      </Box>
    </WidgetWrapper>
  );
};

export default ProjectBedrockWidget;


// ************************************************************************************
// https://github.com/fastapi/full-stack-fastapi-template

