import React from "react";
import { useSelector } from "react-redux";
import { ScrollToTopOnLoad as LoadFresh } from "../../utils";
// import * as Config from "../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  Box,
  useMediaQuery,
} from "@mui/material";
import { Helmet } from "react-helmet";
import NavbarAuth from "../../scenes/navbarAuth";
import Footer from "../footer";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import UserWidget from "../../scenes/widgets/UserWidget";
import MyPostWidget from "../../scenes/widgets/MyPostWidget";
import PostsWidget from "../../scenes/widgets/PostsWidget";
import RTX5090AdvertWidget from "../widgets/ads/RTX5090AdvertWidget";
import FriendListWidget from "../../scenes/widgets/FriendListWidget";


const MatrixPage = () => {
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const { _id, picturePath } = useSelector((state) => state.user);

  return (
    <div>
      <Helmet>
        <title>SmartMetal.ai Matrix - Social Network for Artificial Intelligence Engineers & Creators</title>
        <meta name="description" content="SmartMetal Matrix - Social Network for Artificial Intelligence Engineers & Creators"/>
      </Helmet>
      <Box>
        <NavbarAuth/>
        <Box
          width="100%"
          padding="2rem 4%"
          display={isNonMobileScreens ? "flex" : "block"}
          gap="1.5rem"
          justifyContent="space-between"
        >
          <Box flexBasis={isNonMobileScreens ? "26%" : undefined}>
            <UserWidget userId={_id} picturePath={picturePath}/>
          </Box>
          <Box
            flexBasis={isNonMobileScreens ? "50%" : undefined}
            mt={isNonMobileScreens ? undefined : "2rem"}
          >
            <MyPostWidget picturePath={picturePath}/>
            <PostsWidget userId={_id}/>
          </Box>
          {isNonMobileScreens && (
            <Box flexBasis="26%">
              <RTX5090AdvertWidget/>
              <Box m="2rem 0"/>
              <FriendListWidget userId={_id}/>
            </Box>
          )}
        </Box>
        <Box
          display="block"
          width="100%"
          gap="1.5rem"
          padding="0.0rem"
        >
          <Footer />
        </Box>
      </Box>
      <LoadFresh />
    </div>
    );
};

export default MatrixPage;

