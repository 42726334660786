
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setMode } from "../../state";
// import * as Config from "../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import {
  DarkMode,
  LightMode,
  Menu,
  Close,
} from "@mui/icons-material";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import FlexBetween from "../../components/FlexBetween";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */


const NavbarPublic = () => {
  const [isMobileMenuToggled, setIsMobileMenuToggled] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");

  const theme = useTheme();
  const pal = theme.palette
  const dark = theme.paletteOld.neutral.dark;


  const handleLinkClick = (path) => {
    navigate(path);
    if (!isNonMobileScreens) {
      setIsMobileMenuToggled(false); // Close the mobile menu after clicking a link
    }
  };


  return (
    <FlexBetween
      padding="1rem 4%"
      backgroundColor={pal.bg.lift}
      sx={{
        borderBottom: '1px solid',
        borderColor: pal.primary.main,
      }}
    >
      <FlexBetween gap="1.75rem">
        <Typography
          variant="mainlogo"
          fontWeight="bold"
          fontSize="36px"
          color="primary"
          onClick={() => navigate("/")}
          sx={{
            "&:hover": {
              color: pal.primary.less_ink,
              cursor: "pointer",
            },
          }}
        >
          SmartMetal.ai
        </Typography>
      </FlexBetween>

      {/* DESKTOP NAV */}
      {isNonMobileScreens ? (
        <FlexBetween
          gap="2rem"
          justifyContent="space-between"
          width="60%"
          sx={{ textAlign: "center" }}
        >
          <Typography
            fontWeight="600"
            fontSize="1.1rem"
            fontStyle="italic"
            color={pal.warning.main}
            onClick={() => handleLinkClick("/matrix")}
            sx={{
              mt: "0.4rem",
              pt: "0.2rem",
              "&:hover": {
                cursor: "pointer",
                color: pal.primary.less_ink,
              },
            }}
          >
            Matrix - AI/ML Network
          </Typography>
          <Typography
            fontWeight="600"
            fontSize="0.9rem"
            color="primary"
            onClick={() => handleLinkClick("/forge")}
            sx={{
              mt: "0.4rem",
              "&:hover": {
                cursor: "pointer",
                color: pal.primary.less_ink,
              },
            }}
          >
            Forge Generator
          </Typography>
          <Typography
            fontWeight="600"
            fontSize="0.9rem"
            color="primary"
            onClick={() => handleLinkClick("/dream")}
            sx={{
              mt: "0.4rem",
              "&:hover": {
                cursor: "pointer",
                color: pal.primary.less_ink,
              },
            }}
          >
            Dream-o-matic
          </Typography>
          <Typography
            fontWeight="600"
            fontSize="0.9rem"
            color="primary"
            onClick={() => handleLinkClick("/gallery")}
            sx={{
              mt: "0.4rem",
              "&:hover": {
                cursor: "pointer",
                color: pal.primary.less_ink,
              },
            }}
          >
            Gen AI Gallery
          </Typography>
          <Typography
            fontWeight="600"
            fontSize="0.9rem"
            color="primary"
            onClick={() => handleLinkClick("/dev")}
            sx={{
              mt: "0.4rem",
              "&:hover": {
                cursor: "pointer",
                color: pal.primary.less_ink,
              },
            }}
          >
            Developer Resources
          </Typography>

          <IconButton onClick={() => dispatch(setMode())}>
            {theme.palette.mode === "dark" ? (
              <LightMode sx={{ color: dark, fontSize: "25px" }} />
            ) : (
              <DarkMode sx={{ fontSize: "25px" }} />
            )}
          </IconButton>
        </FlexBetween>
      ) : (
        <IconButton
          onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
        >
          <Menu />
        </IconButton>
      )}

      {/* MOBILE NAV */}
      {!isNonMobileScreens && isMobileMenuToggled && (
        <Box
          position="fixed"
          right="0"
          bottom="0"
          height="100%"
          zIndex="10"
          maxWidth="500px"
          minWidth="300px"
          backgroundColor={pal.bg.bg}
        >
          {/* CLOSE ICON */}
          <Box display="flex" justifyContent="flex-end" p="1rem">
            <IconButton
              onClick={() => setIsMobileMenuToggled(!isMobileMenuToggled)}
            >
              <Close />
            </IconButton>
          </Box>

          {/* MENU ITEMS */}
          <FlexBetween
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="3rem"
          >
            <Typography
              variant="h5"
              color="primary"
              onClick={() => handleLinkClick("/matrix")}
              sx={{
                "&:hover": {
                  cursor: "pointer",
                  color: pal.primary.less_ink,
                },
              }}
            >
              Matrix - AI/ML Network
            </Typography>
            <Typography
              variant="h5"
              color="primary"
              onClick={() => handleLinkClick("/forge")}
              sx={{
                "&:hover": {
                  cursor: "pointer",
                  color: pal.primary.less_ink,
                },
              }}
            >
              Forge Generator
            </Typography>
            <Typography
              variant="h5"
              color="primary"
              onClick={() => handleLinkClick("/dream")}
              sx={{
                "&:hover": {
                  cursor: "pointer",
                  color: pal.primary.less_ink,
                },
              }}
            >
              Dream-o-matic
            </Typography>
            <Typography
              variant="h5"
              color="primary"
              onClick={() => handleLinkClick("/gallery")}
              sx={{
                "&:hover": {
                  cursor: "pointer",
                  color: pal.primary.less_ink,
                },
              }}
            >
              Gen AI Gallery
            </Typography>
            <Typography
              fontSize="16px"
              color="primary"
              onClick={() => handleLinkClick("/dev")}
              sx={{
                mt: "0.4rem",
                "&:hover": {
                  cursor: "pointer",
                  color: pal.primary.less_ink,
                },
              }}
            >
              Developer Resources
            </Typography>

            <IconButton
              onClick={() => dispatch(setMode())}
              sx={{ fontSize: "25px" }}
            >
              {theme.palette.mode === "dark" ? (
                <LightMode sx={{ color: dark, fontSize: "25px" }} />
              ) : (
                <DarkMode sx={{ fontSize: "25px" }} />
              )}
            </IconButton>
          </FlexBetween>
        </Box>
      )}
    </FlexBetween>
  );
};

export default NavbarPublic;
