
import { useNavigate } from "react-router-dom";
// import * as Config  from "../../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Divider,
  IconButton,
} from "@mui/material";
import { ZoomIn } from "@mui/icons-material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import FlexBetween from "../../../components/FlexBetween";
import WidgetWrapper from "../../../components/WidgetWrapper";
import ProjectImage from "../../../components/ProjectImage";
import TechChip from "../../../components/TechChip";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */


const ProjectSolarWidget = ({
    smallMode = false,
  }) => {
  const theme = useTheme();
  const pal = theme.palette;
  // const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  const isNonTinyScreens = useMediaQuery("(min-width: 800px)");
  const navigate = useNavigate();

  const imageFileProject = "thm/solar-design-1920x1399-thumb.png";
  const projectUrl = "/project/solar";
  const imageAlt = "SunPower Machine-Learning Solar Design App (screenshot)";


  return (
    <WidgetWrapper>
      {/* -------- FIRST ROW -------- */}
      <FlexBetween
        gap="1.5rem"
        pb="1.1rem"
      >
        <FlexBetween gap="1.5rem">
          { isNonTinyScreens && (
            <Box
              sx={{
                display: "block",
                // display: "flex",
                // flexDirection: "column",
                // justifyContent: "flex-start",
                // gap: "1rem",
                // flexBasis: "100%",
                // flexGrow: 1,
              }}
            >
              <ProjectImage
                sx={{
                  // display: "flex",
                  // flexDirection: "column",
                  // justifyContent: "flex-start",
                  // gap: "1rem",
                  // flexBasis: "100%",
                  // flexBasis: "max-content",
                  // flexGrow: 1,
                }}
                smallMode={smallMode}
                imageFileProject={imageFileProject}
                imageAlt={imageAlt}
                imageLinkUrl={projectUrl}
              />
            </Box>
          )}
          <Box>
            <FlexBetween gap="0.5rem">
              <Typography
                variant="h4"
                color={pal.neutral.dark}
                fontWeight="600"
                marginBottom="0.75rem"
              >
                SunPower Machine-Learning Solar Design App
              </Typography>
              {/* -------- ZOOM NAVIGATE - ONLY IN SMALL MODE -------- */}
              { smallMode && (
                  <IconButton
                    onClick={() => navigate(projectUrl)}
                  >
                    <ZoomIn
                      sx={{
                        fontSize: "1.8rem",
                        color: pal.primary.main,
                      }}
                    />
                  </IconButton>
                )}
            </FlexBetween>
            {/* -------- FIRST ROW - SUB-HEADING & BULLETS -------- */}
            <Typography
              variant="h5"
              color={pal.neutral.dark}
              fontWeight="500"
              marginBottom="0.75rem"
            >
              Full Time Role as Lead Developer and Dev Team Manager, Full Stack Apps, 2018-2021
            </Typography>
            <FlexBetween gap="1.0rem">
              <List sx={{ pt: 0, pb: 0, listStyleType: 'disc', color: pal.neutral.main }}>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >
                  Mentored talented developers for excellent coding and git merge/SCM practices
                </ListItem>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >
                  Fully automated and modernized the CI/CD build/deploy pipeline and SDLC
                </ListItem>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >
                  Leveraged decades of automation experience using Python, Ansible, BASH, Jenkins
                </ListItem>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >
                  Integrated CircleCI, DataDog for very modern automation and instrumentation
                </ListItem>
              </List>
              <List sx={{ pt: 0, pb: 0, listStyleType: 'disc', color: pal.neutral.main }}>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >
                  Enhanced up/down-stream APIs with Swagger/OpenAPI/OAS
                </ListItem>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >
                  Repaired a corrupted main app repository and migrated ML training data to Git LFS
                </ListItem>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >
                  Enhanced logging system and integrated with DataDog Application Intelligence
                </ListItem>
                <ListItem sx={{ pt: '0', pb: '0', ml: '1.5rem', pl: '0rem', display: 'list-item' }}
                >
                  Worked daily with developers in Ukraine, Poland on any 24x7 schedule required
                </ListItem>
              </List>
            </FlexBetween>
          </Box>
        </FlexBetween>
      </FlexBetween>

      <Divider color={pal.warning.dark} sx={{ "background-color": pal.warning.dark }} />

      {/* -------- SECOND ROW -------- */}
      <Box p="1rem 0">
        <FlexBetween mb="0.5rem">
          <Typography
            // variant="personal"
            fontSize="1.0rem"
            color={pal.neutral.dark}
          >
            &nbsp;&nbsp;&nbsp;&nbsp;In 2019 and 2020 our team achieved 2 patents for
            "SunPower Design Studio".
            &nbsp;&nbsp;In my role as Lead Developer and Manager of a remote team of top-tier developers from
            Europe and the US,
            &nbsp;&nbsp;I transformed operations, software releases, CI/CD, automation, code quality and
            SCM into a great example of best-practices.
            &nbsp;&nbsp;For mission-critical ML model training, I improved data management procedures and
            implemented source control for tens of thousands of annotated solar design images using Git LFS.
            &nbsp;&nbsp;I reviewed pull requests, approved merges, prepared release images and managed the SDLC.
            &nbsp;&nbsp;My own development role focused on APIs for both hosted and consumed APIs,
            stack container design, host GPU/driver interfacing, logging and DataDog integration.
            &nbsp;&nbsp;I led the successful build-out and automation of our dev, stage and prod environments
            into a modern CI/CD pipeline.
          </Typography>
        </FlexBetween>
      </Box>

      <Divider color={pal.warning.dark} sx={{ "background-color": pal.warning.dark }} />

      {/* -------- THIRD ROW -------- */}
      <Box p="1.0rem 0.0rem 0.0rem 0.0rem">
        <Box display="flex" flexWrap="wrap" gap="0.5rem" mb="0.0rem" mt="0.5rem"
             sx={{
               "& .MuiChip-label": { fontSize: ".85rem", fontWeight: "bold" }, // Slightly bigger chip label
             }}>
          <TechChip techName={"Satellite Imagery: Google APIs"} techColor={pal.secondary.dark} />
          <TechChip techName={"LIDAR Data: Google APIs"} techColor={pal.secondary.dark} />
          <TechChip techName={"Python Framework: Flask"} techColor={pal.warning.main} />
          <TechChip techName={"Python ORM: SQLAlchemy"} techColor={pal.warning.main} />
          <TechChip techName={"REST API: Flask-RESTful"} techColor={pal.warning.main} />
          <TechChip techName={"Enhanced APIs: Swagger/OpenAPI (OAS)"} techColor={pal.warning.main} />
          <TechChip techName={"Validation: Marshmallow"} techColor={pal.warning.main} />
          <TechChip techName={"Async. Task Queue: Celery"} techColor={pal.warning.main} />
          <TechChip techName={"Pytest"} techColor={pal.warning.main} />
          <TechChip techName={"DB Migrations: Alembic"} techColor={pal.warning.main} />
          <TechChip techName={"PostgreSQL DB"} techColor={pal.error.main} />
          <TechChip techName={"Postgres Admin IDE: PgAdmin"} techColor={pal.error.main} />
          <TechChip techName={"Redis Cache"} techColor={pal.error.main} />
          <TechChip techName={"PyTorch"} techColor={pal.success.main} />
          <TechChip techName={"TensorFlow"} techColor={pal.success.main} />
          <TechChip techName={"NVIDIA CUDA"} techColor={pal.success.main} />
          <TechChip techName={"React"} techColor={pal.primary.main} />
          <TechChip techName={"Typescript, Hooks, Vite"} techColor={pal.primary.main} />
          <TechChip techName={"Auth/Auth: JWT (JSON Web Token)"} techColor={pal.primary.main} />
          <TechChip techName={"Build Auto.: Jenkins, CircleCI"} techColor={pal.secondary.dark} />
          <TechChip techName={"Automation.: Ansible, BASH"} techColor={pal.secondary.dark} />
          <TechChip techName={"Deploy Auto.: Ansible BASH"} techColor={pal.secondary.dark} />
          <TechChip techName={"NGINX"} techColor={pal.success.main} />
          <TechChip techName={"5 Docker Container Stack"} techColor={pal.neutral.main} />
          <TechChip techName={"Docker Compose for Local Dev"} techColor={pal.neutral.main} />
          <TechChip techName={"Kubernetes (K8S) Pods/Clusters"} techColor={pal.neutral.main} />
          <TechChip techName={"DataDog Integration"} techColor={pal.neutral.main} />
          <TechChip techName={"Google Cloud Platform (GCP) CUDA GPU VMs"} techColor={pal.neutral.main} />
          <TechChip techName={"CI/CD Builds: Jenkins, CircleCI"} techColor={pal.neutral.main} />
          <TechChip techName={"CI/CD & Automation: Ansible, BASH"} techColor={pal.secondary.dark} />
        </Box>

      </Box>
    </WidgetWrapper>
  );
};

export default ProjectSolarWidget;


// <a href="https://patents.justia.com/patent/11392730" target="_blank" rel="noreferrer">
//   PATENT # 11392730 - SUNPOWER CORPORATION - Generating a Photovoltaic System Design for a Building, 2022 - Lead Developer: James Mannix
// </a>
// <br />
// <br />
// <a href="https://patents.justia.com/patent/10754999" target="_blank" rel="noreferrer">
//   PATENT # 10754999 - SUNPOWER CORPORATION - Generating a Photovoltaic System Design for a Building, 2020 - Lead Developer: James Mannix
// </a>
// <br />
// <br />
// <a href="https://patents.justia.com/patent/20200364382" target="_blank" rel="noreferrer">
//   PATENT PUBLICATION # 20200364382 - SUNPOWER CORPORATION - Generating a Photovoltaic System Design for a Building, 2020 - Lead Developer: James Mannix
// </a>
// <br />
// <br />
// <a href="https://patents.justia.com/inventor/james-mannix" target="_blank" rel="noreferrer">
//   PATENTS & FILINGS BY INVENTOR JAMES MANNIX #'S 11392730, 20200364382, 10754999
// </a>
// <br />
// <br />

