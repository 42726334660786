import React, {
  useEffect,
  // useState,
} from "react";
// import { useSelector } from "react-redux";
// import { useParams } from "react-router-dom";
import { ScrollToTopOnLoad as LoadFresh } from "../../utils";
import * as Config from "../../config";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import {
  Box,
  Typography,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogContent,
  IconButton,
  // CircularProgress,
} from "@mui/material";
import { Helmet } from "react-helmet";
import NavbarPublic from "../navbarPublic";
import Footer from "../footer";
/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */
import WidgetWrapper from "../../components/WidgetWrapper";
import CloseIcon from "@mui/icons-material/Close";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";


const GalleryPage = () => {
  // const [user, setUser] = useState(null);
  // const { userId } = useParams();
  // const token = useSelector((state) => state.token);
  const isNonMobileScreens = useMediaQuery("(min-width:1000px)");
  const { palette } = useTheme();
  // FOR POPUP - BUT I DON'T SEE WHY I NEED THESE - MAYBE IT's JUST HOW THE POPUP GETS ITS ARGS:
  const [open, setOpen] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState('');

  // const getUser = async () => {
  //   const response = await fetch(`${Config.expressUri}/users/${userId}`, {
  //     method: "GET",
  //     // headers: { Authorization: `Bearer ${token}` },
  //   });
  //   const data = await response.json();
  //   setUser(data);
  // };

  useEffect(() => {
    // getUser();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // if (!user) return null;
  // if (!user) return <CircularProgress />;  // For investigating/understanding/troubleshooting when I was taking auth off some pages


  const ImagePopup = ({
      imageUrl,
      open,
      onClose,
    }) => {
    return (
      <Dialog
        open={open}
        onClose={onClose}
        fullScreen
        PaperProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.9)', // Dark background behind image
            boxShadow: 'none',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '2%',
          },
        }}
      >
        <DialogContent
          style={{
            height: '100vh', // Ensure the popup takes up the entire viewport
            overflow: 'hidden', // Hide any overflow from image or background
          }}
        >
          <img
            onClick={onClose}
            src={imageUrl}
            alt="Generative AI"
            style={{
              cursor: "pointer",
              maxWidth: '100%',
              maxHeight: '100%',
              objectFit: 'contain', // Prevents scrollbars and resizes image to fit
              borderRadius: '0.75rem',
              border: '1px solid #808080',
              boxShadow: '0px 8px 12px rgba(0, 0, 0, 0.8)',
            }}
          />
          <IconButton
            onClick={onClose}
            style={{
              margin: '.5rem',
              position: 'absolute',
              color: 'white',
              border: '1px solid #808080',
              zIndex: 10,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogContent>
      </Dialog>
    );
  };


  const handleOpen = (url) => {
    setImageUrl(url);
    setOpen(true);
  };


  const handleClose = () => {
    setOpen(false);
  };


  const galleryPieces = [
    {
      img: 'thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_07574ce8-f596-426d-ab20-4b0156ed5f30.png',
      thumb: 'thm/thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_07574ce8-f596-426d-ab20-4b0156ed5f30-thumb.png',
      title: 'Midjourney 3',
      author: '@jimmygizmo',
    },
    {
      img: 'FLUXfp8-2025-01-10-135101__0.png',
      thumb: 'thm/FLUXfp8-2025-01-10-135101__0-thumb.png',
      title: 'FLUX1 Dev fp8',
      author: '@jimmygizmo',
    },
    {
      img: 'thedreambox_a_glossy_photo-realistic_3d_rendering_of_a_hideous__4bd45f93-b781-4894-8eaa-0104b512e0d3.png',
      thumb: 'thm/thedreambox_a_glossy_photo-realistic_3d_rendering_of_a_hideous__4bd45f93-b781-4894-8eaa-0104b512e0d3-thumb.png',
      title: 'Midjourney 3',
      author: '@jimmygizmo',
    },
    {
      img: 'thedreambox_a_glossy_Salvador_Dali_painting_of_a_society_of_ele_077e2401-78f9-4e56-a195-9bca5b684dae.png',
      thumb: 'thm/thedreambox_a_glossy_Salvador_Dali_painting_of_a_society_of_ele_077e2401-78f9-4e56-a195-9bca5b684dae-thumb.png',
      title: 'Midjourney 3',
      author: '@jimmygizmo',
    },
    {
      img: 'FLUXfp8-2024-12-28-005622__0.png',
      thumb: 'thm/FLUXfp8-2024-12-28-005622__0-thumb.png',
      title: 'FLUX1 Dev fp8',
      author: '@jimmygizmo',
    },
    {
      img: 'thedreambox_a_glossy_Salvador_Dali_painting_of_a_glossy_Pieter__92fea657-2072-470d-91d2-6065481d8b5d.png',
      thumb: 'thm/thedreambox_a_glossy_Salvador_Dali_painting_of_a_glossy_Pieter__92fea657-2072-470d-91d2-6065481d8b5d-thumb.png',
      title: 'Midjourney 3',
      author: '@jimmygizmo',
    },
    {
      img: 'thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_3b3977f8-e751-4982-8727-c7b7b17489a2.png',
      thumb: 'thm/thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_3b3977f8-e751-4982-8727-c7b7b17489a2-thumb.png',
      title: 'Midjourney 3',
      author: '@jimmygizmo',
    },
    {
      img: 'thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_baae72e6-67ac-4204-a4ab-9beb5a04d401.png',
      thumb: 'thm/thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_baae72e6-67ac-4204-a4ab-9beb5a04d401-thumb.png',
      title: 'Midjourney 3',
      author: '@jimmygizmo',
    },
    {
      img: 'thedreambox_a_clumpy_pile_of_oozing_opalescent_glossy_melting_g_72b5e0d4-4d4b-4729-9d26-a3599ac246a5.png',
      thumb: 'thm/thedreambox_a_clumpy_pile_of_oozing_opalescent_glossy_melting_g_72b5e0d4-4d4b-4729-9d26-a3599ac246a5-thumb.png',
      title: 'Midjourney 3',
      author: '@jimmygizmo',
    },
    {
      img: 'thedreambox_a_salvador_dali_painting_of_an_ed_roth_painting_6a6c688a-8137-478a-b54f-68c8ddc52ec1.png',
      thumb: 'thm/thedreambox_a_salvador_dali_painting_of_an_ed_roth_painting_6a6c688a-8137-478a-b54f-68c8ddc52ec1-thumb.png',
      title: 'Midjourney 3',
      author: '@jimmygizmo',
    },
    {
      img: 'DALLbizanmals.png',
      thumb: 'thm/DALLbizanmals-thumb.png',
      title: 'DALL-E 2',
      author: '@jimmygizmo',
    },
    {
      img: 'thedreambox_a_35_mm_photograph_of_an_icela_d0412e7b-c859-46a4-a163-4577b561706e.png',
      thumb: 'thm/thedreambox_a_35_mm_photograph_of_an_icela_d0412e7b-c859-46a4-a163-4577b561706e-thumb.png',
      title: 'Midjourney 3',
      author: '@jimmygizmo',
    },
    {
      img: 'SD35-ComfyUI_00082_.png',
      thumb: 'thm/SD35-ComfyUI_00082_-thumb.png',
      title: 'Stable Diffusion 3.5',
      author: '@jimmygizmo',
    },
    {
      img: 'thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_898bed7d-fa85-4441-bfc2-e6190e4b34a1.png',
      thumb: 'thm/thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_898bed7d-fa85-4441-bfc2-e6190e4b34a1-thumb.png',
      title: 'Midjourney 3',
      author: '@jimmygizmo',
    },
    {
      img: 'DALLantswaspss.png',
      thumb: 'thm/DALLantswaspss-thumb.png',
      title: 'DALL-E 2',
      author: '@jimmygizmo',
    },
    {
      img: 'FLUXfp8-2024-12-28-004615__0.png',
      thumb: 'thm/FLUXfp8-2024-12-28-004615__0-thumb.png',
      title: 'FLUX1 Dev fp8',
      author: '@jimmygizmo',
    },
    {
      img: 'FLUXfp8-2025-01-10-143306__0.png',
      thumb: 'thm/FLUXfp8-2025-01-10-143306__0-thumb.png',
      title: 'FLUX1 Dev fp8',
      author: '@jimmygizmo',
    },
    {
      img: 'DALLopalglitterdemon.png',
      thumb: 'thm/DALLopalglitterdemon-thumb.png',
      title: 'DALL-E 2',
      author: '@jimmygizmo',
    },
    {
      img: 'SMLogo003.png',
      thumb: 'thm/SMLogo003-thumb.png',
      title: 'FLUX1 Dev fp8',
      author: '@jimmygizmo',
    },
    {
      img: 'thedreambox_a_glossy_salvador_dali_painting_of_dozens_of_stray__b158ee0e-23a2-4a22-ab35-7896e37006d7.png',
      thumb: 'thm/thedreambox_a_glossy_salvador_dali_painting_of_dozens_of_stray__b158ee0e-23a2-4a22-ab35-7896e37006d7-thumb.png',
      title: 'Midjourney 3',
      author: '@jimmygizmo',
    },
    {
      img: 'DALLovercrowd.png',
      thumb: 'thm/DALLovercrowd-thumb.png',
      title: 'DALL-E 2',
      author: '@jimmygizmo',
    },
    {
      img: 'thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_ef505be9-f50b-430f-80ab-8d77d81faea6.png',
      thumb: 'thm/thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_ef505be9-f50b-430f-80ab-8d77d81faea6-thumb.png',
      title: 'Midjourney 3',
      author: '@jimmygizmo',
    },
    {
      img: 'thedreambox_a_glossy_Salvador_Dali_painting_of_a_glossy_Pieter__8257b787-2e22-481f-95d2-907834bcee43.png',
      thumb: 'thm/thedreambox_a_glossy_Salvador_Dali_painting_of_a_glossy_Pieter__8257b787-2e22-481f-95d2-907834bcee43-thumb.png',
      title: 'Midjourney 3',
      author: '@jimmygizmo',
    },
    {
      img: 'thedreambox_a_sentient_machine_thinking_about_the_definition_of_a0f993a7-8563-42e5-9c19-da045a46b51f.png',
      thumb: 'thm/thedreambox_a_sentient_machine_thinking_about_the_definition_of_a0f993a7-8563-42e5-9c19-da045a46b51f-thumb.png',
      title: 'Midjourney 3',
      author: '@jimmygizmo',
    },
    {
      img: 'FLUXfp8-2024-12-28-011334__0.png',
      thumb: 'thm/FLUXfp8-2024-12-28-011334__0-thumb.png',
      title: 'FLUX1 Dev fp8',
      author: '@jimmygizmo',
    },
    {
      img: 'thedreambox_a_glossy_and_bright_Hieronymus_Bosch_painting_of_a__a11ba836-8c55-4ce3-a075-e5d02a33284c.png',
      thumb: 'thm/thedreambox_a_glossy_and_bright_Hieronymus_Bosch_painting_of_a__a11ba836-8c55-4ce3-a075-e5d02a33284c-thumb.png',
      title: 'Midjourney 3',
      author: '@jimmygizmo',
    },
    {
      img: 'thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_943cb0a1-63cc-4f38-a3e8-e6b6c2dfcf29.png',
      thumb: 'thm/thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_943cb0a1-63cc-4f38-a3e8-e6b6c2dfcf29-thumb.png',
      title: 'Midjourney 3',
      author: '@jimmygizmo',
    },
    {
      img: 'thedreambox_a_glossy_salvador_dali_painting_1950s_couple_mowing_b36ff62f-6a44-4c95-abdf-e3a8f3e21d24.png',
      thumb: 'thm/thedreambox_a_glossy_salvador_dali_painting_1950s_couple_mowing_b36ff62f-6a44-4c95-abdf-e3a8f3e21d24-thumb.png',
      title: 'Midjourney 3',
      author: '@jimmygizmo',
    },
    {
      img: 'thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_9ec602de-efc1-417d-8e32-b66bd19163d0.png',
      thumb: 'thm/thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_9ec602de-efc1-417d-8e32-b66bd19163d0-thumb.png',
      title: 'Midjourney 3',
      author: '@jimmygizmo',
    },
    {
      img: 'thedreambox_a_cinematic_photograph_from_an_architectural_digest_161f68a3-4c0e-48fe-bd3a-d00d6b68e6ca.png',
      thumb: 'thm/thedreambox_a_cinematic_photograph_from_an_architectural_digest_161f68a3-4c0e-48fe-bd3a-d00d6b68e6ca-thumb.png',
      title: 'Midjourney 3',
      author: '@jimmygizmo',
    },
    {
      img: 'thedreambox_a_glossy_salvador_dali_painting_of_a_fat-headded_de_2c3c4dc6-b6dd-4be2-b29e-351098d01d91.png',
      thumb: 'thm/thedreambox_a_glossy_salvador_dali_painting_of_a_fat-headded_de_2c3c4dc6-b6dd-4be2-b29e-351098d01d91-thumb.png',
      title: 'Midjourney 3',
      author: '@jimmygizmo',
    },
    {
      img: 'thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_2ca1b9a6-02ae-4035-be12-659671ff3e4b.png',
      thumb: 'thm/thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_2ca1b9a6-02ae-4035-be12-659671ff3e4b-thumb.png',
      title: 'Midjourney 3',
      author: '@jimmygizmo',
    },
    {
      img: 'thedreambox_a_glossy_salvador_dali_painting_of_a_snake_with_the_b59f66f9-f469-49f5-972b-0b33abc802fc.png',
      thumb: 'thm/thedreambox_a_glossy_salvador_dali_painting_of_a_snake_with_the_b59f66f9-f469-49f5-972b-0b33abc802fc-thumb.png',
      title: 'Midjourney 3',
      author: '@jimmygizmo',
    },
    {
      img: 'thedreambox_a_glossy_salvador_dali_painting_of_two_young_morbid_23211fa8-8e5a-4e08-bad4-1c948bae97d2.png',
      thumb: 'thm/thedreambox_a_glossy_salvador_dali_painting_of_two_young_morbid_23211fa8-8e5a-4e08-bad4-1c948bae97d2-thumb.png',
      title: 'Midjourney 3',
      author: '@jimmygizmo',
    },
    {
      img: 'thedreambox_a_glossy_salvarod_dali_painting_of_a_glossy_ed_roth_4869c312-ab40-43b0-80b8-12bb94211dd2.png',
      thumb: 'thm/thedreambox_a_glossy_salvarod_dali_painting_of_a_glossy_ed_roth_4869c312-ab40-43b0-80b8-12bb94211dd2-thumb.png',
      title: 'Midjourney 3',
      author: '@jimmygizmo',
    },
    {
      img: 'thedreambox_a_salvarod_dali_painting_of_100_gargoyles_which_loo_6e26cf39-a38e-442c-bf39-676de768ddb4.png',
      thumb: 'thm/thedreambox_a_salvarod_dali_painting_of_100_gargoyles_which_loo_6e26cf39-a38e-442c-bf39-676de768ddb4-thumb.png',
      title: 'Midjourney 3',
      author: '@jimmygizmo',
    },
    {
      img: 'DALLfrog.png',
      thumb: 'thm/DALLfrog-thumb.png',
      title: 'DALL-E 2',
      author: '@jimmygizmo',
    },
    {
      img: 'DALLopalskull.png',
      thumb: 'thm/DALLopalskull-thumb.png',
      title: 'DALL-E 2',
      author: '@jimmygizmo',
    },
    {
      img: 'FLUXfp8-2024-12-27-214621__0.png',
      thumb: 'thm/FLUXfp8-2024-12-27-214621__0-thumb.png',
      title: 'FLUX1 Dev fp8',
      author: '@jimmygizmo',
    },
    {
      img: 'thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_4c3f0fb4-961e-463c-86d3-ebff2cb20fb0.png',
      thumb: 'thm/thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_4c3f0fb4-961e-463c-86d3-ebff2cb20fb0-thumb.png',
      title: 'Midjourney 3',
      author: '@jimmygizmo',
    },
    {
      img: 'FLUXfp8-2024-12-28-004353__0.png',
      thumb: 'thm/FLUXfp8-2024-12-28-004353__0-thumb.png',
      title: 'FLUX1 Dev fp8',
      author: '@jimmygizmo',
    },
    {
      img: 'FLUXfp8-2025-01-10-142515__0.png',
      thumb: 'thm/FLUXfp8-2025-01-10-142515__0-thumb.png',
      title: 'FLUX1 Dev fp8',
      author: '@jimmygizmo',
    },
    {
      img: 'DALLmoscow.png',
      thumb: 'thm/DALLmoscow-thumb.png',
      title: 'DALL-E 2',
      author: '@jimmygizmo',
    },
    {
      img: 'thedreambox_a_glossy_hyper-real_ed_roth_painting_of_a_frail-loo_d14dc5c8-fb8f-4e11-bae8-a0bcc6c14194.png',
      thumb: 'thm/thedreambox_a_glossy_hyper-real_ed_roth_painting_of_a_frail-loo_d14dc5c8-fb8f-4e11-bae8-a0bcc6c14194-thumb.png',
      title: 'Midjourney 3',
      author: '@jimmygizmo',
    },
    {
      img: 'DALLcityviolence.png',
      thumb: 'thm/DALLcityviolence-thumb.png',
      title: 'DALL-E 2',
      author: '@jimmygizmo',
    },
    {
      img: 'DALLfordgalaxygrass.png',
      thumb: 'thm/DALLfordgalaxygrass-thumb.png',
      title: 'DALL-E 2',
      author: '@jimmygizmo',
    },
    {
      img: 'thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_eb5a3bd0-eb9b-4971-9c27-cfc4785ddd66.png',
      thumb: 'thm/thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_eb5a3bd0-eb9b-4971-9c27-cfc4785ddd66-thumb.png',
      title: 'Midjourney 3',
      author: '@jimmygizmo',
    },
    {
      img: 'thedreambox_a_salvador_dali_painting_of_an_ed_roth_painting_75bf8d02-88f5-43f6-af53-4aa7fdb1683d.png',
      thumb: 'thm/thedreambox_a_salvador_dali_painting_of_an_ed_roth_painting_75bf8d02-88f5-43f6-af53-4aa7fdb1683d-thumb.png',
      title: 'Midjourney 3',
      author: '@jimmygizmo',
    },
    {
      img: 'thedreambox_a_glossy_Salvador_Dali_painting_of_a_society_of_ele_5e0c8100-2e8f-459c-b6b0-8a0fe4a72c8f.png',
      thumb: 'thm/thedreambox_a_glossy_Salvador_Dali_painting_of_a_society_of_ele_5e0c8100-2e8f-459c-b6b0-8a0fe4a72c8f-thumb.png',
      title: 'Midjourney 3',
      author: '@jimmygizmo',
    },
    {
      img: 'thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_e58ea19f-8b50-4132-af02-98392135fd55.png',
      thumb: 'thm/thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_e58ea19f-8b50-4132-af02-98392135fd55-thumb.png',
      title: 'Midjourney 3',
      author: '@jimmygizmo',
    },
    {
      img: 'DALLpicassostudio.png',
      thumb: 'thm/DALLpicassostudio-thumb.png',
      title: 'DALL-E 2',
      author: '@jimmygizmo',
    },
    {
      img: 'thedreambox_a_glossy_Salvador_Dali_painting_of_a_glossy_Pieter__bd3ff646-a7fe-4e3f-b3f9-1a9eb6928653.png',
      thumb: 'thm/thedreambox_a_glossy_Salvador_Dali_painting_of_a_glossy_Pieter__bd3ff646-a7fe-4e3f-b3f9-1a9eb6928653-thumb.png',
      title: 'Midjourney 3',
      author: '@jimmygizmo',
    },
    {
      img: 'DALLniceday.png',
      thumb: 'thm/DALLniceday-thumb.png',
      title: 'DALL-E 2',
      author: '@jimmygizmo',
    },
    {
      img: 'FLUXfp8-2024-12-28-004257__0.png',
      thumb: 'thm/FLUXfp8-2024-12-28-004257__0-thumb.png',
      title: 'FLUX1 Dev fp8',
      author: '@jimmygizmo',
    },
    {
      img: 'SD35-ComfyUI_00053_.png',
      thumb: 'thm/SD35-ComfyUI_00053_-thumb.png',
      title: 'Stable Diffusion 3.5',
      author: '@jimmygizmo',
    },
    {
      img: 'thedreambox_a_glossy_salvador_dali_painting_of_dozens_of_glossy_296e264c-6d69-4cf8-a6bc-ff144a41fb76.png',
      thumb: 'thm/thedreambox_a_glossy_salvador_dali_painting_of_dozens_of_glossy_296e264c-6d69-4cf8-a6bc-ff144a41fb76-thumb.png',
      title: 'Midjourney 3',
      author: '@jimmygizmo',
    },
    // {
    //   img: '2025-02-12-121524__0.png',
    //   thumb: 'thm/2025-02-12-121524__0-thumb.png',
    //   title: 'FLUX1 Dev fp8',
    //   author: '@jimmygizmo',
    // },
    {
      img: 'DALLmosruins.png',
      thumb: 'thm/DALLmosruins-thumb.png',
      title: 'DALL-E 2',
      author: '@jimmygizmo',
    },
    {
      img: 'FLUXfp8-2025-01-10-135636__0.png',
      thumb: 'thm/FLUXfp8-2025-01-10-135636__0-thumb.png',
      title: 'FLUX1 Dev fp8',
      author: '@jimmygizmo',
    },
    {
      img: 'thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_ff5a86ed-6c67-4ac3-9ea7-51b1bbdc51cb.png',
      thumb: 'thm/thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_ff5a86ed-6c67-4ac3-9ea7-51b1bbdc51cb-thumb.png',
      title: 'Midjourney 3',
      author: '@jimmygizmo',
    },
    {
      img: 'thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_7c55e887-cc9f-456c-9858-e0a21e0d39bc.png',
      thumb: 'thm/thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_7c55e887-cc9f-456c-9858-e0a21e0d39bc-thumb.png',
      title: 'Midjourney 3',
      author: '@jimmygizmo',
    },
    {
      img: 'thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_149ec89f-8847-4843-b8d3-6b87d1333ce4.png',
      thumb: 'thm/thedreambox_a_glossy_hyper-real_salvador_dali_painting_of_a_glo_149ec89f-8847-4843-b8d3-6b87d1333ce4-thumb.png',
      title: 'Midjourney 3',
      author: '@jimmygizmo',
    },
    {
      img: 'thedreambox_a_glossy_salvador_dali_painting_1950s_couple_mowing_3f4b24b1-c020-4419-816a-7832a2defbd1.png',
      thumb: 'thm/thedreambox_a_glossy_salvador_dali_painting_1950s_couple_mowing_3f4b24b1-c020-4419-816a-7832a2defbd1-thumb.png',
      title: 'Midjourney 3',
      author: '@jimmygizmo',
    },
    {
      img: 'FLUXfp8-2024-12-27-212745__0.png',
      thumb: 'thm/FLUXfp8-2024-12-27-212745__0-thumb.png',
      title: 'FLUX1 Dev fp8',
      author: '@jimmygizmo',
    },
    {
      img: 'thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_fb1a48d0-0d2c-4f7e-8fb1-1d0bb4a46db3.png',
      thumb: 'thm/thedreambox_a_glossy_salvador_dali_painting_of_a_glossy_ed_roth_fb1a48d0-0d2c-4f7e-8fb1-1d0bb4a46db3-thumb.png',
      title: 'Midjourney 3',
      author: '@jimmygizmo',
    },
    {
      img: '2025-02-12-104517__0.png',
      thumb: 'thm/2025-02-12-104517__0-thumb.png',
      title: 'FLUX1 Dev fp8',
      author: '@jimmygizmo',
    },
    {
      img: '2025-02-12-113003__0.png',
      thumb: 'thm/2025-02-12-113003__0-thumb.png',
      title: 'FLUX1 Dev fp8',
      author: '@jimmygizmo',
    },
    {
      img: '2025-02-12-121848__0.png',
      thumb: 'thm/2025-02-12-121848__0-thumb.png',
      title: 'FLUX1 Dev fp8',
      author: '@jimmygizmo',
    },
    {
      img: '2025-02-12-113412__0.png',
      thumb: 'thm/2025-02-12-113412__0-thumb.png',
      title: 'FLUX1 Dev fp8',
      author: '@jimmygizmo',
    },
    {
      img: '2025-02-12-123358__0.png',
      thumb: 'thm/2025-02-12-123358__0-thumb.png',
      title: 'FLUX1 Dev fp8',
      author: '@jimmygizmo',
    },
    {
      img: '2025-02-11-011359__0.png',
      thumb: 'thm/2025-02-11-011359__0-thumb.png',
      title: 'FLUX1 Dev fp8',
      author: '@jimmygizmo',
    },
    {
      img: '2025-02-12-102012__0.png',
      thumb: 'thm/2025-02-12-102012__0-thumb.png',
      title: 'FLUX1 Dev fp8',
      author: '@jimmygizmo',
    },
    {
      img: 'FLUX-bedrock-great01-crop-880x880.png',
      thumb: 'thm/FLUX-bedrock-great01-crop-880x880-thumb.png',
      title: 'FLUX1 Dev fp8',
      author: '@jimmygizmo',
    },
    {
      img: '2025-02-12-024445__0.png',
      thumb: 'thm/2025-02-12-024445__0-thumb.png',
      title: 'FLUX1 Dev fp8',
      author: '@jimmygizmo',
    },
    {
      img: 'FLUX-snake-01-crop-800x800.png',
      thumb: 'thm/FLUX-snake-01-crop-800x800-thumb.png',
      title: 'FLUX1 Dev fp8',
      author: '@jimmygizmo',
    },
    {
      img: '2025-02-12-105344__0.png',
      thumb: 'thm/2025-02-12-105344__0-thumb.png',
      title: 'FLUX1 Dev fp8',
      author: '@jimmygizmo',
    },
    {
      img: 'FLUX-bedrock-great02.png',
      thumb: 'thm/FLUX-bedrock-great02-thumb.png',
      title: 'FLUX1 Dev fp8',
      author: '@jimmygizmo',
    },
    {
      img: '2025-02-12-124703__0.png',
      thumb: 'thm/2025-02-12-124703__0-thumb.png',
      title: 'FLUX1 Dev fp8',
      author: '@jimmygizmo',
    },
    {
      img: 'FLUX-snake-00.png',
      thumb: 'thm/FLUX-snake-00-thumb.png',
      title: 'FLUX1 Dev fp8',
      author: '@jimmygizmo',
    },
  ]


  return (
    <div>
      <Helmet>
        <title>SmartMetal.ai - Gallery of AI-Generated Images & Video</title>
        <meta name="description" content="AI Generated Video & Images - Resources for Machine Learning Developers" />
      </Helmet>
      <Box>
        <NavbarPublic />
        <Box
          width="100%"
          padding="2rem 4%"
          display={isNonMobileScreens ? "flex" : "block"}
          gap="2rem"
          justifyContent="center"
        >
          <WidgetWrapper>
            <Typography
              variant="h3"
              // fontWeight="bold"
              // fontSize="1.1rem"
              color={palette.neutral.dark}
              // variant="h5"
              // fontWeight="500"
              sx={{ mb: "1.5rem" }}
            >
              AI-Generated Images & Video
            </Typography>
            <Box display="flex" flexDirection="column">

              <ImageList
                cols={isNonMobileScreens ? 6 : 2}
                gap="1.5rem"
                sx={{
                  margin: "0.0rem",
                  width: "100%",
                  height: "100%",
                }}
              >
                {galleryPieces.map((item) => (
                  <ImageListItem
                    key={item.img}
                  >
                    <img
                      style={{
                        cursor: "pointer",
                        objectFit: "cover",
                        borderRadius: "0.5rem",
                        border: '1px solid #808080',
                      }}
                      // srcSet={`${Config.expressUri}/assets/gallery/${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                      // src={`${Config.expressUri}/assets/gallery/${item.img}?w=248&fit=crop&auto=format`}
                      srcSet={`${Config.expressUri}/assets/gallery/${item.thumb}?w=248&fit=crop&auto=format&dpr=2 2x`}
                      src={`${Config.expressUri}/assets/gallery/${item.thumb}?w=248&fit=crop&auto=format`}
                      // src={`${item.img}?w=248&fit=crop&auto=format`}
                      alt={item.title}
                      loading="lazy"
                      onClick={() => handleOpen(`${Config.expressUri}/assets/gallery/${item.img}`)}
                    />
                    <ImageListItemBar
                      title={item.title}
                      subtitle={<span>by: {item.author}</span>}
                      position="below"
                      sx={{
                        background: "rgba(0, 0, 0, 0.5)",
                        color: "white",
                        padding: "0.0rem 0.6rem 0.0rem 0.6rem",
                        borderRadius: "0.5rem",
                        border: '1px solid #808080',
                        "border-top": "none",
                      }}
                    />
                  </ImageListItem>
                ))}
              </ImageList>

              {/* FOR POPUP */}
              <ImagePopup imageUrl={imageUrl} open={open} onClose={handleClose} />

            </Box>
          </WidgetWrapper>
        </Box>
        <Box
          display="block"
          width="100%"
          gap="1.5rem"
          padding="0.0rem"
        >
          <Footer />
        </Box>
      </Box>
    <LoadFresh />
    </div>
  );
};

export default GalleryPage;

